import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import closeIcon from "../../../../public/images/close-icon.svg";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import FormItem from "app/components/inputs/FormItem";
import { errorMessageFor } from "app/routes/Calendar/EventForm/helpers";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import AthleteListItem from "./AthleteListItem";
import { StudentProfileModel } from 'app/models/scheduling/StudentProfileModel';
import { schedulingFetchGet, schedulingFetchPost } from 'app/utils/request/scheduling/fetch';
import useCurrentFacility from "app/utils/hooks/scheduling/useCurrentFacility";
import SchedulingUserSelector from "app/components/SchedulingUserSelector";
import ToolTip from "app/routes/Scheduling/ToolTip";
import ActiveSubscriptionsModal from "../OfferingActionModal/ActiveSubscriptionsModal";

const ManageOfferingModalModal = ({
  product,
  isModalOpen,
  modalHandler,
}) => {

  type FormValues = {
    studentProfiles: StudentProfileModel[];
  };

  const methods: UseFormReturn<FormValues> = useForm<FormValues>({
    defaultValues: {
      studentProfiles: [],
    },
  });

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [athletes, setAthletes] = useState([]);
  const [fetchData, setFetchData] = useState(true);
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;
  const fetchHandler = () => {
    setFetchData((prev) => !prev);
  };
  const facility = useCurrentFacility().data;
  const watchStudents = watch('studentProfiles');
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");

  const loadStudents = async (inputValue: string, _currentOptions: [], additional: { page: number }) => {
    const exceptIds = watchStudents.map((s: StudentProfileModel) => s.id) || [];
    const page = additional?.page || 1;

    try {
      const response = await schedulingFetchGet(`/facilities/${facility.id}/student_profiles`,
        { query: inputValue, per_page: 20, page, except: exceptIds }, { withHeaders: true }
      );

      const { students, headers } = response;
      const [ current_page, total_pages ] = [ Number(headers.get('Current-Page')), Number(headers.get('Total-Pages')) ];
      const next_page = current_page < total_pages ? current_page + 1 : null;

      return {
        options: students || [],
        hasMore: current_page < total_pages,
        additional: {
          page: next_page,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: null,
        },
      };
    }
  };

  useEffect(() => {
    setLoadingData(true);

    schedulingFetchGet(`stripe_purchases?coach_id=${userId}&stripe_product_id=${product.stripeProdId}`)
      .then((data) => {
        if (!data) {
          throw new Error('Failed to fetch athletes or invalid response format');
        }
        setAthletes(data);
      })
      .catch((error) => {
        console.error('Error fetching athletes:', error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [fetchData, userId, product.stripeProdId]);

  const onSubmit = async (data) => {
    setLoading(true);

    const paymentData = {
      student_profile_ids: data.studentProfiles.map((student) => student.id),
      stripe_product_id: product.stripeProdId,
      coach_id: userId,
    };

    try {
      await schedulingFetchPost(`stripe_purchases/create_payment`, paymentData);

      reset();
      fetchHandler();
    } catch (error) {
      console.error("Error creating payment:", error);
      const errors = error.errors;
      if(errors?.message) {
        setTitleError(errors.title);
        setMessageError(errors.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const { t } = useTranslation();
  return (
    <>
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler} classNames={`${styles.modal} event_form_containe`}>
      <FormProvider {...methods}>
      <form>
          <div className={styles.modal_header}>
            <div className={styles.titleRow}>
              <h2
                className={styles.truncatedText}
                title={product.title.length > 25 ? product.title : ""}
              >
                {t("Manage")} "{product.title.length > 25 ? `${product?.title?.substring(0, 25)}...` : product.title}"
              </h2>
              {product.title.length > 25 && (
                <div className={styles.toolTipAfterEllipsis}>
                  <ToolTip text={product.title} orientation="right" />
                </div>
              )}
            </div>
            <img
              src={closeIcon}
              alt="close"
              className={styles.close_icon}
              onClick={modalHandler}
            />
          </div>
            <FormItem error={errorMessageFor(errors, 'studentProfiles')} className={styles.input_field}>
                <label>{t('Add Athletes')}</label>
          <SchedulingUserSelector
            {...register("studentProfiles", { validate: ids => ids.length > 0 })}
            control={control}
            loadOptions={loadStudents}
            placeholder={'Select Athletes'}
            multiSelect
          />
            </FormItem>
          <div className={styles.athletes_container}>
            <div className={styles.table_header}>
              <label className={styles.subtitle}>{t('Purchases')}</label>
              <div className={styles.purchases_header}>
                  <div className={styles.name}>{t('Name')}</div>
                  <div className={styles.purchases}>{t('# of purchases')}</div>
                  <div className={styles.status}>{t('Status')}</div>
              </div>
            </div>
            {athletes &&
                  athletes.length > 0 ?
                   (
                   <div className={styles.offerings_container}>
                      { athletes.map((athlete) => {
                        return (
                           <AthleteListItem key={athlete} athlete={athlete}/>
                          );
                        })}
                    </div>
                    ) : null}
          </div>
          <div className={styles.button_wrapper}>
            <Button
              buttonType="secondary-outline"
              title={t("Cancel")}
              onClickFunc={modalHandler}
            />
            <Button
              buttonType="primary"
              title={loading ? t("Saving...") : t("Save")}
              onClickFunc={handleSubmit(onSubmit)}
              disabled={loading || loadingData || watchStudents.length === 0}
            />
          </div>
        </form>
      </FormProvider>
    </ModalLayout>
    {messageError && (
        <ActiveSubscriptionsModal
          message={messageError}
          onClose={() => setMessageError("")}
          title={titleError}
        />
      )}
    </>
  );
};

export default memo(ManageOfferingModalModal);
