/**
 * @module Model.FacilityModel
 *
 */
import { Model } from "typedefs";
import { CoachProfileModel, CoachProfileResponse } from 'app/models/scheduling/CoachProfileModel';

interface Address extends Model {
  street1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
export interface FacilityResponse extends Model {
  id: string;
  name: string;
  coachProfiles?: CoachProfileResponse[];
  address?: Address;
  currency?: string;
  timeZone?: string;
}

export class FacilityModel implements Model {
  id: string;
  name: string;
  coachProfiles?: CoachProfileModel[];
  address?: string;
  currency?: string;
  timeZone?: string;
  publicCalendarURL?: string;
  publicCalendar?: boolean;
  publicProgramsURL?: string;
  publicPrograms?: boolean;
  logo_url?: string;


  constructor(facility: FacilityResponse) {
    this.id = facility.id;
    this.name = facility.name;
    this.currency = facility.currency;
    this.timeZone = facility.timeZone;
    this.publicCalendarURL = facility.publicCalendarUrl;
    this.publicCalendar = facility.publicCalendar;
    this.publicPrograms = facility.publicPrograms;
    this.publicProgramsURL = facility.publicProgramsUrl;
    this.logo_url = facility.logoUrl;
    if (facility?.address) {
      this.address =
        facility?.address?.street1 +
        ", " +
        facility?.address?.street2 +
        ", " +
        facility?.address?.city +
        ", " +
        facility?.address?.state +
        ", " +
        facility?.address?.zip +
        ", " +
        facility?.address?.country;
    }
    if (facility.coachProfiles && facility.coachProfiles.length > 0) {
      this.coachProfiles = facility.coachProfiles.map(c => new CoachProfileModel(c));
    }
  }
}
