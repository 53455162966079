import SpaceModel from "app/models/SpaceModel";
import { cls } from "app/utils";
import React from "react";
import UserAvatar from "../UserAvatar";
import styles from "./styles.module.scss";

interface Props {
  spaceData: SpaceModel;
  className?: string;
}

const SpaceCardXl = ({ spaceData, className }: Props) => {
  return (
    <div className={cls(styles.container, className)}>
      <span className={styles.avatar_wrapper}>
        <UserAvatar
          srcName={spaceData?.avatar}
          userName={spaceData?.athleteName}
          sizeWidth={100}
          sizeHeight={100}
          className={styles.avatar}
        />
      </span>
      <div className={styles.space_info}>
        <p className={styles.athlete_name}>{spaceData?.athleteName}</p>
        <p className={styles.sport}>{spaceData?.sport}</p>
      </div>
    </div>
  );
};

export default SpaceCardXl;
