/**
 * @module Reducers.Space
 *
 */
import { RequestError } from "typedefs";
import { SmartListAction } from "app/actions/smartList";

export type SmartListState = {
  smartListsData: any | null;
  smartListsError: RequestError | null;
  pending: boolean;
};

const initialState: SmartListState = {
  smartListsData: null,
  smartListsError: null,
  pending: false,
};

export default function spaceReducer(
  state = initialState,
  action: SmartListAction,
): SmartListState {
  switch (action.type) {
    case "@smartList.fetch.pending":
      return { ...state, smartListsError: null, pending: true };
    case "@smartList.fetch.smartListsSuccess":
      return { ...state, smartListsData: action.payload, pending: false };
    case "@smartList.clear":
      return {
        ...state,
        smartListsData: null,
        smartListsError: null,
        pending: false,
      };

    default:
      return state;
  }
}
