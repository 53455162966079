import React, { memo } from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import ActivitySidebar from "./ActivitySidebar";
import { sidebarItems } from "./ActivitySidebar/sidebarItems";
import TabButton from "app/components/Button/TabButton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "app/components/Navbar";

interface Props {
  children: React.ReactNode;
}

const ActivityLayout = ({ children }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  return (
    <>
      <Navbar
        label={t("Activity")}
        hasNotifications={false}
        hasSearch={false}
      />
      <div className={styles.panel_menu_bar}>
        {sidebarItems(t).map((item) => (
          <TabButton
            key={item.title}
            label={item.title}
            icon={item.icon}
            isActive={window.location.pathname
              .toLowerCase()
              .includes(item.title.toLowerCase())}
            onClick={() => route(item.path)}
            className={styles.tab_btn}
            labelClassName={styles.tab_label}
          />
        ))}
      </div>
      <div className={styles.container}>
        <ActivitySidebar />
        <div className={styles.main}>
          <GettingStartedProgress />
          <div className={styles.content_wrapper}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default memo(ActivityLayout);
