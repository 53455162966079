import React from "react";
import styles from "./styles.module.scss";
import SwitchToggle from "../../Scheduling/SwitchToggle";
import CopyField from "../../Scheduling/CopyField";
import ToolTip from "app/routes/Scheduling/ToolTip";

interface Props {
  inputText: string;
  inputLabel: string;
  title: string;
  enabled: boolean;
  onChange: (value: boolean) => any;
  toolTipText?: string | string[];
  titleToolTipText?: string | string[] | null;
}

const TogglePill = ({
  inputText,
  inputLabel,
  title,
  enabled = false,
  onChange,
  toolTipText = '',
  titleToolTipText = null
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.first_row}>
        <div className={styles.title}>{title}</div>
        {titleToolTipText && <ToolTip text={titleToolTipText} />}
      </div>
      <div className={styles.row}>
        <SwitchToggle
          key={inputText}
          defaultChecked={enabled}
          name={"default"}
          title={inputLabel}
          setValue={() => null}
          defaultValue={false}
          onChange={onChange}
          toolTipText={toolTipText}
        />
      </div>
      {enabled && <CopyField className={styles.input} value={inputText} />}
    </div>
  );
};

export default TogglePill;
