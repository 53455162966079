/**
 * @module Reducers.Space
 *
 */
import { RequestError } from "typedefs";
import { SpaceAction } from "app/actions/space";
import { SpaceModel, SpaceQueryModel } from "app/models";
import UserModel from "app/models/UserModel";

export type SpaceState = {
  data: SpaceModel[] | null;
  spaces: SpaceModel[] | null;
  page: number;
  query: SpaceQueryModel;
  hasMore: boolean;
  error: RequestError | null;
  pending: boolean;
  sync?: number;
  newOwner?: UserModel | null;
  isModalOpen?: boolean;
  selectedSpace?: SpaceModel | null;
};

const initPage = 1;

export const initQuery = {
  options: {
    include: ["team_info"],
    exclude: ["permissions", "state"],
  },
};

const initialState: SpaceState = {
  data: null,
  spaces: null,
  page: initPage,
  query: { ...initQuery },
  hasMore: true,
  error: null,
  pending: false,
  selectedSpace: null,
};

export default function spaceReducer(
  state = initialState,
  action: SpaceAction,
): SpaceState {
  switch (action.type) {
    case "@space.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@space.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@space.fetch.spacePage":
      return { ...state, page: action.payload };
    case "@space.fetch.spaceQuery":
      return { ...state, query: action.payload };
    case "@space.fetch.hasMore":
      return { ...state, hasMore: action.payload };
    case "@space.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@space.fetch.newOwner":
      return { ...state, newOwner: action.payload };
    case "@space.fetch.isModalOpen":
      return { ...state, isModalOpen: action.payload };
    case "@space.fetch.selectedSpace":
      return { ...state, selectedSpace: action.payload, pending: false };
    case "@space.clear":
      return {
        ...state,
        data: null,
        spaces: null,
        page: initPage,
        query: { ...initQuery },
        hasMore: true,
        error: null,
        pending: false,
        isModalOpen: false,
        selectedSpace: null,
      };
    case "@space.clearSpaceForSearch":
      return {
        ...state,
        data: null,
        spaces: null,
        page: initPage,
        hasMore: true,
        error: null,
        pending: false,
      };

    default:
      return state;
  }
}
