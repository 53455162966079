import React, { useCallback, useContext, useMemo } from "react";
import styles from "./styles.module.scss";
import config from "config/environment";
import { SidebarProps } from "../index.types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { hasFeature } from "app/utils/hasFeature";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";

import { UserModel } from "app/models";
import Item from "./Item";
import { some } from "lodash";
import {
  UserContext,
  UserContextType,
  RoleType,
} from "app/contexts/UserContext";

export type MenuItemData = {
  title: string;
  icon?: string;
  route: string;
  targetBlank: boolean;
  displayIf?: (user: UserModel, self: MenuItemData) => boolean;
  displayChildrenIf?: (user: UserModel, self: MenuItemData) => boolean;
  children?: MenuItemData[];
};

const facilityHasCoaches = (user: UserContextType): boolean => {
  if (!user.facility) {
    return false;
  }

  if (!user.facility.coachProfiles) {
    return false;
  }

  return user.facility.coachProfiles.length > 0;
};

const showForCoach = (user: UserContextType, _self: MenuItemData): boolean => {
  return user.kind === "coach" && (!user.role || user.role === RoleType.COACH);
};

const showForSchedulingCoach = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  return (
    user.kind === "coach" &&
    (user.role === RoleType.COACH || !user.facilityMembershipId)
  );
};

const showForSchedulingStaff = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  return (
    (user.role === RoleType.ADMIN || user.role === RoleType.SCHEDULER) &&
    facilityHasCoaches(user)
  );
};

const showForAthlete = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  return user.kind === "athlete";
};

const showForSchedulingFacilityUsers = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  return hasFeature(user.features, "scheduling") && !!user.role;
};

const showForSchedulingUsers = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  if (user.role === RoleType.ADMIN || user.role === RoleType.SCHEDULER) {
    return facilityHasCoaches(user);
  }
  return hasFeature(user.features, "scheduling");
};

const showForSchedulingCoaches = (
  user: UserContextType,
  self: MenuItemData,
): boolean => {
  return (
    hasFeature(user.features, "scheduling") &&
    showForSchedulingCoach(user, self)
  );
};

const showForSchedulingAthletes = (
  user: UserContextType,
  self: MenuItemData,
): boolean => {
  return hasFeature(user.features, "scheduling") && showForAthlete(user, self);
};

const showForNonFacilityAdminStaff = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  const isFacilityUser =
    hasFeature(user.features, "scheduling") && !!user.facilityMembershipId;

  if (!isFacilityUser) {
    return true;
  }

  const isAdminStaff = [RoleType.ADMIN, RoleType.SCHEDULER].includes(user.role);

  return !isAdminStaff;
};

const showMyFaciltyOnTop = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  return user.role === RoleType.ADMIN;
};

const showMyFaciltyAtBottom = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  return user.role === RoleType.COACH && user.isAdmin;
};

const hideOnlyForCoaches = (
  user: UserContextType,
  _self: MenuItemData,
): boolean => {
  return user.kind !== "coach";
};

const SidebarMenuList = (_props: SidebarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { self } = useSelector((state: RootState) => state.user);
  // Directly call hasFeature here instead of using useCallback inside useMemo
  const hasSchedulingFeature = hasFeature(self?.features, "scheduling");
  const user = useContext(UserContext);

  const listData = useMemo<MenuItemData[]>(
    () => [
      {
        title: t("Home"),
        icon: "ico-home-o",
        route: "/home",
        targetBlank: false,
        // forAthlete: false,
        displayIf: hideOnlyForCoaches,
      },
      {
        title: t("Admin Settings"),
        route: "/manage_facility",
        icon: "ico-facility",
        targetBlank: false,
        displayIf: showMyFaciltyOnTop,
      },
      {
        title: t("Home"),
        icon: "ico-home-o",
        route: "/spaces",
        targetBlank: false,
        // forAthlete: true,
        displayIf: showForCoach,
      },
      {
        title: t("Feed"),
        icon: "ico-feed-o",
        route: "/feed",
        targetBlank: false,
        // forAthlete: true,
        displayIf: showForNonFacilityAdminStaff,
      },
      {
        title: t("Activity"),
        icon: "ico-notifications-o",
        route: "/notifications",
        targetBlank: false,
        displayIf: showForNonFacilityAdminStaff,
      },
      {
        title: t("Calendar"),
        icon: "ico-calendar",
        route: "/calendar",
        targetBlank: false,
        displayIf: showForSchedulingCoaches,
        displayChildrenIf: (user: UserContextType, _self: MenuItemData) => {
          if (!user.facility) {
            return false;
          }

          if (user.role === RoleType.ADMIN) {
            return false;
          }

          if (user.role === RoleType.COACH) {
            const coachIds =
              user.facility?.coachProfiles?.map((c) => c.id) || [];
            if (coachIds.length <= 1) {
              return false;
            }
          }

          return true;
        },
        children: [
          {
            title: t("My Calendar"),
            route: "/calendar",
            targetBlank: false,
            displayIf: showForSchedulingCoaches,
          },
          {
            title: t("Facility Calendar"),
            route: "/facility_calendar",
            targetBlank: false,
            displayIf: showForSchedulingFacilityUsers,
          },
        ],
      },
      {
        title: t("Facility Calendar"),
        route: "/facility_calendar",
        icon: "ico-calendar",
        targetBlank: false,
        displayIf: showForSchedulingStaff,
      },
      {
        title: t("Bookings"),
        icon: "ico-calendar",
        route: "/sessions",
        targetBlank: false,
        displayIf: showForSchedulingAthletes,
      },
      {
        title: t("Billing"),
        icon: "ico-dollar-circle",
        route: "/billing",
        targetBlank: false,
        displayIf: showForSchedulingCoaches,
      },
      {
        title: t("Programs"),
        icon: "ico-program",
        route: "/programs",
        targetBlank: false,
        displayIf: showForSchedulingUsers,
      },
      {
        title: t("Library"),
        icon: "ico-library-o",
        route: "/resources",
        targetBlank: false,
        displayIf: showForNonFacilityAdminStaff,
      },
      {
        title: t("Templates"),
        icon: "ico-templates",
        route: "/space_templates",
        targetBlank: false,
        // forAthlete: false,
        displayIf: showForCoach,
      },
      {
        title: t("Schedule"),
        icon: "ico-time",
        route: "/schedule",
        targetBlank: false,
        // forAthlete: false,
        displayIf: showForCoach,
      },

      {
        title: t("Admin Settings"),
        route: "/manage_facility",
        icon: "ico-facility",
        targetBlank: false,
        displayIf: showMyFaciltyAtBottom,
      },
      {
        title: t("Support & FAQ"),
        icon: "ico-heart",
        route: config.SUPPORT_URL,
        targetBlank: true,
      },
    ],
    [hasSchedulingFeature, t],
  );

  const selectHandler = useCallback((val: string, isTargetBlank?: boolean) => {
    if (!isTargetBlank) {
      history.push(val);
    } else {
      window.open(val);
    }
  }, []);

  const checkActivePath = (route: string) => {
    if (route === "/") {
      return location.pathname === "/";
    }
    return (
      location.pathname.startsWith(route) ||
      location.pathname.startsWith("/public" + route)
    );
  };

  const checkActive = (menuItem: MenuItemData): boolean => {
    if (menuItem.children && menuItem.children.length > 0) {
      return some(menuItem.children, (child: MenuItemData) => {
        return checkActivePath(child.route);
      });
    }

    return checkActivePath(menuItem.route);
  };

  const showMenuItemChildren = (menuItem: MenuItemData) => {
    if (!menuItem.children || menuItem.children.length === 0) {
      return false;
    }

    if (!menuItem.displayChildrenIf) {
      return true;
    }

    return menuItem.displayChildrenIf(user, menuItem);
  };

  return (
    <div className={styles.sidebar_nav}>
      {listData.map((list) => {
        return (
          <Item
            key={list.route}
            data={list}
            user={user}
            isActive={checkActive(list)}
            onClick={selectHandler}
            activeChildren={showMenuItemChildren(list)}
          >
            {showMenuItemChildren(list) && (
              <div className={styles.child_nav_item}>
                {list.children.map((childList: MenuItemData) => {
                  return (
                    <Item
                      key={`child-${childList.route}`}
                      data={childList}
                      user={user}
                      isActive={checkActivePath(childList.route)}
                      onClick={selectHandler}
                    />
                  );
                })}
              </div>
            )}
          </Item>
        );
      })}
    </div>
  );
};

export default SidebarMenuList;
