import React, { memo, useCallback, useState } from "react";
import styles from "./styles.module.scss";
import Sidebar from "app/components/Sidebar";
import SiteNavbar from "app/components/SiteNavbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { cls } from "app/utils";
import AlertContainer from "app/components/AlertContainer";
import { useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const mediaWatcher = window.matchMedia("(max-width: 820px)");

const SiteWrapper: React.FC<Props> = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const { alerts } = useSelector(
    (state: RootState) => state.alerts,
    shallowEqual,
  );
  const location = useLocation();

  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(true);
  const calendarView = mediaWatcher.matches ? "timeGridNarrow" : "timeGridWeek";

  const toggleSidebar = useCallback(() => {
    setSidebarExpanded(!sidebarExpanded);
  }, [sidebarExpanded]);

  const onExpireAlarm = useCallback((id: string) => {
    dispatch({ type: "@alert.expire", payload: { id } });
  }, []);

  if (!user) {
    return null;
  }

  const onMobileCalendar =
    location.pathname === "/calendar" && calendarView !== "timeGridWeek";
  const onMobileFacilityCalendar =
    location.pathname === "/facility_calendar" &&
    calendarView !== "timeGridWeek";

  const containerClass =
    onMobileCalendar || onMobileFacilityCalendar
      ? styles.child_container_no_padding
      : styles.child_container;

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={cls(styles.main, { [styles.expanded]: sidebarExpanded })}>
        <SiteNavbar
          sidebarExpanded={sidebarExpanded}
          toggleSidebar={toggleSidebar}
        />
        <div className={containerClass}>
          <div className={cls(styles.child_wrapper)}>{children}</div>
        </div>
      </div>
      <AlertContainer alerts={alerts} setExpired={onExpireAlarm} />
    </div>
  );
};

export default memo(SiteWrapper);
