import React, { FC } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

interface Props {
  images?: string[];
  title?: string;
  subtitle?: string;
  hasContextMenu?: boolean;
  buttonName?: string | React.ReactNode;
  onClickButton?: () => void;
  buttonStyles?: string;
  date?: string;
  className?: string;
  onContentCardClick?: () => void;
}

const MultiContentCard: FC<Props> = ({
  images,
  title,
  subtitle,
  buttonName,
  onClickButton,
  buttonStyles,
  date,
  className,
  onContentCardClick,
}: Props) => {
  const displayedImages = images?.slice(0, 3) ?? [null];

  return (
    <div
      className={cls(styles.contentWrap, className, {
        ["cursor_pointer"]: onContentCardClick,
      })}
      onClick={onContentCardClick}
    >
      <div className={styles.contentInfoWrapper}>
        <div className={styles.contentCardAvatar}>
          {displayedImages.length > 0 &&
            displayedImages.map((img, index) => (
              <div key={index} className={styles.contentCardAvatarShape}>
                {img ? (
                  <img src={img} alt={`icon-${index}`} />
                ) : (
                  <i className={`ico ico-groups`} />
                )}
              </div>
            ))}
          {displayedImages?.length === 0 && (
            <div className={styles.contentCardAvatarShape}>
              {" "}
              <i className={`ico ico-groups`} />{" "}
            </div>
          )}
        </div>
        <div className={styles.contentCardInfoText}>
          <span className={styles.title}>
            <h2>{title}</h2>
            {date && <p>{date}</p>}
          </span>
          <h3>{subtitle}</h3>
        </div>
      </div>
      {buttonName && (
        <button
          className={cls(styles.button, buttonStyles)}
          onClick={onClickButton ? onClickButton : null}
        >
          {buttonName}
        </button>
      )}
    </div>
  );
};

export default MultiContentCard;
