import { filterAction } from "app/actions/filter";
import { FilterModel } from "app/models/FilterModel";
import { RequestError } from "typedefs";

export type filterState = {
  error: RequestError | { fromError: string | null; toError: string | null };
  data: FilterModel;
};

export const initialFilterState: FilterModel = {
  data: {
    orderby: "created_at",
    tags: [],
    type: {
      image: true,
      video: true,
      note: true,
      audio: true,
      datafile: true,
    },
    pickedDateState: {
      fromDay: null,
      fromMonth: null,
      fromYear: null,
      toDay: null,
      toMonth: null,
      toYear: null,
    },
    from: "",
    to: "",
    userIds: [],
  },
};

const initialState: filterState = {
  error: null,
  data: {
    orderby: "created_at",
    tags: [],
    type: {
      image: true,
      video: true,
      note: true,
      audio: true,
      datafile: true,
    },
    pickedDateState: {
      fromDay: null,
      fromMonth: null,
      fromYear: null,
      toDay: null,
      toMonth: null,
      toYear: null,
    },
    from: "",
    to: "",
    userIds: [],
  },
};

export default function filterReducer(
  state = initialState,
  action: filterAction
) {
  switch (action.type) {
    case "@filter.data":
      return { ...state, data: action.payload };
    case "@filter.error":
      return { ...state, error: action.payload, pending: false };
    case "@filter.clear":
      return {
        ...state,
        error: null,
        data: {
          orderby: "created_at",
          tags: [],
          type: {
            image: true,
            video: true,
            note: true,
            audio: true,
            datafile: true,
          },
          pickedDateState: {
            fromDay: null,
            fromMonth: null,
            fromYear: null,
            toDay: null,
            toMonth: null,
            toYear: null,
          },
          from: "",
          to: "",
          userIds: [],
        },
      };
    default:
      return state;
  }
}
