import React from "react";
import { cls } from "app/utils";
import { Loading } from "app/components/Wrappers";
import { SpacesLimit } from "app/components/paywalls";
import styles from "./styles.module.scss";
import SpaceTransferSuccessModal from "app/components/SpaceTabsContainer/SpaceTransferSuccessModal";
import CoachHomeLayout from "app/components/Layouts/CoachHomeLayout";
import null_list from "app/images/null-lists.png";
import no_space_png from "app/images/null-training-space.png";
import { NoFilteredResults, NoResults } from "app/components/Layouts";
import SmartListDescription from "./_components/SmartListDescription";
import { useTranslation } from "react-i18next";
import ButtonVariant from "app/components/Button/ButtonVariant";
import SpacesNavbar from "./_components/SpacesNavbar";
import useSpacesModel from "./indexModel";
import SpacesList from "./_components/SpacesList";
import NoSmartListSpace from "app/components/NoResults/NoSmartListSpace";
import DemoSpacesDescription from "./_components/DemoSpacesDescription";

function LayoutWrapper({
  children,
  selectedListValue,
  isDemo,
  onSmartListSelect,
  updateSearch,
  upgradeHandler,
  showUpgrade,
  closeUpgrade,
}: {
  children: React.ReactNode;
  selectedListValue: any;
  isDemo?: boolean;
  onSmartListSelect: (value: any) => void;
  updateSearch: (value: string) => void;
  upgradeHandler: () => void;
  showUpgrade: boolean;
  closeUpgrade: () => void;
}) {
  return (
    <CoachHomeLayout>
      <SpacesNavbar
        selectedListValue={selectedListValue}
        onSmartListSelect={onSmartListSelect}
        updateSearch={updateSearch}
        upgradeHandler={upgradeHandler}
      />
      {selectedListValue?.object === "smart_list" && (
        <SmartListDescription selectedListValue={selectedListValue} />
      )}
      {isDemo && <DemoSpacesDescription />}
      {children}
      <SpacesLimit show={showUpgrade} onClose={closeUpgrade} />
    </CoachHomeLayout>
  );
}

export default function Spaces() {
  const {
    spacesData,
    pending,
    sentryRef,
    // user,
    debouncedSearch,
    isModalOpen,
    showUpgrade,
    closeUpgrade,
    selectedListValue,
    smartListSelectHandler,
    updateSearch,
    upgradeHandler,
    newSingle,
    isDemo,
    demoPending,
  } = useSpacesModel();

  const { t } = useTranslation();

  if (debouncedSearch?.length >= 3 && !pending && spacesData.length === 0) {
    return (
      <LayoutWrapper
        selectedListValue={selectedListValue}
        onSmartListSelect={smartListSelectHandler}
        updateSearch={updateSearch}
        upgradeHandler={upgradeHandler}
        showUpgrade={showUpgrade}
        closeUpgrade={closeUpgrade}
      >
        <NoFilteredResults missingTerm={debouncedSearch} />
      </LayoutWrapper>
    );
  }

  const NoResultLayoutWrapper = ({
    children,
  }: {
    children: React.ReactNode;
  }) => {
    return (
      <LayoutWrapper
        selectedListValue={selectedListValue}
        onSmartListSelect={smartListSelectHandler}
        isDemo={isDemo}
        updateSearch={updateSearch}
        upgradeHandler={upgradeHandler}
        showUpgrade={showUpgrade}
        closeUpgrade={closeUpgrade}
      >
        {children}
      </LayoutWrapper>
    );
  };

  if (debouncedSearch?.length < 3 && !pending && spacesData.length === 0) {
    if (selectedListValue?.object === "smart_list") {
      return (
        <NoResultLayoutWrapper>
          <NoSmartListSpace selectedSmartList={selectedListValue} />
        </NoResultLayoutWrapper>
      );
    }

    if (selectedListValue?.object === "segment") {
      return (
        <NoResultLayoutWrapper>
          <NoResults
            image={null_list}
            text={t("No Spaces in this list yet")}
            subtext={t(
              "All spaces that you create in this list will appear here.",
            )}
            className={styles.no_results}
          />
        </NoResultLayoutWrapper>
      );
    }

    if (selectedListValue?.object !== "segment") {
      return (
        <NoResultLayoutWrapper>
          <>
            <NoResults
              image={no_space_png}
              text={t("Welcome to Spaces")}
              subtext={t("Work one-on-one with a coach or player.")}
              className={styles.no_results}
            />
            <div className={styles.btn_wrapper}>
              <ButtonVariant
                buttonStyles={styles.add_btn}
                buttonType="primary"
                title={"Add new Space"}
                icon={<i className="ico ico-add" />}
                onClickFunc={newSingle}
              />
            </div>
          </>
        </NoResultLayoutWrapper>
      );
    }
  }

  return (
    <LayoutWrapper
      selectedListValue={selectedListValue}
      onSmartListSelect={smartListSelectHandler}
      isDemo={isDemo}
      updateSearch={updateSearch}
      upgradeHandler={upgradeHandler}
      showUpgrade={showUpgrade}
      closeUpgrade={closeUpgrade}
    >
      <Loading className={cls(styles.spaces)}>
        <div className={styles.page_container}>
          <SpacesList
            containerRef={sentryRef}
            spacesData={spacesData}
            pending={pending || demoPending}
            upgradeHandler={upgradeHandler}
            isDemo={isDemo}
            selectedListValue={selectedListValue}
          />
          {isModalOpen && <SpaceTransferSuccessModal />}
        </div>
      </Loading>
    </LayoutWrapper>
  );
}
