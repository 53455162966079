import React, { memo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useActions } from "app/utils/hooks";
import { leaveGroup } from "app/actions/groups";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import GroupsNotificationsModal from "./GroupNotificationsModal";
import GroupSettingsModal from "./GroupSettingsModal";
import TransferGroupModal from "./TransferGroupModal";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import GroupsModel from "app/models/GroupsModel";

interface GroupSettingsProps {
  groupData: GroupsModel;
}

const GroupSettings = ({ groupData }: GroupSettingsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { leaveGroup: leaveGroupAction } = useActions({ leaveGroup });

  // Modal states
  const [modals, setModals] = useState({
    notifications: false,
    settings: false,
    transfer: false,
  });

  // Permission checks
  const canEdit =
    groupData?.permissions?.includes("edit") &&
    groupData?.planPermissions?.includes("edit");
  const canLeave =
    groupData?.permissions?.includes("leave") &&
    groupData?.planPermissions?.includes("leave");

  // Modal handlers with useCallback
  const toggleModal = useCallback((modalName: keyof typeof modals) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: !prev[modalName],
    }));
  }, []);

  const handleTransferModal = useCallback(() => {
    setModals((prev) => ({
      ...prev,
      settings: false,
      transfer: !prev.transfer,
    }));
  }, []);

  // Leave group handler
  const handleLeaveGroup = useCallback(() => {
    if (!groupData?.id) {
      return;
    }

    customPrompt({
      message: t(
        "Are you sure you would like to leave {{selectedGroupName}}'s group?",
        {
          selectedGroupName: groupData.name,
        }
      ),
      onYes: async () => {
        try {
          await leaveGroupAction(groupData.id);
          history.push("/groups");
        } catch (error) {
          console.error("Error leaving group:", error);
          // Here you could add error handling UI feedback
        }
      },
      onCancel: () => undefined,
    });
  }, [groupData?.id, groupData?.name, history, leaveGroupAction, t]);

  return (
    <>
      <ul className={styles.dropdown_list}>
        {canEdit && (
          <li onClick={() => toggleModal("settings")}>{t("Group Settings")}</li>
        )}

        <li onClick={() => toggleModal("notifications")}>
          {t("Your Notifications")}
        </li>

        {canLeave && (
          <li onClick={handleLeaveGroup} className={cls("text-danger")}>
            {t("Leave Group")}
          </li>
        )}
      </ul>

      {modals.notifications && (
        <GroupsNotificationsModal
          isModalOpen={modals.notifications}
          modalHandler={() => toggleModal("notifications")}
        />
      )}

      {modals.settings && (
        <GroupSettingsModal
          groupData={groupData}
          isModalOpen={modals.settings}
          modalHandler={() => toggleModal("settings")}
          transferGroupModalHandler={handleTransferModal}
        />
      )}

      {modals.transfer && (
        <TransferGroupModal
          groupData={groupData}
          isModalOpen={modals.transfer}
          modalHandler={handleTransferModal}
        />
      )}
    </>
  );
};

export default memo(GroupSettings);
