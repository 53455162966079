/**
 * @module Components
 *
 */
import React from "react";
import { isEmpty } from "app/utils";
import default_logo_img from "app/images/fallback/logo/square_default.png";

/***/
type PropsDef = {
  className?: string;
  srcName?: string;
  altName?: string;
  sizeWidth?: number;
  sizeHeight?: number;
};

/**
 * Corrects the url for the default user avatar when the user
 * has not set an avatar yet.
 *
 * @method TeamAvatar
 */
export default function TeamAvatar(props: PropsDef) {
  let srcName = props.srcName;
  if (
    isEmpty(srcName) ||
    srcName.indexOf("/assets/fallback/logo/square_default") !== -1
  ) {
    srcName = default_logo_img;
  }

  return (
    <img
      className={props.className}
      src={srcName}
      alt={props.altName}
      width={props.sizeWidth}
      height={props.sizeHeight}
    />
  );
}
