import React from "react";
import AsyncPaginate from "react-select-async-paginate";
import { components } from "react-select";
import UserAvatar from "../UserAvatar";
import style from "../UserSelector/styles.module.scss";
import { SelectStyles } from "app/routes/Calendar/EventForm/constants";

const InfiniteScrollUserSelector = ({
  hasInvite = false,
  onInviteClick = () => null,
  hasArrow = false,
  fetchUsers,
  placeholder,
  selectedUsers,
  setValues,
  singleValue = false,
  value = null,
  showEmail = true,
}) => {

  const loadOptions = async (inputValue: string, _currentOptions: [], { page }) => {
    try {
      const response = await fetchUsers(inputValue, page, 20);
      const { students, headers } = response.data;
      const [current_page, total_pages] = [
        Number(headers.get("Current-Page")),
        Number(headers.get("Total-Pages")),
      ];
      const next_page = current_page < total_pages ? current_page + 1 : null;

      return {
        options: students || [],
        hasMore: current_page < total_pages,
        additional: {
          page: next_page,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: null,
        },
      };
    }
  };

  const user_avatar = (data) => {
    return (
      <UserAvatar
        src={data.profilePicture}
        height={40}
        width={40}
        font_size={12}
        first_name={data.firstName}
        last_name={data.lastName}
      />
    );
  };

  const CustomOption = ({ innerProps, isDisabled, data }) => {
    return !isDisabled && data ? (
      <div
        {...innerProps}
        className={style.option_container}
        data-cy="student-option"
      >
        {user_avatar(data)}
        <div className={style.option_info}>
          <p className={style.option_name}>
            {data.firstName} {data.lastName}
          </p>
          {showEmail && <p className={style.option_email}>{data.email}</p>}
        </div>
      </div>
    ) : null;
  };

  const CustomMenu = (props) => {
    return (
      <components.Menu {...props}>
        {props.children}
        {hasInvite && (
          <div
            className={style.list_add_button}
            onClick={() => {
              onInviteClick();
            }}
          >
            <span
              data-testid="dropdown-button"
              className={style.list_add_button_text}
            >
              Invite New Student
            </span>
          </div>
        )}
      </components.Menu>
    );
  };

  const CustomIndicator = (props) => {
    return <components.IndicatorsContainer {...props} />;
  };

  const addParticipant = (data) => {
    if (singleValue) {
      setValues(data);
      return true;
    }
    if (selectedUsers.some((item) => item.id === data.id)) {
      return null;
    }
    return setValues([...selectedUsers, data]);
  };

  return (
    <div className={`${style.container}`}>
      <div
        className={style.input_full_container}
        data-cy="invite-student-input"
      >
        <AsyncPaginate
          classNamePrefix="infinity-select"
          styles={{
            ...SelectStyles,
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          components={{
            IndicatorSeparator: () => null,
            IndicatorsContainer: hasArrow ? CustomIndicator : () => null,
            Option: CustomOption,
            Menu: CustomMenu,
          }}
          value={singleValue ? value : null}
          onChange={addParticipant}
          loadOptions={loadOptions}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          additional={{
            page: 1,
          }}
          debounceTimeout={300}
          shouldLoadMore={() => {
            // Load more when 75% scrolled
            const menu = document.querySelector('.infinity-select__menu-list');
            if (!menu) {
              return false;
            }
            return menu.scrollTop > (menu.scrollHeight * 0.75 - menu.clientHeight);
          }}
        />
      </div>
    </div>
  );
};

export default InfiniteScrollUserSelector;
