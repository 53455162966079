import React from "react";
import ButtonVariant from "app/components/Button/ButtonVariant";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import { useCreateNewSegment } from "./indexModel";
import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  modalHandler: () => void;
  onUpgrade: () => void;
}

const CreateSegmentModal = ({ isOpen, modalHandler, onUpgrade }: IProps) => {
  const { t } = useTranslation();

  const { newListError, newListName, onNewListNameChange, onSubmit } =
    useCreateNewSegment({ modalHandler, onUpgrade });

  return (
    <ModalLayout
      classNames={styles.modal_container}
      isOpen={isOpen}
      onClose={modalHandler}
    >
      <div className={styles.modal_content_wrapper}>
        <p>{t("New List")}</p>
        <span className={styles.input_wrapper}>
          <label htmlFor="new-list">{t("List Name")}</label>
          <input
            id="new-list"
            name="new-list"
            onChange={(e) => onNewListNameChange(e)}
            className={cls({
              [styles.error]: newListError,
            })}
          />
          {newListError && (
            <p
              className={cls({
                [styles.error_text]: newListError,
              })}
            >
              {t("Name Required")}
            </p>
          )}
        </span>
        <span className={styles.buttons_container}>
          <ButtonVariant
            title={t("Cancel")}
            buttonType="secondary-outline"
            onClickFunc={modalHandler}
          />
          <ButtonVariant
            title={t("Create")}
            buttonType="primary"
            onClickFunc={onSubmit}
            disabled={newListName && newListName.length <= 0}
          />
        </span>
      </div>
    </ModalLayout>
  );
};

export default CreateSegmentModal;
