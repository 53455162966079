import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { filterData } from "../../actions/filter";
import { useCallback, useEffect, useState } from "react";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import { clearFilter } from "app/actions/filter";
import useFilters from "../helpers/useFilters";
import { useTranslation } from "react-i18next";

interface Props {
  filterClickHandler?: (filters: any) => void;
  onFilterClear?: (filters: any) => void;
  hideNotes?: boolean;
}

export const FilterDropdownModel = ({
  filterClickHandler,
  onFilterClear,
  hideNotes,
}: Props) => {
  const { t } = useTranslation();
  const filterState = useSelector(
    (state: RootState) => state.filter,
    shallowEqual
  );
  const dispatch = useDispatch();
  const filterActions = useActions({ clearFilter });
  const { filters } = useFilters();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedAuthors, setSelectedAuthors] = useState<UserModel[]>([]);

  const handleSelectedAuthorChange = (users: UserModel[]) => {
    setSelectedAuthors(users);
  };

  const filterModalHandler = () => {
    setDropdownOpen((prev) => !prev);
  };

  const mediaTypeOptions = [
    { value: "image", label: t("Images") },
    { value: "video", label: t("Videos") },
    { value: "note", label: t("Notes"), hide: hideNotes },
    { value: "audio", label: t("Audio") },
    { value: "datafile", label: t("Documents") },
  ];

  const sortByOptions = [
    { value: "created_at", label: t("Created at") },
    { value: "captured_at", label: t("Captured at") },
  ];

  const toggleMediaType = useCallback(
    (dataType: "image" | "video" | "audio" | "datafile") => {
      if (filterState.data) {
        dispatch(
          filterData({
            ...filterState.data,
            type: {
              ...filterState.data.type,
              [dataType]: !filterState.data.type[dataType],
            },
          })
        );
      }
    },
    [filterState.data]
  );

  const authorOptions = useSelector(
    (state: RootState) => state.connection.data,
    shallowEqual
  );

  const authorsSelectHandler = useCallback(
    (selectedData: UserModel[]) => {
      handleSelectedAuthorChange(selectedData);
      if (selectedData) {
        const newIds = selectedData?.map((user: UserModel) => user.id);
        setSelectedAuthors(selectedData);
        dispatch(
          filterData({
            ...filterState.data,
            userIds: newIds,
          })
        );
      }
    },
    [filterState.data]
  );

  function formatWord(word: string) {
    if (!word) {
      return null;
    }
    const parts = word.split("_");

    const formattedWord = parts
      .map((part) => {
        if (part.length > 0) {
          return part[0].toUpperCase() + part.slice(1);
        }
        return part;
      })
      .join(" ");

    return formattedWord;
  }

  const onSortBySelect = (orderby: "created_at" | "captured_at") => {
    dispatch(filterData({ ...filterState.data, orderby }));
  };

  let sortByValue =
    formatWord(filterState.data.orderby) || sortByOptions[0].label;

  useEffect(() => {
    sortByValue =
      formatWord(filterState.data.orderby) || sortByOptions[0].label;
  }, [filterState.data.orderby]);

  const onTagsChange = (tags: any) => {
    const tagNames = tags.map((item: { name: string }) => item.name);
    dispatch(filterData({ ...filterState.data, tags: tagNames }));
  };

  const onFilterClick = () => {
    setDropdownOpen(false);
    filterClickHandler(filters);
    setIsFiltered(true);
  };

  const clearFiltersHandler = () => {
    setDropdownOpen(false);
    filterActions.clearFilter();
    setSelectedAuthors([]);
    onFilterClear(null);
    setIsFiltered(false);
  };

  // useEffect(() => {
  //   return () => {
  //     filterActions.clearFilter();
  //     setDropdownOpen(false);
  //     setSelectedAuthors([]);
  //     setIsFiltered(false);
  //   };
  // }, []);

  return {
    filterState,
    mediaTypeOptions,
    toggleMediaType,
    sortByOptions,
    authorsSelectHandler,
    authorOptions,
    onSortBySelect,
    sortByValue,
    onTagsChange,
    filterModalHandler,
    onFilterClick,
    clearFiltersHandler,
    isDropdownOpen,
    isFiltered,
    selectedAuthors,
  };
};
