import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export default function NoCustomersPlaceholder() {
  const { t } = useTranslation();

  return (
    <div>
      <p className={styles.subtitle}>
        {t("All customers purchased your offering will be displayed here")}
      </p>

      <div className={styles.customers_placeholder}>
        <p className={styles.no_customers_text}>{t("No customers yet.")}</p>
        <p className={styles.connection_prompt}>
          {t(
            "Make sure you are connected to your Stripe account in the Setup tab."
          )}
        </p>
      </div>
    </div>
  );
}
