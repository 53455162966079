import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { cls } from "app/utils";
import SmartListModel from "app/models/SmartListModel";

interface Props {
  selectedSmartList?: SmartListModel;
  className?: string;
}

const NoSmartListSpace: React.FC<Props> = ({
  selectedSmartList,
  className,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cls(styles.container, className)}>
      <i className={cls("ico ico-tspace", styles.icon)} />
      <h3>{t("This smart list is empty")}</h3>
      <p>
        {t("All spaces with {{label}} posts will appear here", {
          label: selectedSmartList?.name?.toLowerCase(),
        })}
      </p>
    </div>
  );
};

export default NoSmartListSpace;
