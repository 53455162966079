import { useActions, useSelector } from "app/utils/hooks";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { RootState } from "typedefs";
import {
  fetchCoachProfile,
  updateCoachProfile,
} from "app/actions/coachNowCoachProfile";
import { useCallback, useEffect, useState } from "react";
import { IUserPhone } from "app/interfaces/UserTypes";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import { CoachProfileModel } from "app/models/CoachProfileModel";

export const useCoachProfileModel = () => {
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { data: coachData } = useSelector(
    (state: RootState) => state.coachProfile,
    shallowEqual,
  );
  const actions = useActions({
    fetchCoachProfile,
    updateCoachProfile,
  });

  const [loading, setLoading] = useState(false);
  const [toastAlert, setToastAlert] = useState(null);
  const [toastErrorAlert, setToastErrorrAlert] = useState(null);
  const [userState, setUserState] = useState<CoachProfileModel>(
    coachData || {},
  );
  const [uploadedAvatar, setUploadedAvatar] = useState<string | null>(null);
  const [uploadedCover, setUploadedCover] = useState<string | null>(null);
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [error, setError] = useState<{
    bookingServiceError: string | null;
    paymentServiceError: string | null;
  }>({
    bookingServiceError: null,
    paymentServiceError: null,
  });

  useEffect((): (() => void) => {
    let isMounted = true;
    setLoading(true);

    actions
      .fetchCoachProfile(self?.id)
      .then((res: any): void => {
        if (isMounted && res) {
          setUserState(res);
          if (res?.coverUrl) {
            setUploadedCover(res?.coverUrl);
          }
          if (res?.avatarUrl) {
            setUploadedAvatar(res?.avatarUrl);
          }
          if (res?.phone && res?.phoneCountry) {
            const fullPhone = `+${res.phoneCountry}${res.phone}`;
            setPhoneValue(fullPhone);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      isMounted = false;
      setLoading(false);
    };
  }, [actions]);

  const onUpload = (avatar: { url: string }) => {
    setLoading(true);

    try {
      setUploadedAvatar(avatar?.url);
      updateUserData({ type: "avatar_url", value: avatar?.url });
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
    }
  };

  const onCoverChange = (avatar: { id: string; url: string }) => {
    setUploadedCover(avatar?.url);
    setUserState({
      ...userState,
      cover_id: avatar?.id,
    });
  };

  const onPhoneChange = (
    value: string,
    data: IUserPhone,
    _event: React.FormEvent<HTMLInputElement>,
    // formattedValue: string,
  ) => {
    const phone = value.substring(data?.dialCode?.length);
    const phoneCountry = data?.countryCode?.toUpperCase();
    console.log(phone);
    setPhoneValue(value);
    setUserState({
      ...userState,
      phone: value,
      phoneCountry,
    });
  };

  const updateUserData = useCallback(
    ({ type, value }: { type: string; value: string | boolean }) => {
      setUserState((prev) => ({ ...prev, [type]: value }));
    },
    [],
  );

  const isValidUrl = (value: string) =>
    value === "" || /^https?:\/\/[a-zA-Z0-9-]+\.[a-zA-Z]{2,}/.test(value);

  const handleUrlInputUpdate = useCallback(
    ({ type, value }: { type: string; value: string }) => {
      const trimmedValue = value.replace(/\s+/g, "");

      const errorMessage = t(
        "Please provide a valid URL (e.g., https://example.com or www.example.com).",
      );

      if (trimmedValue !== "" && !isValidUrl(trimmedValue)) {
        if (type === "bookingServiceLink") {
          setError((prev) => ({
            ...prev,
            bookingServiceError: errorMessage,
          }));
        }

        if (type === "paymentServiceLink") {
          setError((prev) => ({
            ...prev,
            paymentServiceError: errorMessage,
          }));
        }
      } else {
        setError({
          bookingServiceError: null,
          paymentServiceError: null,
        });
      }

      setUserState((prev) => ({ ...prev, [type]: trimmedValue }));
    },
    [t, isValidUrl],
  );

  const deleteHandler = useCallback(
    (onYes, promptQuestion) => {
      customPrompt({
        message: promptQuestion,
        onYes: () => {
          onYes();
        },
        onCancel: () => {
          return;
        },
      });
    },
    [actions, self, userState, t],
  );

  const deleteCoverHandler = () => {
    const deleteCover = () => {
      setUploadedCover(null);
      if (!userState?.coverUrl) {
        setUserState({
          ...userState,
          cover_id: null,
        });
      } else {
        actions.updateCoachProfile({ remove_cover: true }).then(() => {
          actions.fetchCoachProfile(self?.id).then(() => {
            setToastAlert(t("Your account was successfully updated."));
          });
        });
      }
    };

    deleteHandler(
      deleteCover,
      t("Are you sure you want to delete the cover image?"),
    );
  };

  const deleteAvatarHandler = () => {
    const deleteCover = () => {
      setUploadedAvatar(null);
      if (!userState?.avatar_url) {
        setUserState({
          ...userState,
          avatar_url: null,
        });
      } else {
        actions
          .updateCoachProfile({
            remove_avatar: true,
            avatar_url: null,
          })
          .then(() => {
            actions.fetchCoachProfile(self?.id).then(() => {
              setToastAlert(t("Your account was successfully updated."));
            });
          });
      }
    };

    deleteHandler(
      deleteCover,
      t("Are you sure you want to delete the avatar?"),
    );
  };

  const hasErrors = (errorState: {
    bookingServiceError: string | null;
    paymentServiceError: string | null;
  }) => {
    return Object.values(errorState).some((error) => error !== null);
  };

  const updateUser = async () => {
    if (hasErrors(error)) {
      setToastAlert(null);
      setToastErrorrAlert(
        t("Please resolve the errors before updating your account."),
      );
      return;
    }

    setLoading(true);
    try {
      if (userState) {
        await actions.updateCoachProfile(userState);
        await actions.fetchCoachProfile(self?.id);

        setToastErrorrAlert(null);
        setToastAlert(t("Your account was successfully updated."));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during update:", error);
      setToastErrorrAlert(
        t(
          "An error occurred while updating your account. Please try again later.",
        ),
      );
      setToastAlert(null);
      setLoading(false);
    }
  };

  return {
    t,
    self,
    coachData,
    userState,
    updateUserData,
    uploadedAvatar,
    onCoverChange,
    uploadedCover,
    onUpload,
    onPhoneChange,
    phoneValue,
    updateUser,
    handleUrlInputUpdate,
    setToastAlert,
    toastAlert,
    toastErrorAlert,
    deleteCoverHandler,
    deleteAvatarHandler,
    error,
    loading,
  };
};
