import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "../styles.module.scss";
import ImageIco from "../../../../../public/images/image-icon.svg";
import { useTranslation } from "react-i18next";
import LoadingPlaceholder from "../LoadingPlaceholder";

const getColor = (props) => {
  if (props.isdragaccept) {
    return styles.accepted;
  }
  return "";
};

export default function PhotoUploader({
  type,
  uploadPhoto,
  imageLoader,
  customPlaceholderStyle = null,
  allowsDefault = false,
  onSelectClick = () => null,
}) {
  const { t } = useTranslation();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      try {
        await uploadPhoto(type, file);
      } catch (error) {
        console.log(error);
      }
    },
    [type, uploadPhoto, t]
  );

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
    multiple: false,
    noKeyboard: true,
    noClick: true,
  });

  return (
    <div
      className={styles.photo_uploader_container}
      data-testid="photo-uploader"
    >
      <div
        {...getRootProps({})}
        className={`
          ${styles.placeholder}
          ${
            type === "logo"
              ? styles.logo_placeholder
              : styles.banner_placeholder
          }
          ${customPlaceholderStyle}
          ${getColor({ isDragAccept })}
        `}
      >
        <input {...getInputProps()} />
        {imageLoader ? (
          <LoadingPlaceholder />
        ) : (
          <div className={styles.inside_box}>
            <img src={ImageIco} />
            <p>{t("Drop your image here or")}</p>
            <a onClick={open} className={styles.link}>
              {t("browse")}
            </a>
            {allowsDefault && (
              <>
                <span>{` or \n`}</span>
                <a onClick={onSelectClick} className={styles.link}>
                  {t(" select from our list.")}
                </a>
              </>
            )}
            <p className={styles.supports}>{t("Supports: JPG, JPEG, PNG")}</p>
            {type === "banner" && (
              <p className={styles.supports}>
                {t(
                  "Banner should be at least 980px wide and at most 250px tall for best quality."
                )}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
