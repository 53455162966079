import React from 'react';
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export default function StatusIndicator({ payment_status }) {
  const { t } = useTranslation();

  const status_html = (status) => {
    if(status === 'pending') {
      return (<div  className={styles.status_invoice}>{t("Invoice")}</div>);
    } else if (status === 'paid') {
      return (<div  className={styles.status_paid}>{t("Paid")}</div>);
    } else if (status === 'canceled') {
        return (<div  className={styles.status_failed}>{t("Canceled")}</div>);
    } else if (status === 'past_due') {
      return (<div  className={styles.status_failed}>{t("Past Due")}</div>);
    } else {
      return (<div  className={styles.status_failed}>{t("Failed")}</div>);
    }
  };
  return (
    status_html(payment_status)
  );
}
