import React, { useState, useEffect, useRef, LegacyRef } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { DateTime } from "luxon";
import styles from "./styles.module.scss";
import i18n from "app/i18n";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { CoachProfileModel } from "app/models/CoachProfileModel";

import en from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";

const locales = {
  en,
  ja,
};

type PropsDef = {
  date: DateTime;
  setDate: (date: DateTime) => void;
  formatDate?: (date: DateTime) => string;
  minDate?: Date | null;
  coach?: CoachProfileModel;
  minutesDuration?: number;
};

const defaultDateFormat = (date: DateTime): string => {
  return date.setLocale("en-us").toLocaleString(DateTime.DATE_FULL);
};

const DatePicker = (props: PropsDef) => {
  const {
    date,
    setDate,
    formatDate = defaultDateFormat,
    minDate = null,
    coach,
    minutesDuration,
  } = props;
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [excludeDates, setExcludeDates] = useState<Date[]>([]);
  const closing = useRef(false);

  const fetchExcludedDates = async (currentDate: DateTime) => {
    try {
      const params = {
        from: DateTime.local(
          currentDate.year,
          currentDate.month,
          1,
        ).toISODate(),
        to: DateTime.local(
          currentDate.year,
          currentDate.month,
          currentDate.daysInMonth,
        ).toISODate(),
        coach_profile_ids: [coach.externalId],
        slot_duration: minutesDuration,
      };

      const data = await schedulingFetchGet("/available_dates", params, {}, null, true);

      if(!data[0].dates) {
        data[0].dates = [];
      }

      const availableDates = new Set(
        data.flatMap((profile: any): void =>
          profile.dates.map((dateString: string): string =>
            DateTime.fromISO(dateString).toISODate(),
          ),
        ),
      );

      const allDatesInMonth = Array.from(
        { length: currentDate.daysInMonth },
        (_, i) =>
          DateTime.local(
            currentDate.year,
            currentDate.month,
            i + 1,
          ).toISODate(),
      );

      const formattedDates = allDatesInMonth
        .filter((day: string): boolean => !availableDates.has(day))
        .map((day: string): Date => DateTime.fromISO(day).toJSDate());

      setExcludeDates(formattedDates);
    } catch (error) {
      console.error("Error fetching excluded dates:", error);
    }
  };

  useEffect((): void => {
    if (coach) {
      fetchExcludedDates(date);
    }
  }, [date]);

  useEffect((): void => {
    const currentLocale = locales[i18n.language] || locales.en;
    registerLocale(i18n.language, currentLocale);
  }, [i18n.language]);

  const CustomInput = React.forwardRef((_props, ref) => {
    return (
      <div
        className={styles.title_container}
        onClick={handleClick}
        ref={ref as LegacyRef<HTMLInputElement>}
      >
        <div className={styles.title}>{formatDate(date)}</div>
        <div
          className={styles.offset}
          style={{ transform: `${datePickerOpen ? "rotate(180deg)" : "none"}` }}
        >
          <i className="ico ico-chevron" />
        </div>
      </div>
    );
  });
  CustomInput.displayName = "HeaderInput";

  const handleChange = (value: Date) => {
    setDate(DateTime.fromJSDate(value));
    setDatePickerOpen(false);
  };

  const handleClick = (e: any): void => {
    e.preventDefault();
    if (!closing.current) {
      setDatePickerOpen(!datePickerOpen);
    }
  };

  const handleCalendarClose = () => {
    closing.current = true;
    setDatePickerOpen(false);

    setTimeout(() => {
      closing.current = false;
    }, 200);
  };

  const handleInputClick = () => {
    if (!datePickerOpen && !closing.current) {
      setDatePickerOpen(true);
    }
  };

  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    const handleDecreaseMonth = () => {
      const newDate = date.minus({ months: 1 });
      setDate(newDate);
      fetchExcludedDates(newDate);
      decreaseMonth();
    };

    const handleIncreaseMonth = () => {
      const newDate = date.plus({ months: 1 });
      setDate(newDate);
      fetchExcludedDates(newDate);
      increaseMonth();
    };

    return (
      <div className={styles.header}>
        <div
          className={styles.date_arrow}
          style={{ transform: "rotate(90deg)" }}
          onClick={handleDecreaseMonth}
        >
          <span>
            {" "}
            <i className="ico ico-chevron" />{" "}
          </span>
        </div>
        <p className={styles.current_month}>
          {date.toLocaleString({ month: "long", year: "numeric" })}
        </p>
        <div
          className={styles.date_arrow}
          style={{ transform: "rotate(-90deg)" }}
          onClick={handleIncreaseMonth}
        >
          <span>
            {" "}
            <i className="ico ico-chevron" />{" "}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.date_picker}>
      <ReactDatePicker
        locale={i18n.language}
        selected={date.toJSDate()}
        onChange={(value: Date): void => handleChange(value)}
        dateFormat="MMMM, d, yyyy"
        useWeekdaysShort={true}
        renderCustomHeader={({
          date: selDate,
          decreaseMonth,
          increaseMonth,
        }): React.ReactElement => (
          <CustomHeader
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            date={DateTime.fromJSDate(selDate)}
          />
        )}
        customInput={<CustomInput />}
        open={datePickerOpen}
        onClickOutside={handleCalendarClose}
        onCalendarClose={handleCalendarClose}
        onInputClick={handleInputClick}
        minDate={minDate}
        excludeDates={excludeDates}
        calendarClassName={styles.day_picker}
        disabledKeyboardNavigation
      />
    </div>
  );
};

export default DatePicker;
