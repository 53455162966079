import styles from "./styles.module.scss";
import React from "react";
import { useHistory } from "react-router-dom";
import OfferingOptions from "./OfferingOptions";
import Navbar from "app/components/Navbar";
import { useTranslation } from "react-i18next";

const NewOffering = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const navigateBack = () => {
    history.goBack();
  };

  return (
    <div>
      <Navbar label={t("Offerings")} hasSearch={false} hasNotifications={false} onBackClick={navigateBack}/>
      <div className={styles.empty_container}>
        <OfferingOptions/>
      </div>
    </div>
  );
};

export default NewOffering;
