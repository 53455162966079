import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import Navbar from "app/routes/Facility/Navbar";
import PagePanel from "app/components/PagePanel";
import {useTranslation} from "react-i18next";
import UserItem from "app/routes/FacilityUsers/UserItem";
import AddFacilityUser from "app/routes/FacilityUsers/AddFacilityUser";
import {fetchDelete, fetchGet, fetchPost, fetchPut} from "app/utils/request";
import {toast} from "react-toastify";
import useCurrentFacility from 'app/utils/hooks/scheduling/useCurrentFacility';
import { Loading } from 'app/components/Wrappers';
import { schedulingFetchGet } from 'app/utils/request/scheduling/fetch';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isAdmin: boolean;
}

const FacilityUsers = () => {
  const { t } = useTranslation();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const currentFacility = useCurrentFacility();
  const [users, setUsers] = useState([]);

  const openAddModal = () => setAddModalOpen(true);
  const closeAddModal = () => setAddModalOpen(false);

  const facilityId = currentFacility.data?.id;

  useEffect(() => {
    if(!facilityId) { return; }
    fetchGet(`/facilities/${facilityId}/memberships`, {}, {version: 1})
      .then((response) => {
        setUsers(response);
      }).catch((error) => {
        toast.error("Failed to fetch users");
        console.error(error);
      });
  }, [facilityId]);

  if (!facilityId) {
    return <Loading loadType="spinner" />;
  }

  const handleAddUser = (user: User) => {
    fetchPost(`/facilities/${facilityId}/memberships`, { membership: user }, {version: 1})
      .then((response) => {
        setUsers([...users, response]);
        toast.success("User added successfully");
      }).catch((error) => {
        toast.error("Failed to add user");
        console.error(error);
      });
  };

  const checkCoachRemoval = (coach: User) => {
    if (coach.role !== 'coach') {
      return Promise.resolve();
    }

    return schedulingFetchGet(`/coach_profiles/${coach.id}/removable`);
  };

  const handleRemoveUser = (user: User) => {
    checkCoachRemoval(user)
      .then(() => {
        return fetchDelete(`/facilities/${facilityId}/memberships/${user.id}`, {}, {version: 1})
        .then(() => {
          setUsers(users.filter((u) => u.id !== user.id));
          toast.success("User removed successfully");
        }).catch((error) => {
          toast.error("Failed to remove user");
          console.error(error);
        });
      })
      .catch((err) => {
        if (err.status === 409) {
          toast.error(t("Coach is booked for future programs and cannot be deleted"));
        } else {
          toast.error("Failed to remove user");
        }
      });
  };

  const handleEditUser = (user: User) => {
    fetchPut(`/facilities/${facilityId}/memberships/${user.id}`, { membership: user }, {version: 1})
      .then(() => {
        setUsers((prevUsers) =>
          prevUsers.map((u) => {
            if (u.id === user.id) {
              return {
                ...user,
                role: user.role === "coach" && user.isAdmin ? "coach - admin" : user.role,
              };
            }
            return u;
          })
        );
        toast.success("User updated successfully");
      }).catch((error) => {
        toast.error("Failed to update user");
        console.error(error);
      });
  };

  return (
    <div className="calendar-page-container">
      <div className={styles.title}>
        <h1>{t("Admin Settings")}</h1>
      </div>
      <div>
        <Navbar location={"users"}/>
        <PagePanel
          title={t("Users")}
          titleStyles={styles.header}
          headerChildren={(
            <div className={styles.page_panel}>
              <button
                type="button"
                className={styles.button2}
                onClick={openAddModal}
              >
                <span className={styles.fullText}>+ {t("Add User")}</span>
                <span className={styles.shortText}>+ {t("Add")}</span>
              </button>
            </div>
          )}>
          {users.map((user) => (
            <UserItem
              key={user.id}
              user={user}
              onRemove={handleRemoveUser}
              onEdit={handleEditUser}
            />
          ))}
        </PagePanel>
        <AddFacilityUser
          isOpen={isAddModalOpen}
          onClose={closeAddModal}
          users={users}
          onAddUser={handleAddUser}
        />
      </div>
    </div>
  );
};

export default FacilityUsers;
