export const bill_start_options = [{ value: "today", label: "Today"},
{ value: "first_of_month", label: "1st of Month" },
{ value: "fifteenth_of_month", label: "15th of Month" }];

export const currency_symbol = { 'USD': '$',
    'AUD': '$' ,
    'GBP': '£',
    'CAD': '$',
    'EUR': '€',
    'JPY': '¥',
    'AED': 'AED',
    'MXN': 'MXN',
    'NZD': '$',
    'TRY': '₺',
};

export const currency = [{ value: 'USD', label: 'US Dollar (USD, $)' },
    { value: 'AUD', label: 'Australian Dollar (AUD, $)' },
    { value: 'GBP', label: 'British Pound (GBP, £)'},
    { value: 'CAD', label: 'Canadian Dollar (CAD, $)' },
    { value: 'EUR', label: 'Euro (EUR, €)', currency: '€' },
    { value: 'JPY', label: 'Japanese Yen (JPY, ¥)'},
    { value: 'AED', label: 'United Arab Emirates Dirham (AED)' },
    { value: 'MXN', label: 'Mexican Peso (MXN)' },
    { value: 'NZD', label: 'New Zealand Dollar (NZD, $)' },
    { value: 'TRY', label: 'Turkish Lira (TRY, ₺)' },
  ];
