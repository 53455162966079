import React, { useRef, useState, useEffect } from 'react';
import { useError } from 'app/utils/hooks';
import { UserModel } from 'app/models';
import { isValidEmail } from 'app/utils/validate';
import { cls } from 'app/utils';
import Styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import {TemplateSelect} from "app/components/inputs/SelectBox";
import {DefaultOption} from "app/components/inputs/SelectBox/Base";
import {newSpaceValues} from "app/actions/spaceCreation";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "typedefs";
import SwitchToggle from "app/components/OptionButtonGroup/SwitchToggle";
import {fetchPost} from "app/utils/request";
import {toast} from "react-toastify";
import styles from "app/routes/Calendar/EventForm/styles.module.scss";
import CloseIcon from "../../../public/images/close.svg";

interface AddNewStudentProps {
  inputValue?: string;
  getLabel: () => string;
  onDone: (user: UserModel) => void;
  onCancel: () => void;
}

interface CreateNewErrorState {
  firstNameError?: string;
  lastNameError?: string;
  emailError?: string;
}

const initError: CreateNewErrorState = {
  firstNameError: null,
  lastNameError: null,
  emailError: null,
};

interface State {
  firstName: string;
  lastName: string;
  email: string;
  templateId: string;
  newSpace: boolean;
  focusElement: React.MutableRefObject<any>;
}

const initState: State = {
  firstName: '',
  lastName: '',
  email: '',
  templateId: '',
  newSpace: false,
  focusElement: null,
};

export default function AddNewStudent(props: AddNewStudentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [createSpace, setCreateSpace] = useState(false);
  const [templateId, setTemplateId] = useState("");

  const [ errors, addErrors, clearErrors ] = useError(initError);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const [ state ] = useState(() => {
    let firstName = "";
    let lastName = "";
    let email = "";
    let focusElement = firstNameRef;
    if (props.inputValue != null) {
      if (props.inputValue.indexOf('@') !== -1) {
        email = props.inputValue;
        if (!/^.+@.+\..+$/.test(props.inputValue)) {
          focusElement = emailRef;
        }
      } else {
        firstName = props.inputValue;
        if (/^.+\s...+$/.test(props.inputValue)) {
          focusElement = emailRef;
        }
        lastName = props.inputValue;
      }
    }
    return { ...initState, firstName, lastName, email, focusElement };
  });

  const handleAddContact = (student: any) => {
    return fetchPost(`/contacts`, student);
  };

  const registerNewStudent = (student: any) => {
    const newContact = {
      first_name: student.firstName,
      last_name: student.lastName,
      email: student.email,
      role: "athlete",
      sync_now: true
    };
    return handleAddContact(newContact);
  };

  const addUser = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event != null) { event.preventDefault(); }
    clearErrors();

    const email = emailRef.current.value;
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;

    const err: CreateNewErrorState = {};
    if (!(email && email.length)) {
      err.emailError = t("Email is required");
    } else if (!isValidEmail(email)) {
      err.emailError = t("A valid email is required");
    }

    if (!(firstName && firstName.length)) {
      err.firstNameError = t("First Name is required");
    }

    if (!(lastName && lastName.length)) {
      err.lastNameError = t("First Name is required");
    }

    if (Object.keys(err).length) {
      addErrors(err);
      return;
    }

    const user: Partial<UserModel> = { email, firstName, lastName };

    registerNewStudent(user).then((resp) => {
      const user = {
        ...resp as UserModel,
        displayName: firstName + ' ' + lastName,
        newSpace: createSpace,
        templateId,
      };

      if (props.onDone != null) {
        props.onDone(user as UserModel);
      }
      toast.success(t("Contact created successfully."));
    }).catch((error) => {
      toast.error(t("An unexpected error has occurred."));
      console.error(error);
    });
  };

  const keyHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.keyCode === 13) {
      event.preventDefault();
      addUser();
    } else if (event.keyCode === 27) {
      props.onCancel();
    }
    return false;
  };

  const { spaces_import } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual,
  );

  const templateSelectHandler = (value: DefaultOption) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        space_template_id: {
          label: value ? value.label : "",
          id: value ? value.id : "",
        },
      }),
    );
    setTemplateId(value.id);
  };

  useEffect(() => {
    if (state.focusElement != null && state.focusElement.current != null) {
      state.focusElement.current.focus();
    }
  }, [state.focusElement]);

  return (
    <>
      <div className={styles.header}>
        <h2>{props.getLabel()}</h2>
        <img className={styles.modal_status_icon} src={CloseIcon} alt="info" onClick={props.onCancel} />
      </div>

      <div className={Styles.form}>
        <div className={cls(Styles.formItem, errors.firstNameError ? Styles.invalid : '')}>
          <label>{t('First Name')}</label>
          <input
            ref={firstNameRef}
            type="text"
            autoComplete="none"
            spellCheck={false}
            onKeyDown={keyHandler}
            defaultValue={state.firstName}
          />
          <div className={Styles.error}>
            {errors.firstNameError}
          </div>
        </div>

        <div className={cls(Styles.formItem, errors.lastNameError ? Styles.invalid : '')}>
          <label>{t('Last Name')}</label>
          <input
            ref={lastNameRef}
            type="text"
            autoComplete="none"
            spellCheck={false}
            onKeyDown={keyHandler}
            defaultValue={state.lastName}
          />
          <div className={Styles.error}>
            {errors.lastNameError}
          </div>
        </div>

        <div className={cls(Styles.formItem, errors.emailError ? Styles.invalid : '')}>
          <label>{t('Email')}</label>
          <input
            ref={emailRef}
            type="text"
            autoComplete="none"
            spellCheck={false}
            onKeyDown={keyHandler}
            defaultValue={state.email}
          />
          <div className={Styles.error}>
            {errors.emailError}
          </div>
        </div>

        <hr className={Styles.separator} />

        <div>
          <p className={Styles.mini_title}>{t("Create Space")}</p>
          <p className={Styles.text}>{t("Automatically create a Space for a new Athlete. You can also apply one of your templates for this Space.")}</p>
        </div>

        <SwitchToggle
          checked={createSpace}
          onChange={() => setCreateSpace(!createSpace)}
          title={t('Create Space')}
        />

        {createSpace && (
          <div className={cls(Styles.formItem, errors.emailError ? Styles.invalid : '')}>
            <label>{t('Apply Template')}</label>
            <TemplateSelect
              onChange={templateSelectHandler}
              isSearchable
            />
          </div>
        )}
      </div>

      <div className={Styles.form_actions}>
        <button className="btn btn-outline-secondary" onClick={props.onCancel}>
          {t('Cancel')}
        </button>
        <button className="btn btn-primary" onClick={addUser}>
          {t('Add')}
        </button>
      </div>
    </>
  );
}
