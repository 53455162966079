import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import UserAvatar from "app/components/UserAvatar";
import { useConstants } from "../../Scheduling/constants";
import SearchImage from "../../../../public/images/search.svg";

interface Customer {
  studentProfileId: number;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  totalSpent: string;
  remainingSessions: number;
  totalSessions: number;
  payments: number;
  date: string;
  currency: string;
}

interface CustomerTableProps {
  customers: Customer[];
  formatDate: (isoDate: string) => string;
}

export default function CustomerTable({
  customers,
  formatDate,
}: CustomerTableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const { currency_list } = useConstants();

  const filteredCustomers = customers.filter(
    (customer) =>
      customer?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer?.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getCurrencySymbol = (currencyCode) => {
    const currency = currency_list.find((c) => c.value === currencyCode);
    return currency ? currency.currency : currencyCode;
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.searchAndFilter}>
        <div className={styles.search_container}>
          <input
            className={styles.search}
            placeholder={t("Search Customer...")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <img src={SearchImage} alt="Search" />
        </div>
      </div>

      {filteredCustomers.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t("Athlete Name")}</th>
              <th>{t("Total Spend")}</th>
              <th>{t("Payments")}</th>
              <th>{t("Remaining Sessions")}</th>
              <th>{t("Activation Date")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer, index) => (
              <tr
                key={index}
                className={styles.clickableRow}
                onClick={() =>
                  history.push(`/billing/${customer.studentProfileId}`, {
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    email: customer.email,
                    profilePicture: customer.profilePicture,
                    currency: customer.currency,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <td className={styles.athlete}>
                  <UserAvatar
                    srcName={customer.profilePicture}
                    userName={`${customer.firstName} ${customer.lastName}`}
                    altName="avatar"
                    className={styles.avatar}
                    sizeHeight={40}
                    sizeWidth={40}
                  />
                  <div>
                    <strong>
                      {customer.firstName} {customer.lastName}
                    </strong>
                    <br />
                    <span className={styles.email}>{customer.email}</span>
                  </div>
                </td>
                <td>
                  {getCurrencySymbol(customer.currency)}
                  {customer.totalSpent}
                </td>
                <td>{customer.payments}</td>
                <td>
                  {customer.remainingSessions === -1 ? (
                    <span className={styles.infinitySymbol}>&infin;</span>
                  ) : (
                    customer.remainingSessions
                  )}
                </td>
                <td>{formatDate(customer.date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles.customers_placeholder}>
          <div className={styles.no_customers_text_customers}>
            {t("No customers found.")}
          </div>
        </div>
      )}
    </div>
  );
}
