import { useActions, useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import { PostingFormProps } from "./index.types";
import { useCallback, useEffect, useState } from "react";
import {
  clearMediaState,
  fetchMediaById,
} from "app/actions/media-library-data";
import { clearDestinations } from "app/actions/destinations";
import { PostingModel } from "app/models/PostModel";
import TagModel from "app/models/TagModel";
import { toInteger } from "lodash";
import { shallowEqual } from "react-redux";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";

const INITIAL_POST_LOCATIONS = {
  smart_list_ids: [],
  space_id: [],
  team_ids: [],
  segment_ids: [],
  team_group_ids: [],
};

const POLLING_INTERVAL = 3000;

const usePostingForm = ({
  post,
  attachmentProps,
  onPost,
  onCancel,
  expanded,
  postTextIsRequired,
  locationsIsRequired,
  scheduleIsRequired,
  hasSchedule,
  scheduledInActive,
}: PostingFormProps) => {
  const resourceActions = useActions({
    fetchMediaById,
    clearMediaState,
    clearDestinations,
  });

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { pending } = useSelector(
    (state: RootState) => state.mediaLibraryData,
    shallowEqual,
  );
  const selectedLocations = useSelector(
    (state: RootState) => state.destinations.selectedDestinations,
    shallowEqual,
  );

  const [attachment, setAttachment] = useState<MediaTypes | null>(
    post?.resources?.[0] || null,
  );
  const [isProcessed, setIsProcessed] = useState(
    attachment?.processed || false,
  );
  const [postTextError, setPostTextError] = useState<boolean>(false);
  const [postLocationsError, setPostLocationsError] = useState<boolean>(false);
  const [scheduleError, setScheduleError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [postLocations, setPostLocations] = useState(INITIAL_POST_LOCATIONS);
  const [postingFormIsExpanded, setPostingFormIsExpanded] = useState<boolean>(
    expanded || false,
  );
  const [pickedSchedule, setPickedSchedule] = useState(null);
  const [newTags, setNewTags] = useState<any>(
    attachment?.tags || post?.tags || [],
  );
  const [newPostData, setNewPostData] = useState<PostingModel | null>(
    post || {
      details: "",
      tags: newTags,
      resource_id: attachment?.id,
      resource_type: attachment?.type,
    },
  );
  const [paywallIsOpened, setPaywallIsOpened] = useState(false);

  // Computed values
  const scheduleVisible = hasSchedule && self?.kind === "coach";

  // Handlers
  const postingFormExpandHandler = () => {
    setPostingFormIsExpanded((prev) => !prev);
  };

  const postingFormShrinkHandler = useCallback(() => {
    setPostingFormIsExpanded(false);
  }, []);

  const paywallModalHandler = () => {
    setPaywallIsOpened((prev) => !prev);
  };

  const onSelectMedia = (media: MediaTypes) => {
    setAttachment(media);
  };

  const onClearMedia = () => {
    setAttachment(null);
  };

  const textAreaHandler = useCallback((text: string) => {
    setNewPostData((prevData) => ({
      ...prevData,
      details: text,
    }));
  }, []);

  const onTagsChange = (tags: TagModel[]) => {
    if (tags) {
      setNewTags(tags as any);
      const tagNames = tags.map((item: { name: string }) => item.name);
      setNewPostData((prevData: any) => ({
        ...prevData,
        tags: tagNames,
      }));
    }
  };

  const onScheduleChange = useCallback(
    (pickedAt: number, pickedTime?: "Days" | "Hours" | "Weeks") => {
      if (pickedAt) {
        setPickedSchedule(pickedAt);

        if (scheduledInActive) {
          let calculatedTime = toInteger(pickedAt);
          if (pickedTime === "Days") {
            calculatedTime = toInteger(pickedAt) * 24;
          } else if (pickedTime === "Weeks") {
            calculatedTime = toInteger(pickedAt) * 7 * 24;
          }

          setNewPostData((prevData: PostingModel) => ({
            ...prevData,
            scheduled_in: calculatedTime,
          }));
        } else {
          setNewPostData((prevData: PostingModel) => ({
            ...prevData,
            scheduled_at: new Date(pickedAt).toISOString(),
          }));
        }
      }
    },
    [scheduledInActive],
  );

  const clearPostingForm = useCallback(() => {
    resourceActions.clearMediaState();
    resourceActions.clearDestinations();
    postingFormShrinkHandler();
    setErrorMessage(null);
    setPostTextError(false);
    setPostLocationsError(false);
    setScheduleError(false);
    setPostLocations(null);
    setNewPostData(null);
    setPickedSchedule(null);
    setNewTags(null);
    setAttachment(null);
  }, [resourceActions, postingFormShrinkHandler]);

  const onSubmitFunction = () => {
    // Validate form if required fields are specified
    if (postTextIsRequired || locationsIsRequired || scheduleIsRequired) {
      // Validate post text
      if (
        !attachment &&
        postTextIsRequired &&
        (!newPostData?.details || newPostData.details.trim() === "")
      ) {
        setPostTextError(true);
        return;
      } else if (postTextError) {
        setPostTextError(false);
      }

      // Validate locations
      if (
        locationsIsRequired &&
        (!selectedLocations || !selectedLocations.length)
      ) {
        setPostLocationsError(true);
        return;
      } else if (postLocationsError) {
        setPostLocationsError(false);
      }

      // Validate schedule
      if (scheduleIsRequired && !pickedSchedule) {
        setScheduleError(true);
        return;
      } else if (scheduleError) {
        setScheduleError(false);
      }
    }

    // Submit and reset form
    Promise.resolve(onPost(newPostData));
    clearPostingForm();
  };

  const onCancelHandler = () => {
    clearPostingForm();
    if (onCancel) {
      onCancel();
    }
  };

  // Effects
  // Handle attachment props updates
  useEffect(() => {
    if (attachmentProps) {
      setAttachment(attachmentProps);
      setNewTags(attachmentProps?.tags);
    }
  }, [attachmentProps]);

  // Handle attachment processing
  useEffect(() => {
    setNewPostData((prevData) => ({
      ...prevData,
      resource_id: attachment?.id,
      resource_type: attachment?.type,
    }));

    // Set up polling for video processing
    const pollingTimer = setInterval(() => {
      if (attachment?.processed) {
        clearInterval(pollingTimer);
        setIsProcessed(true);
      }

      if (attachment?.type === "video" && !isProcessed) {
        resourceActions.fetchMediaById(attachment.id);
      }
    }, POLLING_INTERVAL);

    return () => clearInterval(pollingTimer);
  }, [attachment, isProcessed, resourceActions]);

  // Handle selected locations
  useEffect(() => {
    if (selectedLocations?.length > 0) {
      if (errorMessage) {
        setErrorMessage(null);
      }

      selectedLocations.forEach((obj: { id: string; object: string }) => {
        let locationKey = "team_group_ids";

        switch (obj.object) {
          case "smart_list":
            locationKey = "smart_list_ids";
            break;
          case "athlete_space":
            locationKey = "space_id";
            break;
          case "team":
            locationKey = "team_ids";
            break;
          case "segment":
            locationKey = "segment_ids";
            break;
        }

        setPostLocations((prevState: any) => ({
          ...(prevState ?? {}),
          [locationKey]: [...(prevState[locationKey] || []), obj.id],
        }));
      });
    }

    return () => {
      setPostLocations(INITIAL_POST_LOCATIONS);
    };
  }, [selectedLocations, errorMessage]);

  // Update post data when locations change
  useEffect(() => {
    setNewPostData((prevData) => ({
      ...prevData,
      ...postLocations,
    }));
  }, [postLocations]);

  // Cleanup on unmount
  useEffect(() => {
    return clearPostingForm;
  }, [clearPostingForm]);

  return {
    pending,
    postTextError,
    textAreaHandler,
    onTagsChange,
    onScheduleChange,
    onSelectMedia,
    onClearMedia,
    newPostData,
    postingFormExpandHandler,
    postingFormIsExpanded,
    pickedSchedule,
    onSubmitFunction,
    onCancelHandler,
    newTags,
    postLocationsError,
    scheduleError,
    attachment,
    paywallModalHandler,
    paywallIsOpened,
    scheduleVisible,
  };
};

export default usePostingForm;
