import React from "react";
import { DateTime } from "luxon";

import DatePicker from "app/components/DatePicker";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { cls } from "app/utils";

type PropsDef = {
  date: DateTime;
  onNext: () => void;
  onPrev: () => void;
  onDateChange: (date: DateTime) => void;
  handleCalendarSettings?: () => void;
  initNewEvent: () => void;
  calendarView: string;
  dateFormat: (date: DateTime, calendarView: string) => string;
};

export default function CalendarToolbar(props: PropsDef) {
  const {
    date,
    onNext,
    onPrev,
    onDateChange,
    handleCalendarSettings,
    initNewEvent,
    calendarView,
    dateFormat,
  } = props;
  const { t } = useTranslation();

  if (!date) {
    return null;
  }
  return (
    <div className={styles.calendar_toolbar_container}>
      <div className={styles.toolbar_left}>
        <div className={styles.toolbar_date}>
          <DatePicker
            date={date}
            setDate={onDateChange}
            formatDate={(date) => dateFormat(date, calendarView)}
          />
        </div>
      </div>

      <div className={styles.toolbar_right}>
        <button
          className={cls(
            styles.toolbar_button_left,
            "btn",
            "btn-outline-secondary",
          )}
          style={{ transform: "rotate(90deg)" }}
          onClick={onPrev}
        >
          <span className={styles.arrow_container}>
            <i className="ico ico-chevron" />
          </span>
        </button>
        <button
          onClick={() => onDateChange(DateTime.now())}
          type="button"
          className={cls(styles.button_today)}
        >
          {t("Today")}
        </button>
        <button
          className={cls(
            styles.toolbar_button_right,
            "btn",
            "btn-outline-secondary",
          )}
          style={{ transform: "rotate(-90deg)" }}
          onClick={onNext}
        >
          <span className={styles.arrow_container}>
            <i className="ico ico-chevron" />
          </span>
        </button>

        <div className={styles.divider} />

        <button
          onClick={initNewEvent}
          type="button"
          className={cls(styles.button_primary)}
        >
          <span>+</span>
          {t("Add")}
        </button>

        {handleCalendarSettings && (
          <button
            type="button"
            className={cls(
              styles.page_panel_settings_button,
              styles.toolbar_settings,
              "btn",
              "btn-outline-secondary",
            )}
            onClick={handleCalendarSettings}
          >
            <i className={cls("ico ico-cog", styles.icon_settings)} />
          </button>
        )}
      </div>
    </div>
  );
}
