import React, { useState } from 'react';
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import UserAvatar from 'app/components/UserAvatar';
import renew from "../../../../../public/images/product-renew.svg";
import cancel from "../../../../../public/images/stripe-cancel-sub.svg";
import { DateTime } from 'luxon';
import StatusIndicator from '../../StatusIndicator';
import { schedulingFetchDelete } from 'app/utils/request/scheduling/fetch';
import { toast } from 'react-toastify';
import OfferingActionModal from '../../OfferingActionModal';

export default function AthleteListItem({ athlete, productId, coachId, fetchHandler, product}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    setShowModal(false);
    const productData = {
      coach_id: coachId,
      stripe_product_id: productId,
      student_profile_id: athlete.studentProfileId,
    };

    schedulingFetchDelete(`/stripe_purchase`, productData)
      .then((_) => {
        toast.success("Subscription canceled");
        fetchHandler();
      })
      .catch((_) => {
        toast.error("An unexpected error has occurred.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formattedDate = athlete?.renewalDate
  ? DateTime.fromISO(athlete.renewalDate).toFormat('MMM d')
  : t("N/A");

  const modalContent = (
    <div>
      {t("Are you sure you want to cancel the athlete's")}{" "}
      <strong style={{ color: "grey" }}>{product?.title}</strong>{" "}
      {t("subscription?")}
      <br />
      {t("If you cancel, they won’t be charged at the next billing cycle.")}
      <br />
      {t("It will remain valid until")}{" "}
      <strong style={{ color: "grey" }}>{formattedDate}</strong>.
      <br />
    </div>
  );

  return (
    <div className={styles.athlete_list_item}>
      <div className={styles.name_container}>
        <div className={styles.avatar}>
          <UserAvatar
              srcName={athlete.profilePicture}
              userName={athlete.firsName + " " + athlete.lastName}
              altName="avatar"
              className={styles.avatar}
              sizeHeight={40}
              sizeWidth={40}
            />
        </div>
        <div>{athlete.firstName} {athlete.lastName}</div>
      </div>
        <div className={styles.cycles}>{athlete.cycles}</div>
        <StatusIndicator payment_status={athlete.status}/>
        <div className={styles.date}>
          <div>{athlete.renewalDate !== 'N/A' ? DateTime.fromISO(athlete.renewalDate).toFormat('MMM d, yyyy') : '-'}</div>
          {athlete.subscriptionType === 'invoice'? <div className={styles.invoice}>{t('Invoice')}</div> :
          (
          <div className={styles.renewal}>
             <img src={renew} alt="close"/>
              <div>{t('Auto-renew')}</div>
          </div>
          )}
        </div>
        { athlete.renewalDate !== 'N/A' && athlete.status === 'paid' ?
         (
          <div className={styles.cancel_sub}>
            <img src={cancel} onClick={loading ? undefined : () => setShowModal(true)} alt="close"/>
          </div>
         ) : null }

      {showModal && (
        <OfferingActionModal
          actions={{
            onCancel: handleCancel,
            onConfirm: handleConfirm,
          }}
          data={{
            title: t("Are you sure you want to cancel subscription?"),
            content: modalContent,
            button_text: t("Yes, cancel it"),
            operation: "delete",
          }}
          cancelButton={t("No, keep it")}
        />
      )}
    </div>
  );
}
