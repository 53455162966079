import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import LinkSquare from "../../../public/images/link-square.svg";
import ViewProgramCard from "../../components/Programs/ViewProgramCard";
import { schedulingFetchGet } from "../../utils/request/scheduling/fetch";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProgramState } from "app/reducers/scheduling/program";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";
import useCurrentFacility from "app/utils/hooks/scheduling/useCurrentFacility";

interface Params {
  program_id: string;
}

const ViewPrograms = ({ isPublic = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { program_id } = useParams<Params>();
  const [program, setProgram] = useState<ProgramState>();
  const userState = useSelector(
    (state: RootState): UserModel => state.user.data,
  );
  const [refetch, setRefetch] = useState(true);
  const userType = userState?.kind;
  const currentFacility = useCurrentFacility();
  const facilityId = currentFacility?.data?.id;

  useEffect(() => {
    if (refetch) {
      schedulingFetchGet(`/programs/${program_id}`, {}, {}, null, isPublic)
        .then((result) => {
          setProgram(result);
          setRefetch(false);
        })
        .catch((error) => {
          setRefetch(false);
          history.push("/programs");
          toast.error(error.errors);
        });
    }
  }, [refetch]);

  const [tooltipText, setTooltipText] = useState("Copy URL");
  let url = window.location.href;

  const handleButtonClick = () => {
    const privacy = program?.privacy;
    if (privacy === "public" && !url.includes("/public")) {
      url = url.replace("/programs", "/public/programs");
    }
    navigator.clipboard.writeText(url);

    setTooltipText(t("URL copied to clipboard"));

    setTimeout(() => {
      setTooltipText(t("Copy URL"));
    }, 2000);
  };

  return (
    <div
      className={`programs-page-container ${url.includes("/public") ? styles.public_container : styles.container}`}
    >
      <div className={styles.imageContainer}>
        <img src={program?.externalHeaderUrl} />
        {userType === "coach" && facilityId === program?.facilityId && (
          <div className={styles.getLinkButton} onClick={handleButtonClick}>
            <span className={styles.iconWrapper}>
              <img src={LinkSquare} />
            </span>
            <div className={styles.tooltip_container}>
              <span className={styles.tooltip_button}>{t("Get Link")}</span>
              <span className={styles.tooltip}>{tooltipText}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.container}>
        <ViewProgramCard
          setRefetch={setRefetch}
          program={program}
          programId={program_id}
          facilityId={program?.facilityId}
          isPublic={url.includes("/public")}
        />
      </div>
    </div>
  );
};

export default ViewPrograms;
