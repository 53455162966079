import React from "react";
import styles from "./styles.module.scss";
import UserAvatar from "app/components/UserAvatar";
import SubscriptionCard from "./SubscriptionCard";
import NumberOfSessions from "../../Offerings/Offering/NumberOfSessions";
import OfferingCard from "./OfferingCard";
import { useTranslation } from "react-i18next";

interface Subscription {
  fullName?: string;
  profilePicture: string;
  offeringTitle: string;
  billingFrequency: number | null;
  nextInvoiceDate: string | null;
  productPrice: number;
  currency: string;
  remainingSessions: number | null;
  unlimited?: boolean;
  productType?: string;
  productId?: string;
  coachId?: string;
  studentProfileId?: string;
}

interface SubscriptionCardWithCoachProps {
  subscription: Subscription;
  coachName: string;
  coachImage: string;
  formatInvoiceDate: (isoDate: string | null) => string;
  handleDelete: (productId: string) => void;
}

const SubscriptionCardWithCoach: React.FC<SubscriptionCardWithCoachProps> = ({
  subscription,
  coachName,
  coachImage,
  formatInvoiceDate,
  handleDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.subscriptionCardWithCoach}>
      <div className={styles.coachInfo}>
        <div className={styles.coachInfo}>
          <UserAvatar
            srcName={coachImage}
            userName={coachName}
            className={styles.avatar2}
            altName="coach-avatar"
            sizeHeight={40}
            sizeWidth={40}
          />
          <span className={styles.coachName}>
            {t("Coach: {{coachName}}", { coachName })}
          </span>
        </div>
        <NumberOfSessions
          numberOfSessions={subscription.remainingSessions}
          unlimited={subscription.remainingSessions === -1}
        />
      </div>

      <div className={styles.divider2}></div>
      {subscription.productType === "subscription" ? (
        <SubscriptionCard
          subscription={subscription}
          formatInvoiceDate={formatInvoiceDate}
          handleDelete={handleDelete}
        />
      ) : (
        <OfferingCard subscription={subscription} />
      )}
    </div>
  );
};

export default SubscriptionCardWithCoach;
