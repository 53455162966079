import styles from "../styles.module.scss";
import React, { useState } from "react";
import PhotoPreview from "../PhotoPreview";
import FailContainer from "../FailContainer";
import PhotoUploader from "../PhotoUploader";
import upload from "app/utils/uploader";

interface IProps {
  imageLink: string;
  title?: string | null;
  containerStyle?: string;
  description?: string | null;
  customPlaceholderStyle?: string;
  type: string | null;
  setUrl: (_value: string | null) => void;
  uploadedImageContainer?: string;
  allowsDefault?: boolean;
  onSelectClick?: () => void;
  handleCrop?: () => void;
  uploadImage?: boolean;
}

const PhotoContainer = ({
  imageLink,
  title = null,
  containerStyle,
  description = null,
  customPlaceholderStyle,
  type = null,
  setUrl = (_value: string | null) => null,
  uploadedImageContainer,
  allowsDefault = false,
  onSelectClick = () => null,
  handleCrop = () => null,
  uploadImage = false,
}: IProps) => {
  const [result, setResult] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [errorMessage, _setErrorMessage] = useState("");

  const handleURL = (url) => {
    setUrl(url);
    if (handleCrop) {
      handleCrop();
    }
    setImageLoader(false);
  };

  const uploadPhoto = async (_type, image) => {
    if (uploadImage) {
      setImageLoader(true);
      const uploadedImage = await upload(
        image,
        image.name,
        image.type,
        undefined
      );
      handleURL(uploadedImage?.url);
      setImageLoader(false);
    } else {
      handleURL(image);
      setImageLoader(false);
    }
  };

  const deletePhoto = () => {
    setUrl(uploadImage ? '' : null);
  };

  const [typeTemp, setTypeTemp] = useState(type);
  let component;
  if (imageLink) {
    component = (
      <PhotoPreview
        type={typeTemp}
        imageLink={imageLink}
        uploadPhoto={uploadPhoto}
        setType={setTypeTemp}
        result={result}
        errorMessage={errorMessage}
        setDisplayModal={deletePhoto}
        imageLoader={imageLoader}
        customContainerStyle={uploadedImageContainer}
      />
    );
  } else if (result === "fail") {
    component = (
      <FailContainer
        type={typeTemp}
        uploadPhoto={uploadPhoto}
        setType={setTypeTemp}
        setResult={setResult}
        errorMessage={errorMessage}
        imageLoader={imageLoader}
        customPlaceholderStyle={customPlaceholderStyle}
      />
    );
  } else {
    component = (
      <PhotoUploader
        allowsDefault={allowsDefault}
        onSelectClick={onSelectClick}
        type={typeTemp}
        uploadPhoto={uploadPhoto}
        imageLoader={imageLoader}
        customPlaceholderStyle={customPlaceholderStyle}
      />
    );
  }

  return (
    <div className={containerStyle} data-testid="loading-placeholder">
      {title && <h3>{title}</h3>}
      {component}
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default PhotoContainer;
