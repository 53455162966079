import { createPost, fetchPost } from "app/actions/postingForm";
import { filterLocationForPosting } from "app/components/helpers/filterLocationForPosting";
import PostModel, { PostingModel } from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import usePlanFeature from "app/utils/services/plan_feature/user";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  onPost?: (post: PostModel) => void;
}

export const FeedPostingFormModel = ({ onPost }: Props) => {
  const feedPostActions = useActions({ createPost, fetchPost });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );

  const { canSchedulePosts, canAddMedia, canPostMedia } = usePlanFeature(user);

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = (): boolean => {
    if (!user) {
      return null;
    }
    return canAddMedia && canPostMedia;
  };

  const { selectedDestinations } = useSelector(
    (state: RootState) => state.destinations,
    shallowEqual
  );

  // posting form submit handler
  const onSubmit = (data: PostingModel): void => {
    // check if location(s) are selected in redux to use id from it
    if (selectedDestinations) {
      const filteredDestinations =
        filterLocationForPosting(selectedDestinations);

      feedPostActions
        .createPost({ ...data, ...filteredDestinations })
        .then((res): void => {
          if (onPost) {
            onPost({ ...res, userAvatar: user.avatar });
          }
        });
    }
  };

  // const onCancel = (): void => {
  //   // setFilteredDestinations(defaultDestinations);
  // };

  return {
    canSchedulePosts,
    canAddMedia,
    canPostMedia,
    canUploadMedia,
    onSubmit,
    // onCancel,
  };
};
