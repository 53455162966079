import { useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface UsePaginationProps<QueryParams, DataType> {
  initialParams: QueryParams;
  fetchData: (params: QueryParams, signal: AbortSignal) => Promise<DataType[]>;
  clearDataAction: () => void;
  dependencies?: any[];
  fetchOnMount?: boolean;
}

export const usePagination = <
  QueryParams extends { page?: number; perPage?: number },
  DataType,
>({
  initialParams,
  fetchData,
  clearDataAction,
  dependencies = [],
  fetchOnMount = true,
}: UsePaginationProps<QueryParams, DataType>) => {
  const [query, setQuery] = useState<QueryParams>(initialParams);
  const [data, setData] = useState<DataType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pending, setPending] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const isFirstRender = useRef<boolean>(true);

  const fetchPage = async (queryVal: QueryParams) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    setPending(true);
    try {
      const res = await fetchData(queryVal, abortControllerRef.current.signal);
      if (!abortControllerRef.current.signal.aborted) {
        setData((prevData) => [...prevData, ...res]);
        setQuery(queryVal);
        if (res?.length < (queryVal?.perPage || 0)) {
          setHasMore(false);
        }
      }
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching data:", error);
      }
    } finally {
      setPending(false);
    }
  };

  const loadMore = () => {
    if (!pending && hasMore) {
      const newQuery = { ...query, page: (query?.page || 1) + 1 };
      fetchPage(newQuery);
    }
  };

  const loadInitialData = (newParams: QueryParams) => {
    setData([]);
    setHasMore(true);
    fetchPage({ ...initialParams, ...newParams, page: 1 });
  };

  const clearData = () => {
    setData([]);
    setQuery(initialParams);
    setHasMore(true);
    clearDataAction();
  };

  const [sentryRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (fetchOnMount) {
        fetchPage(initialParams);
      }
    } else {
      fetchPage(initialParams);
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      clearDataAction();
      clearData();
    };
  }, dependencies);

  const updateData = (updateFn: (currentData: DataType[]) => DataType[]) => {
    setData((prevData) => updateFn(prevData));
  };

  return {
    data,
    pending,
    hasMore,
    sentryRef,
    updateData,
    loadInitialData,
    clearData,
  };
};
