import React from "react";
import ViewProgram from "app/routes/ViewProgram";
import SiteWrapper from "app/components/Layouts/SiteWrapper";
import { useStoreActions } from "app/utils/hooks";
import { fetchSelf } from "app/actions/user";
import { Loading } from "app/components/Wrappers";

const PublicProgram = () => {
  const { user } = useStoreActions({ user: fetchSelf });

  if(user?.pending) {
    return <Loading loadType="spinner" />;
  }

  if(user?.data) {
    return <SiteWrapper><ViewProgram isPublic={true} /></SiteWrapper>;
  } else {
    return <ViewProgram isPublic={true} />;
  }
};

export default PublicProgram;
