import React, { memo, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import TeamGroupModel from "app/models/TeamGroupModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import CreateNewChannelModal from "app/components/CreateNewChannelModal";
import ListModel from "app/models/ListModel";
import { useActions } from "app/utils/hooks";
import { fetchJoinTeam, joinTeam } from "app/actions/team-group-member-import";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GroupsModel from "app/models/GroupsModel";

interface Props {
  channels: TeamGroupModel[];
  joinableList: ListModel[];
  selectedGroup: GroupsModel;
}

const ChannelsList = ({ channels, joinableList, selectedGroup }: Props) => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const channelListActions = useActions({ fetchJoinTeam, joinTeam });
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const url = window.location.pathname;
  const history = useHistory();
  const route = (path: string) => {
    history.push(path);
  };

  const parts = url.split("/");
  const teamId = parts[parts.indexOf("channels") + 1];

  const onChannelClick = (channelId?: string) => {
    if (channelId) {
      route(`/groups/${params?.id}/channels/${channelId}`);
    } else {
      route(`/groups/${params?.id}`);
    }
  };

  const CreateNewChannelHandler = () => {
    setIsModalOpen((prev) => !prev);
  };

  const canViewMembers =
    selectedGroup?.permissions?.includes("view_members") ||
    selectedGroup?.ownerId === self?.id;

  const canCreateTeamGroup =
    selectedGroup?.permissions?.includes("create_team_groups") &&
    selectedGroup?.planPermissions?.includes("create_team_groups");

  const renderCreateChannel = () => {
    return (
      <>
        {canCreateTeamGroup && (
          <button className={styles.add_icon} onClick={CreateNewChannelHandler}>
            <i className="ico ico-add"></i>
          </button>
        )}
      </>
    );
  };

  const onJoinClick = async (id: string) => {
    try {
      const res = await channelListActions.joinTeam(id);

      if (res) {
        route(`/groups/${params?.id}/channels/${res?.id}`);
      }
    } catch (error) {
      console.error("Error joining team:", error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.channels_title_wrapper}>
        <label htmlFor="channels_title">
          {t("Channels")} (
          {channels && channels.length ? channels.length.toString() : "0"})
        </label>
        {renderCreateChannel()}
      </div>
      <div
        className={cls(styles.channels_wrapper, {
          [styles.channels_border]: channels && channels.length > 0,
        })}
      >
        <div className={styles.mobile}>{renderCreateChannel()}</div>
        <div
          className={cls(styles.channel, {
            [styles.active]: !url.includes("channels"),
          })}
          onClick={() => onChannelClick()}
        >
          <div
            className={cls(styles.groups_icon, "team-group-icon ico ico-team")}
          />
          <span className={styles.channel_info}>
            <div className={styles.channel_title}>{t("General")}</div>
            {canViewMembers && (
              <p>{t("count_member", { count: selectedGroup?.membersCount })}</p>
            )}
          </span>
        </div>
        {channels?.length > 0 &&
          channels?.map((channel: TeamGroupModel) => {
            return (
              <div
                key={channel.id}
                className={cls(styles.channel, {
                  [styles.active]:
                    url.includes("channels") && teamId === channel.id,
                })}
                onClick={() => onChannelClick(channel.id)}
              >
                <div
                  className={cls(
                    styles.groups_icon,
                    "team-group-icon ico ico-team",
                  )}
                />
                <span className={styles.channel_info}>
                  <div className={styles.channel_title}>{channel.name}</div>
                  {canViewMembers && (
                    <p>
                      {t("count_member", {
                        count: channel?.membersCount,
                      })}
                    </p>
                  )}
                </span>
              </div>
            );
          })}
        {joinableList &&
          joinableList.map((joinable: TeamGroupModel) => {
            return (
              <div
                key={joinable.id}
                className={cls(styles.channel, styles.joinable)}
                onClick={() => onJoinClick(joinable.id)}
              >
                <div
                  className={cls(
                    styles.groups_icon,
                    "team-group-icon ico ico-team",
                  )}
                />
                <span className={styles.channel_info}>
                  <div className={styles.channel_title}>{joinable.name}</div>
                  <button className={styles.join_btn}>{t("Join")}</button>
                </span>
              </div>
            );
          })}
      </div>
      {isModalOpen && (
        <CreateNewChannelModal
          isOpen={isModalOpen}
          onClose={CreateNewChannelHandler}
        />
      )}
    </div>
  );
};

export default memo(ChannelsList);
