import React, { useMemo, useContext, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import FormItem from "app/components/inputs/FormItem";
import TimeRangeInput from "app/components/inputs/TimeRangeInput";
import DateSelector from "./DateSelector";
import SessionTypeSelector from "./SessionTypeSelector";
import RecurrenceSelector from "./RecurrenceSelector";
import SchedulingUserSelector from 'app/components/SchedulingUserSelector';
import { schedulingFetchGet } from 'app/utils/request/scheduling/fetch';

import { errorMessageFor } from "./helpers";

import styles from "./styles.module.scss";

import { LessonRateModel } from 'app/models/scheduling/LessonRateModel';
import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';
import { UserContext, RoleType } from 'app/contexts/UserContext';
import { StudentProfileModel } from 'app/models/scheduling/StudentProfileModel';
import { FacilityModel } from 'app/models/scheduling/FacilityModel';
import _, { uniqBy } from 'lodash';

type PropsDef = {
  lessonRateOptions: LessonRateModel[];
  onDateChange: (date: Date) => void;
  coachProfile: CoachProfileModel;
  facility?: FacilityModel;
  canAddNewAthletes?: boolean;
};

export default function SessionForm(props: PropsDef) {
  const { t } = useTranslation();
  const { lessonRateOptions, onDateChange, coachProfile, facility, canAddNewAthletes } = props;
  const [duration, setDuration] = useState(15);

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchTimeZone = watch('timeZone');
  const watchStartTime = watch('start');
  const watchLessonRateId = watch('lessonRateId');
  const watchCoaches: CoachProfileModel[] = watch('coachProfiles');
  const watchStudents = watch('studentProfiles');

  const luxonStartAt = useMemo(() => {
    return DateTime.fromISO(watchStartTime, { zone: watchTimeZone });
  }, [watchStartTime]);

  const onStartTimeChange = (changeEvent: any) => {
    const newStartAt = DateTime.fromISO(changeEvent.target.value, {
      zone: watchTimeZone,
    });
    const sessionType = lessonRateOptions.find(
      (lr) => lr.id === watchLessonRateId
    );
    const durationInMinutes = sessionType
      ? parseInt(sessionType.duration, 10)
      : duration;

    if (durationInMinutes > 0) {
      const newEndTime = newStartAt.plus({ minutes: durationInMinutes });
      setValue("end", newEndTime.toISO());
    }
  };

  const calculateDuration = (start, end) => {
    const startTime = DateTime.fromISO(start, {
      zone: watchTimeZone,
    });
    const endTime = DateTime.fromISO(end, {
      zone: watchTimeZone,
    });

    setDuration(endTime.diff(startTime, 'minutes').toObject().minutes);
  };

  const onEndTimeChange = (changeEvent: any) => {
    const newEndAt = DateTime.fromISO(changeEvent.target.value, {
      zone: watchTimeZone,
    });
    const sessionType = lessonRateOptions.find(
      (lr) => lr.id === watchLessonRateId
    );

    if (!sessionType || !luxonStartAt.isValid) {
      setValue("end", changeEvent.target.value);
      calculateDuration(watchStartTime, changeEvent.target.value);

      return;
    }

    const durationInMinutes = parseInt(sessionType.duration, 10) || 0;
    const expectedEndTime = luxonStartAt.plus({ minutes: durationInMinutes });

    if (!expectedEndTime.equals(newEndAt)) {
      setValue("lessonRateId", null);
    }

    setValue("end", changeEvent.target.value);
    calculateDuration(watchStartTime, changeEvent.target.value);
  };

  const onLessonRateChange = (changeEvent: any) => {
    const sessionType = changeEvent.target.value;
    setValue("lessonRateId", sessionType.id, { shouldValidate: true });
    setValue("rate", sessionType.rate);
    setValue("location", sessionType.location);

    const durationInMinutes = parseInt(sessionType.duration, 10);
    if (luxonStartAt.isValid) {
      const newEndTime = luxonStartAt.plus({ minutes: durationInMinutes });
      setValue("end", newEndTime.toISO());
    }
  };

  const onRateChange = (changeEvent: any) => {
    const newRate = changeEvent.target.value;
    setValue("rate", newRate);

    if (!watchLessonRateId) {
      return;
    }

    const sessionType = lessonRateOptions.find(
      (lr) => lr.id === watchLessonRateId
    );

    if (!sessionType) {
      return;
    }

    if (sessionType.rate !== newRate) {
      setValue("lessonRateId", null, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const calculateHours = () => {
    const dateList = [];

    _.range(0, 24*60, 15).forEach((value) => {
      const hour = Math.floor(value / 60);

      const minute = value % 60;
      const ampm = hour >= 12 ? "PM" : "AM";

      let label;

      if(hour === 0) {
        label = `12:${minute.toString().padStart(2, "0")} ${ampm}`;
      } else if(hour > 12) {
        label = `${hour-12}:${minute.toString().padStart(2, "0")} ${ampm}`;
      } else {
        label = `${hour}:${minute.toString().padStart(2, "0")} ${ampm}`;
      }

      dateList.push({
        value,
        label,
      });
    });

    return dateList;
  };

  const user = useContext(UserContext);
  const allowCustomSession = user.isAdmin || user.role === RoleType.ADMIN || (user.role === RoleType.COACH && watchCoaches && watchCoaches[0]?.id === user.id);
  const sessionType = lessonRateOptions.find(lr => lr.id === watchLessonRateId);
  const allowRecurring = user.isAdmin || (user.role === RoleType.COACH && watchCoaches && watchCoaches[0]?.id === user.id);
  const dateList = calculateHours();

  let minEventDate = null;
  let maxEventDate = null;

  if (!allowCustomSession && watchCoaches[0]?.earliestBookingDate && watchCoaches[0]?.latestBookingDate) {
    minEventDate = watchCoaches[0].earliestBookingDate.toJSDate();
    maxEventDate = watchCoaches[0].latestBookingDate.toJSDate();
  }


  const loadStudents = async (inputValue: string, _currentOptions: [], additional: { page: number }, newStudents: []) => {
    const exceptIds = watchStudents.value?.map((s: StudentProfileModel) => s.id) || [];
    const page = additional?.page || 1;

    try {
      const response = await schedulingFetchGet(`/facilities/${facility.id}/student_profiles`,
        { query: inputValue, per_page: 20, page, except: exceptIds }, { withHeaders: true }
      );

      const { students, headers } = response;
      const [ current_page, total_pages ] = [ Number(headers.get('Current-Page')), Number(headers.get('Total-Pages')) ];
      const next_page = current_page < total_pages ? current_page + 1 : null;

      const allStudents = uniqBy([...newStudents, ...students], "id");

      return {
        options: allStudents || [],
        hasMore: current_page < total_pages,
        additional: {
          page: next_page,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: null,
        },
      };
    }
  };

  return (
    <div className={`${styles.form_content} form_fields`}>
      <FormItem error={errorMessageFor(errors, 'studentProfiles')} className={styles.input_field}>
        <label>{t('Participating Athletes')}</label>
        <SchedulingUserSelector
          {...register("studentProfiles", { validate: ids => ids.length > 0 })}
          control={control}
          loadOptions={loadStudents}
          placeholder={t('Select Athletes')}
          multiSelect
          canAddNewAthletes={canAddNewAthletes}
        />
      </FormItem>

      <hr className={styles.separator} />

      <FormItem className={styles.input_field}>
        <label>{t("Session")}</label>
        <SessionTypeSelector
          key={watchCoaches[0]?.id}
          {...register('lessonRateId', { onChange: onLessonRateChange, required: !allowCustomSession })}
          control={control}
          options={lessonRateOptions}
          allowCustomSession={allowCustomSession}
        />
      </FormItem>

      <FormItem className={styles.input_field} error={errorMessageFor(errors, 'start')}>
        <label>{t("Date")}</label>
        <DateSelector
          {...register("date", { onChange: onDateChange, validate: _.isDate })}
          control={control}
          minDate={minEventDate}
          maxDate={maxEventDate}
        />
      </FormItem>

      <div className={styles.form_row}>
        <FormItem
          error={errorMessageFor(errors, "start")}
          className={styles.input_field}
        >
          <label>{t("Start Time")}</label>
          <TimeRangeInput
            {...register("start", { onChange: onStartTimeChange })}
            control={control}
            timeZone={watchTimeZone}
            dateList={dateList}
            customStartTime={null}
          />
        </FormItem>


        {allowCustomSession && (
          <FormItem error={errorMessageFor(errors, 'end')} className={styles.input_field}>
            <label>{t('End Time')}</label>
            <TimeRangeInput
              {...register('end', { onChange: onEndTimeChange })}
              control={control}
              timeZone={watchTimeZone}
              dateList={dateList}
              customStartTime={watchStartTime}
            />
          </FormItem>
        )}

        {!allowCustomSession && (
          <FormItem className={styles.input_field}>
            <label>{t('Duration')}</label>
            <input type="text" value={sessionType?.duration || ''} disabled />
          </FormItem>
        )}
      </div>

      {allowRecurring && <RecurrenceSelector />}

      <hr className={styles.separator} />

      <FormItem className={styles.input_field}>
        <label>{t("Rate")}</label>
        <div className={styles.currency_container}>
          <span>{coachProfile?.currencySymbol}</span>
          <input
            {...register("rate", { onChange: onRateChange })}
            placeholder="0"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t("Location")}</label>
        <input type="text" {...register("location")} />
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t("Notes")}</label>
        <textarea
          style={{ height: 100, resize: "none" }}
          {...register("notes")}
          placeholder={t("Add note here")}
        />
      </FormItem>
    </div>
  );
}
