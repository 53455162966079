import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { useSelector } from "app/utils/hooks";
import {
  clearAppliedTemplates,
  newSpaceValues,
} from "app/actions/spaceCreation";
// import { fetchSelf } from "app/actions/user";
import { RootState } from "typedefs";
import InvitationToMultipleSpaces from "./StepsComponents/InvitationToMultipleSpaces";
import SelectSpaceList from "../CreateNewSpace/StepsComponents/SelectSpaceList";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch } from "react-redux";
import { clearList } from "app/actions/list";

const CreateNewMultipleSpaces = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const { step, spaces_import } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual,
  );

  useEffect(() => {
    if (spaces_import.create_for_self) {
      dispatch(
        newSpaceValues({
          ...spaces_import,
          sport: self?.sport,
          sport_other: self?.sport,
          create_for_self: false,
        }),
      );
    }

    return () => {
      dispatch(clearAppliedTemplates());
      dispatch(clearList());
    };
  }, [dispatch]);

  const renderContent = () => {
    if (step === 2) {
      return <SelectSpaceList />;
    }

    return <InvitationToMultipleSpaces />;
  };

  return (
    <div className={styles.container}>
      <PagePanel
        title={t("Create Multiple Spaces")}
        titleStyles={styles.header_title}
      />
      <div className={styles.panel}>{renderContent()}</div>
    </div>
  );
};

export default CreateNewMultipleSpaces;
