import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import { isNull } from "lodash";
import OfferingOption from "../OfferingOption";
import SaveBar from "app/routes/Sessions/SaveBar";
import ClinicSvg from "../../../../../public/images/clinic-icon.svg";
import Dollar from "../../../../../public/images/dollar-circle-icon.svg";
import { useTranslation } from "react-i18next";
interface Option {
  title: string;
  label: string;
  subtitle: string;
  image: string;
}

export default function OfferingOptions() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Option | null>(null);
  const history = useHistory();

  const onSubmit = () => {
    if (selected?.title === "PACKAGE") {
     history.push('/payment/create');
    } else {
      history.push('/subscription/create');
    }
  };
  const handleSelection = (item) => {
    setSelected(item);
  };
  const offeringOptions: Option[] = [
    {
      title: "PACKAGE",
      label: "Payment",
      subtitle:
        "Create single session offerings or multi-session packages.",
       image: ClinicSvg,
    },
    {
      title: "SUBSCRIPTION",
      label: "Subscription",
      subtitle:
        "Create monthly or annual subscription offerings.",
       image: Dollar,
    },
  ];

  return (
    <div>
      <div className={`${styles.empty_container} billing-page-container`}>
        <div>
          <div className={styles.container}>
            <p className={styles.bold_text}>
              {t('Add New Package or Subscription')}
            </p>
            <div
              className={`${styles.program_options_line_container} ${styles.mt_20}`}
            >
              {offeringOptions.map((item, index) => {
                return (
                  <OfferingOption
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    DisplayImage={item.image}
                    selected={selected?.title === item.title}
                    onClick={() => handleSelection(item)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <SaveBar
        onSubmit={onSubmit}
        showCancel={true}
        onCancel={() => {
          history.push("/billing");
        }}
        submitDisabled={isNull(selected)}
      />
    </div>
  );
}
