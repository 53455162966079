import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import closeIcon from "../../../../public/images/close-icon.svg";
import { FormProvider, useForm } from "react-hook-form";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import FormInput from "app/components/Programs/FormInput";
import Label from "app/components/Programs/Label";
import { cls } from "app/utils";
import { schedulingFetchPatch } from "app/utils/request/scheduling/fetch";
import ToolTip from "app/routes/Scheduling/ToolTip";

const EditOfferingModal = ({
  product,
  isModalOpen,
  modalHandler,
  dataRefresher,
}) => {
  const { t } = useTranslation();
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      name: product.title,
      description: product.description || "",
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    const productData = {
      name: data.name,
      description: data.description ?  data.description : '',
      stripe_product_id: product.stripeProdId,
      coach_id: userId,
    };

    schedulingFetchPatch(`stripe_products/${product.stripeProdId}`, productData)
      .then(() => {
        modalHandler();
        dataRefresher();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating product:", error);
        setLoading(false);
      });
  };

  return (
    <ModalLayout
      isOpen={isModalOpen}
      onClose={modalHandler}
      classNames={`${styles.modal} event_form_containe`}
    >
      <FormProvider {...methods}>
        <form>
          <div className={styles.modal_header}>
            <div className={styles.titleRow}>
              <h2
                className={styles.truncatedText}
                title={product.title?.length > 20 ? product.title : ""}
              >
                {t("Edit")} "
                {product.title?.length > 20
                  ? `${product?.title?.substring(0, 20)}...`
                  : product.title}
                "
              </h2>
              {product.title?.length > 20 && (
                <div className={styles.toolTipAfterEllipsis}>
                  <ToolTip text={product.name} orientation="right" />
                </div>
              )}
            </div>
            <img
              src={closeIcon}
              alt="close"
              className={styles.close_icon}
              onClick={modalHandler}
            />
          </div>
          <div className={styles.modal_body}>
            <FormInput
              tagTitle="Name"
              fieldName="name"
              defaultValue={product.name}
            />
            <div className={styles.textarea_width}>
              <Label
                text="Description"
                item={
                  (
                  <textarea
                    data-testid="description"
                  {...methods.register('description')}
                    className={styles.details_textarea}
                  />
                  )
                }
                containerStyle={styles.mt_16}
                />
            </div>

            <div className={styles.info_wrapper}>
              <i className={cls("ico ico-info", styles.info_icon)} />
              <div>
                {t(
                  "To edit Price or other options you need to Clone and create a new Offering, then Deactivate the old offering"
                )}
              </div>
            </div>

            <div className={styles.button_wrapper}>
              <Button
                buttonType="secondary-outline"
                title={t("Cancel")}
                onClickFunc={modalHandler}
              />
              <Button
                buttonType="primary"
                title={loading ? t("Saving...") : t("Save")}
                onClickFunc={handleSubmit(onSubmit)}
                disabled={loading}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </ModalLayout>
  );
};

export default memo(EditOfferingModal);
