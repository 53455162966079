import React, { useState, useCallback, memo } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import SmartListModel from "app/models/SmartListModel";
import spacesIcon from "app/images/smart-list/spaces-icon.svg";
import inspirationIcon from "app/images/smart-list/inspiration-icon.svg";
import feedPostIcon from "app/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "app/images/smart-list/15-calendar-icon.svg";
import listIcon from "app/images/smart-list/list-icon.svg";
import SmartListsUpgradeModal from "app/components/Layouts/SmartListsUpgradeModal";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePagination } from "app/utils/hooks/usePagination";
import { fetchLists, clearList } from "app/actions/list";
import { useActions } from "app/utils/hooks";
import { Loading } from "app/components/Wrappers";
import useQueryParams from "app/utils/hooks/useQueryParams";
import DropdownButton from "app/components/DropdownButton";
import check_icon from "app/images/blue_check.svg";

interface IProps {
  selectedListValue: SmartListModel;
  onSmartListSelect: (value: SmartListModel) => void;
}

const PER_PAGE = 8;
const DEFAULT_LIST_VALUE = {
  Object: "athlete_space",
  name: "All Spaces",
  id: "1",
};

const SpaceSmartList = ({
  selectedListValue = DEFAULT_LIST_VALUE,
  onSmartListSelect,
}: IProps) => {
  const history = useHistory();
  const query = useQueryParams();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user.self, shallowEqual);
  const smartListActions = useActions({
    fetchLists,
    clearList,
  });

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);

  const handleUpgradeModal = () => {
    setIsUpgradeModalOpen((prev) => !prev);
  };

  const hasSmartListAccess = (): boolean => {
    return (
      (user?.plan?.planCode.startsWith("coachpro+") &&
        user?.plan?.planCode.startsWith("academy")) ||
      user.plan.status !== "trial_expired"
    );
  };

  const { data, pending, sentryRef }: any = usePagination({
    initialParams: {
      page: 1,
      perPage: PER_PAGE,
    },
    fetchData: (params, signal) => smartListActions.fetchLists(params, signal),
    clearDataAction: smartListActions.clearList,
    dependencies: [],
  });

  const handleDataClick = useCallback(
    (value: { name: string; id: string; object: string }) => {
      if (value.object === "smart_list" && !hasSmartListAccess()) {
        handleUpgradeModal();
      } else {
        onSmartListSelect(value);

        const newRoute = `/spaces?object=${value?.object}&name=${value.name
          .toLocaleLowerCase()
          .replace(/\s+/g, "_")}&id=${value.id ? value.id : "_"}`;

        history.push(newRoute);
      }
    },
    [user, handleUpgradeModal],
  );

  const renderIcon = useCallback(() => {
    switch (selectedListValue?.name) {
      case "Unanswered by me":
        return feedPostIcon;
      case "15 days inactive":
      case "15 days active":
        return fifteenDaysIcon;
      case "All spaces":
        return spacesIcon;
      default:
        return listIcon;
    }
  }, [selectedListValue]);

  const allSpacesData = [
    {
      name: t("All spaces"),
      object: "athlete_space",
      id: "1",
      icon: spacesIcon,
    },
  ];

  const demoSpaceMenu = {
    name: t("Inspiration"),
    object: "demo_space",
    id: "2",
    icon: inspirationIcon,
  };

  const smartListData: SmartListModel[] = [
    {
      id: "b58c0eb022b1013bf060784f4383ab9b",
      name: t("Unanswered by me"),
      object: "smart_list",
      icon: feedPostIcon,
    },
    {
      id: "b58caf3022b1013bf061784f4383ab9b",
      name: t("15 days inactive"),
      object: "smart_list",
      icon: fifteenDaysIcon,
    },
    {
      id: "db854d8026c3013bfa4d784f4383ab9b",
      name: t("15 days active"),
      object: "smart_list",
      icon: fifteenDaysIcon,
    },
  ];

  const mapData = useCallback(
    (data: SmartListModel[]) => {
      if (!data) {
        return null;
      }

      return data?.map((item: SmartListModel) => {
        return (
          <div
            key={item.id}
            className={cls(styles.menu_list)}
            onClick={() =>
              handleDataClick({
                name: item.name,
                id: item.id ? item.id : null,
                object: item.object ? item.object : "athlete_space",
              })
            }
          >
            <div className={styles.menu_list_title_wrapper}>
              <img
                src={item.icon ? item.icon : listIcon}
                alt="menuIcon"
                className={styles.icon}
              />
              <p className={styles.item_label}>{item.name}</p>
            </div>
            {selectedListValue?.id === item?.id && <img src={check_icon} />}
          </div>
        );
      });
    },
    [selectedListValue],
  );

  return (
    <div className={styles.container}>
      <div className={styles.menu_container}>
        <DropdownButton
          hideButtonStyles
          btnContent={
            <div
              className={cls(styles.selected_value, {
                // tslint:disable-next-line:jsx-wrap-multiline
                [styles.is_selected]:
                  query.queryParams.get("object") !== "demo_space",
              })}
            >
              <div className={cls(styles.selected_value_title)}>
                <img src={renderIcon()} alt="icon" />
                <p>{selectedListValue?.name ?? allSpacesData?.[0].name}</p>
              </div>
              <i className="ico ico-chevron" />
            </div>
          }
          btnClassName={styles.dropdown_btn}
          dropdownClassname={styles.dropdown_container}
          closeOnClick
          dropdownWrapperClass={cls(styles.dropdown_wrapper, {
            // [styles.active]:
            //   query.queryParams.get("id") === selectedListValue?.id &&
            //   query.queryParams.get("object") !== "demo_space",
          })}
        >
          {mapData(allSpacesData)}
          <p className={styles.list_title}>{t("SMART LISTS")}</p>
          {mapData(smartListData)}
          {data?.length > 0 && (
            <div>
              <p className={styles.list_title}>{t("LISTS")}</p>
              {mapData(data)}
            </div>
          )}
          {pending && <Loading isLoading={pending} loadType="spinner" />}
          <div ref={sentryRef} />
        </DropdownButton>
        <div
          className={cls(styles.inspiration_button, {
            [styles.inspiration_selected]:
              query.queryParams.get("object") === "demo_space",
          })}
        >
          <div
            className={cls(styles.selected_value)}
            onClick={() => handleDataClick(demoSpaceMenu)}
          >
            <div className={styles.list_item}>
              <img
                src={inspirationIcon}
                alt="menuIcon"
                className={styles.icon}
              />
              <p className={styles.label}>{t("Inspiration")}</p>
            </div>
          </div>
        </div>
      </div>
      <SmartListsUpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={handleUpgradeModal}
      />
    </div>
  );
};

export default memo(SpaceSmartList);
