import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { useApplyTemplateDropdown } from "./indexModel";
import ApplyTemplateSuccessModal from "app/components/Layouts/ApplyTemplateSuccessModal";
import ApplyTemplateToGroup from "../ApplyTemplateToGroup";
import ApplyTemplateToChannel from "../ApplyTemplateToChannel";
import SpaceSelectModal from "app/components/Layouts/SpaceSelectModal";
import GroupSelectModal from "app/components/Layouts/GroupSelectModal";
import ChannelSelectModal from "app/components/Layouts/ChannelSelectModal";
import { useTranslation } from "react-i18next";
import DropdownButton from "app/components/DropdownButton";
import ButtonVariant from "../ButtonVariant";

interface Props {
  templateId: string;
  templateName?: string;
  className?: string;
}

const ApplyTemplateDropdown = ({
  templateId,
  templateName,
  className,
}: Props) => {
  const { t } = useTranslation();
  const {
    successModal,
    onCloseSuccessModal,
    location,
    applied,
    dropdownRef,
    isSpaceSelectModalOpen,
    handleSpaceSelectModal,
    isGroupSelectModalOpen,
    handleGroupSelectModal,
    isChannelSelectModalOpen,
    handleChannelSelectModal,
  } = useApplyTemplateDropdown();

  return (
    <>
      <div className={cls(styles.container, className)} ref={dropdownRef}>
        <DropdownButton
          btnContent={
            <ButtonVariant buttonType="primary" title={t("Apply to") + "..."} />
          }
          closeOnClick
        >
          <div className={styles.dropdown_container}>
            <div
              className={styles.applyButton}
              onClick={handleSpaceSelectModal}
            >
              <i className="ico ico-tspace" />
              <p>{t("Apply to Space")}</p>
            </div>
            <ApplyTemplateToGroup
              className={styles.applyButton}
              groupSelectModalHanlder={handleGroupSelectModal}
            />
            <ApplyTemplateToChannel
              className={styles.applyButton}
              channelSelectModalHanlder={handleChannelSelectModal}
            />
          </div>
        </DropdownButton>
      </div>
      {isSpaceSelectModalOpen && (
        <SpaceSelectModal
          templateId={templateId}
          isOpen={isSpaceSelectModalOpen}
          onClose={handleSpaceSelectModal}
        />
      )}
      {isGroupSelectModalOpen && (
        <GroupSelectModal
          templateId={templateId}
          isOpen={isGroupSelectModalOpen}
          onClose={handleGroupSelectModal}
        />
      )}
      {isChannelSelectModalOpen && (
        <ChannelSelectModal
          templateId={templateId}
          isOpen={isChannelSelectModalOpen}
          onClose={handleChannelSelectModal}
        />
      )}
      <ApplyTemplateSuccessModal
        isSuccessModalOpen={successModal}
        onSuccessModalClose={onCloseSuccessModal}
        templateName={templateName}
        applied={applied}
        appliedLocation={location}
      />
    </>
  );
};

export default ApplyTemplateDropdown;
