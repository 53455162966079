import React from "react";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Deactivate from "../../../../public/images/product-deactivate.svg";

type PropsDef = {
  message: string;
  onClose: () => void;
  title?: string;
};

const ActiveSubscriptionsModal: React.FC<PropsDef> = ({
  message,
  onClose,
  title
}) => {
  const { t } = useTranslation();

  return (
    <ModalLayout isOpen onClose={onClose} classNames={styles.modal}>
      <div className={styles.confirmation_modal_content}>
        <div className={styles.close_button} onClick={onClose}>
          <i className="ico ico-x" />
        </div>

        <div className={styles.title}>
          <img src={Deactivate} alt="deactivate" />
          <h2>{title}</h2>
        </div>

        <div className={styles.content}>
          {message}
        </div>

        <div className={styles.actions}>
          <button className="btn btn-primary" onClick={onClose}>
            {t("Close")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ActiveSubscriptionsModal;
