import { useHistory, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import style from "./styles.module.scss";
import { clearPost, fetchPostById, reportPost } from "app/actions/posts";
import { editPost, deletePost } from "app/actions/postingForm";
import { RootState } from "typedefs";
import { PostModel } from "app/models";
import { Loading } from "app/components/Wrappers";
import PagePanel from "app/components/PagePanel";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { useActions } from "app/utils/hooks";
import { useTranslation } from "react-i18next";
import Post from "../../components/Post";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";

const DEFAULT_POST_QUERY: any = {
  "options[include]": ["replies", "spaces"],
};

const PostPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [updateError, setUpdateError] = useState<boolean>(false);
  const [post, setPost] = useState<PostModel>([]);
  const [postPending, setPostPending] = useState(true);
  const [isReply, setIsReply] = useState<string | null>(null);

  const postsActions = useActions({
    editPost,
    deletePost,
    reportPost,
  });

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const fetchPost = async (query = DEFAULT_POST_QUERY, postId?: string) => {
    try {
      const post: PostModel = dispatch(
        fetchPostById(postId ?? id, { ...query }),
      );
      return post;
    } catch (error) {
      console.error("Error fetching post:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchPostData = async () => {
      setPostPending(true);
      try {
        const post = await fetchPost();

        if (!post?.id) {
          return history.push("/404");
        }

        if (!post.rootId) {
          setPost(post);
        } else {
          try {
            const rootPost = await fetchPost(
              { ...DEFAULT_POST_QUERY, id: post.rootId },
              post.rootId,
            );

            if (rootPost) {
              setIsReply(post?.id);
              setPost(rootPost);
            }
          } catch (err) {
            console.error("Error fetching root post:", err);
            return history.push("/404");
          }
        }
      } catch (error) {
        console.error("Error fetching post data:", error);
        return history.push("/500");
      } finally {
        setPostPending(false);
      }
    };

    fetchPostData();

    return () => {
      dispatch(clearPost());
      setPost([]);
      setPostPending(false);
      setReportModalOpen(false);
    };
  }, [id]);

  const onPostEdit = useCallback(
    async (editedPost: PostModel) => {
      try {
        await postsActions.editPost({
          ...editedPost,
        });
      } catch (e) {
        setUpdateError(true);
      }
    },
    [post],
  );

  const onPostDelete = useCallback((posts: PostModel) => {
    postsActions.deletePost(posts?.id);
  }, []);

  const onPostReportClick = useCallback(() => {
    setReportModalOpen(true);
  }, []);

  const onPostReportClose = useCallback(() => {
    setReportModalOpen(false);
  }, []);

  const onReport = useCallback(
    async (message?: string) => {
      const data = await postsActions.reportPost(post?.id, message);
      if (data.id) {
        setReportModalOpen(false);
        history.goBack();
      }
    },
    [post],
  );

  if (!post?.id && postPending) {
    return <Loading loadType="spinner" isLoading={postPending} />;
  }

  function formatPostLocation(posts: PostModel) {
    if (posts?.teamGroup != null) {
      return {
        location: posts?.teamGroup?.team?.name,
        subLocation: posts?.teamGroup?.name,
        avatar: posts?.teamGroup?.team?.logo,
        goToUrl: `/groups/${posts?.teamGroup?.team?.id}/channels/${posts?.teamGroup?.id}`,
      };
    } else if (posts?.team != null) {
      return {
        location: posts?.team?.name,
        subLocation: undefined,
        avatar: posts?.team?.logo,
        goToUrl: `/groups/${posts?.team?.id}`,
      };
    } else if (posts?.space != null) {
      return {
        location: posts?.space?.athleteName,
        subLocation: posts?.space?.sport,
        avatar: posts?.space?.avatar,
        goToUrl: `/spaces/${posts?.space?.id}`,
      };
    }

    return {
      location: self?.displayName,
      subLocation: undefined as any,
      avatar: self?.avatar,
      goToUrl: "/",
    };
  }

  return (
    <div className={style.container}>
      <GettingStartedProgress />
      <PagePanel
        headerWrapperClassName={style.panel_container}
        title={formatPostLocation(post)?.location}
        subTitle={formatPostLocation(post)?.subLocation}
        avatar={formatPostLocation(post)?.avatar}
        onButtonClick={() => history?.push(formatPostLocation?.(post)?.goToUrl)}
        buttonTitle={t("Go To Timeline")}
        buttonType="outline-secondary"
        buttonStyles={style.go_to_button}
      />
      {post && (
        <Post
          key={post?.id}
          post={post}
          onRemove={onPostDelete}
          onReport={onPostReportClick}
          onEdit={onPostEdit}
          updateError={!!updateError}
          user={self}
          hidePinBadge
          canPinPost={false}
          isReply={isReply}
        />
      )}
      {isReportModalOpen && (
        <PostReportModal
          isModalOpen={isReportModalOpen}
          modalHandler={onPostReportClose}
          reportHandler={onReport}
        />
      )}
    </div>
  );
};
export default PostPage;
