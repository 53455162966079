import { cls } from "app/utils";
import React, { memo } from "react";
import NotificationsDropdown from "../NotificationsDropdown";
import SiteSearch from "../SiteSearch";
import styles from "./styles.module.scss";
import LeftArrow from "../../../public/images/back-icon.svg";

interface IProps {
  label: string;
  hasSearch?: boolean;
  hasNotifications?: boolean;
  children?: React.ReactNode;
  className?: string;
  onBackClick?: () => void;
}

const Navbar = ({
  label,
  hasSearch = true,
  hasNotifications = true,
  children,
  className,
  onBackClick,
}: IProps) => {
  return (
    <div className={cls(styles.home_navbar, className)}>
      <div className={styles.leftContainer}>
        {onBackClick && (
          <div className={styles.gray_button} onClick={onBackClick}>
            <img className={styles.back_icon} src={LeftArrow} alt="Back" />
          </div>
        )}
        <h2 className={styles.home_navbar_title}>{label}</h2>
      </div>
      <div className={styles.navbar_content}>
        {hasSearch && (
          <div>
            <SiteSearch className={styles.search} />
          </div>
        )}
        {hasNotifications && (
          <div>
            <NotificationsDropdown />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default memo(Navbar);
