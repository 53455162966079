import React from "react";
import { NotificationsModel } from "app/models/NotificationModel";
import { useTranslation } from "react-i18next";

const NotificationMessage = ({
  notification,
}: {
  notification: NotificationsModel;
}) => {
  const { t } = useTranslation();

  switch (
    notification.meta &&
    notification.meta.post &&
    notification.meta.post.type
  ) {
    case "image":
      return notification.meta.post.headline ? (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted an image")}:
          &quot;
          {notification.meta.post.headline}&quot;
        </span>
      ) : (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted an image")}.
        </span>
      );
    case "video":
      return notification.meta.post.headline ? (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted a video")}:
          &quot;
          {notification.meta.post.headline}&quot;
        </span>
      ) : (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted a video")}.
        </span>
      );
    case "audio":
      return notification.meta.post.headline ? (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted audio")}: &quot;
          {notification.meta.post.headline}&quot;
        </span>
      ) : (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted audio")}.
        </span>
      );
    case "datafile":
      return notification.meta.post.headline ? (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted a document")}:
          &quot;
          {notification.meta.post.headline}&quot;
        </span>
      ) : (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("posted a document")}.
        </span>
      );
    case "note":
      return notification.meta.post.headline ? (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("replied to a post")}:
          &quot;
          {notification.meta.post.headline}&quot;
        </span>
      ) : (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("replied to a post")}:
        </span>
      );
    default:
      return null;
  }
};

const LikeFormatted = ({ notification }: NotificationsModel) => {
  const { t } = useTranslation();

  switch (
    notification.meta &&
    notification.meta.post &&
    notification.meta.post.type
  ) {
    case "image":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("liked your image")}.
        </span>
      );
    case "video":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("liked your video")}.
        </span>
      );
    case "audio":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("liked your audio")}.
        </span>
      );
    case "datafile":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("liked your document")}.
        </span>
      );
    case "note":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b> {t("liked your post")}.
        </span>
      );
    default:
      return null;
  }
};

export const getNotificationLabel = (notification: NotificationsModel) => {
  const { t } = useTranslation();

  switch (notification.event) {
    case "post.created":
    case "reply.created":
      return <NotificationMessage notification={notification} />;
    case "like.created":
      return <LikeFormatted notification={notification} />;
    case "read_receipt.sent":
      return (
        <span>
          <b>{notification.message}</b>
        </span>
      );
    case "invitation.declined":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b>{" "}
          {t("rejected your invitation")}.
        </span>
      );
    case "invitation.accepted":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b>{" "}
          {t("accepted your invitation")}.
        </span>
      );
    case "team_invitation.accepted":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b>{" "}
          {t("joined the {{group}} Group.", {
            group: notification?.meta?.team?.teamName,
          })}
        </span>
      );
    case "team_group_membership.created":
      return (
        <span>
          <b>{notification.initiator.nameShort}</b>, {t("channel")}:{" "}
          {notification.meta.teamGroup.name}
        </span>
      );
    case "team.ownership_transferred":
      return notification.meta.teamGroup && notification.meta.teamGroup.name ? (
        <span>
          <b>{notification.initiator.nameShort}</b>{" "}
          {t("transferred ownership of")} {notification.meta.teamGroup.name}{" "}
          {t("to you")}.
        </span>
      ) : (
        <span>
          <b>{notification.message}</b>
        </span>
      );
    case "space.ownership_transferred":
    case "space_invitation.created_auto_accepted":
    case "team_invitation.created_auto_accepted":
      return (
        <span>
          <b>{notification.message}</b>
        </span>
      );
    default:
      return null;
  }
};

export default NotificationMessage;
