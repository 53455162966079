import React, { useRef, useState } from 'react';
 // @ts-ignore
import AsyncPaginate, { AsyncResult, wrapMenuList } from "react-select-async-paginate";
import { components } from 'react-select';
import { uniqBy } from 'lodash';
import { useController } from 'react-hook-form';

import Option from "./Option";
import SelectAllOption from "./SelectAllOption";
import SingleValue from "./SingleValue";
import MultiValue from "./MultiValue";
import SelectionList from "./SelectionList";

import { SelectStyles } from 'app/routes/Calendar/EventForm/constants';
import styles from "app/routes/Calendar/EventForm/styles.module.scss";
import { UserModel } from "app/models";
import { useTranslation } from "react-i18next";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import AddNewStudent from "./AddNewStudent";

type PropsDef = {
  name: string;
  control: any;
  placeholder: string;
  loadOptions: (inputValue: string, _currentOptions: any[], additional: { page: number }, newStudents: any[]) => Promise<AsyncResult<any, { page: number }>>;
  getOptionLabel?: (option: any) => string;
  multiSelect?: boolean;
  allowMultiSelectAll?: boolean;
  allOptionsLabel?: string;
  canAddNewAthletes?: boolean;
};

const onMultiSelect = (selectedUser: any, field: any) => {
  const filteredUsers = field.value.filter((s: { id: number; }) => s.id !== selectedUser.id);

  if (filteredUsers.length === field.value.length) { // adding user
    field.onChange(uniqBy(field.value.concat([selectedUser]), 'id'));
  } else {
    field.onChange(filteredUsers);
  }
};

const defaultOptionLabel = (opt: any) => {
  return `${opt.firstName} ${opt.lastName}`;
};

const SchedulingUserSelector = React.forwardRef((props: PropsDef, _ref) => {
  const {
    name, control, placeholder, loadOptions,
    getOptionLabel = defaultOptionLabel, allOptionsLabel,
    multiSelect = false,
    allowMultiSelectAll = false,
    canAddNewAthletes = false,
  } = props;

  const selectRef = useRef<any>(null);

  const { field } = useController({ name, control });
  const [inviteNewStudentBox, setInviteNewStudentBox] = useState(false);
  const [newStudents, setNewStudents] = useState([]);
  const { t } = useTranslation();

  const onRemove = (userId: number) => {
    const newUsers = field.value.filter(user => {
      return user.id !== userId;
    });

    if(field.value.some((user: any) => user.id === userId)) {
      setNewStudents(newStudents.filter((user: any) => user.id !== userId));
    }

    field.onChange(newUsers);
  };

  const hasUsers = field.value.length > 0;
  const showSelectionList = hasUsers && multiSelect;

  const MultiValueWithAllOptions = (props: any) => {
    const selection = props.getValue();

    if (props.options.length === selection.length && allowMultiSelectAll) {
      return <div>{allOptionsLabel}</div>;
    }

    return <MultiValue {...props} />;
  };

  const ValueComponent = multiSelect ? MultiValueWithAllOptions : SingleValue;

  const showSelectAllOption = multiSelect && allowMultiSelectAll;

  const MenuListWithAllOption = (props) => {
    return (
      <>
        <SelectAllOption
          label={allOptionsLabel}
          selectOption={() => {
            field.onChange(props.options);
            selectRef.current.onMenuClose();
          }}
        />
        {props.children}
      </>
    );
  };

  const MenuList = wrapMenuList(showSelectAllOption ? MenuListWithAllOption : components.MenuList);

  const saveNewOption = (user: UserModel) => {
    const newStudent = {
      id: user.externalId,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.displayName,
      externalId: user.externalId,
      email: user.email,
      templateId: user.templateId,
      newSpace: user.newSpace,
      profilePicture: "https://shorturl.at/u2VPP",
      newStudent: true,
    };

    setNewStudents((prevStudents) => uniqBy([...prevStudents, newStudent], 'id'));

    field.onChange(multiSelect ? [...field.value, newStudent] : [newStudent]);
    setInviteNewStudentBox(false);
  };

  return (
    <div>
      <AsyncPaginate
        key={newStudents.length}
        ref={selectRef}
        classNamePrefix="infinity-select"
        styles={SelectStyles}
        defaultOptions
        getOptionLabel={getOptionLabel}
        getOptionValue={opt => opt.id}
        loadingMessage={() => null}
        components={{
          MenuList,
          Option,
          SingleValue: ValueComponent,
          LoadingIndicator: () => null
        }}
        loadOptions={
          (inputValue, _currentOptions, additional) =>
            loadOptions(inputValue, _currentOptions, additional, newStudents)
        }
        onChange={value =>  {
          if (multiSelect) {
            onMultiSelect(value, field);
          } else {
            field.onChange([value]);
          }
        }}
        placeholder={placeholder}
        value={field.value}
        isSearchable={multiSelect}
        debounceTimeout={300}
        shouldLoadMore={() => {
          // Load more when 75% scrolled
          const menu = document.querySelector('.infinity-select__menu-list');
          if (!menu) {
            return false;
          }
          return menu.scrollTop > (menu.scrollHeight * 0.75 - menu.clientHeight);
        }}
      />

      {multiSelect && canAddNewAthletes && (
        <div className={styles.invite_new_students_button} onClick={() => setInviteNewStudentBox(!inviteNewStudentBox)}>
          {t("+ Invite New Athlete")}
        </div>
      )}

      <ModalLayout isOpen={inviteNewStudentBox} classNames={`${styles.modal} ${styles.new_student_modal} invite_new_student_modal`}>
        <AddNewStudent
          getLabel={() => t("Add New Athlete")}
          onDone={saveNewOption}
          onCancel={() => setInviteNewStudentBox(false)}
        />
      </ModalLayout>

      {showSelectionList && <SelectionList selection={field.value} onRemove={onRemove} />}
    </div>
  );
});

export default SchedulingUserSelector;
