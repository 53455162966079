import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import ToolTip from "app/routes/Scheduling/ToolTip";

interface RemainingSessionsProps {
  remaining: number;
  updateRemainingSessions: (purchaseId, incrementValue: number) => void;
  purchaseId: string;
}

export default function RemainingSessions({
  remaining,
  updateRemainingSessions,
  purchaseId,
}: RemainingSessionsProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.remainingSessions}>
      <div className={styles.textContainer}>
        <p className={styles.sectionTitleSessions}>{t("Remaining Sessions")}</p>
        <ToolTip
          text={t(
            "Here you can set the amount of remaining sessions for this athlete."
          )}
          selected={true}
          fontSize={"16px"}
          orientation={"right"}
          iconColor={"gray"}
        />
      </div>
      {remaining === -1 ? (
        <div className={styles.infiniteSessions}>
          <span className={styles.infinitySymbol}>&infin;</span>
        </div>
      ) : (
        <div className={styles.remainingInputWrapper}>
          <button
            className={styles.decrementButton}
            onClick={() => {
              if (remaining > 0) {
                updateRemainingSessions(purchaseId, -1);
              }
            }}
            disabled={remaining === 0}
          >
            <i className="fas fa-minus"></i>
          </button>
          <input
            type="number"
            value={remaining}
            className={styles.remainingInput}
            readOnly
          />
          <button
            className={styles.incrementButton}
            onClick={() => updateRemainingSessions(purchaseId, 1)}
          >
            <i className="fas fa-plus"></i>
          </button>
        </div>
      )}
    </div>
  );
}
