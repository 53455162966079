import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PaymentsTable from "../../Billing/Customers/CustomerDetails/PaymentTable";
import SubscriptionCardWithCoach from "../../Billing/Customers/CustomerDetails/SubscriptionCardWithCoach";
import AccountWrap from "..";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";

interface Subscription {
  fullName?: string;
  profilePicture: string;
  offeringTitle: string;
  billingFrequency: number | null;
  nextInvoiceDate: string | null;
  productPrice: number;
  currency: string;
  remainingSessions: number | null;
  unlimited?: boolean;
  productType?: string;
  productId?: string;
  coachId?: string;
  studentProfileId?: string;
}

const ManagePayments: React.FC = () => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(true);
  const [errorPayments, setErrorPayments] = useState<string | null>(null);
  const [errorSubscriptions, setErrorSubscriptions] = useState<string | null>(
    null
  );
  const userId = useSelector((state: RootState) => state.user.self.id);

  useEffect(() => {
    setIsLoadingPayments(true);
    setErrorPayments(null);

    schedulingFetchGet(`/students/${userId}/all_purchases`)
      .then((response) => {
        if (!response) {
          throw new Error("Unexpected response format");
        }
        setPayments(response);
      })
      .catch((err) => {
        console.error("Failed to fetch payments:", err);
        setErrorPayments(err.message || t("Failed to fetch payments"));
      })
      .finally(() => {
        setIsLoadingPayments(false);
      });

    setIsLoadingSubscriptions(true);
    setErrorSubscriptions(null);

    schedulingFetchGet(`/students/${userId}/all_offerings`)
      .then((response) => {
        if (!response) {
          throw new Error("Unexpected response format");
        }
        setSubscriptions(response);
      })
      .catch((err) => {
        console.error("Failed to fetch subscriptions:", err);
        setErrorSubscriptions(
          err.message || t("Failed to fetch subscriptions")
        );
      })
      .finally(() => {
        setIsLoadingSubscriptions(false);
      });
  }, []);

  const formatInvoiceDate = (isoDate: string | null) => {
    if (!isoDate) {
      return t("N/A");
    }
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(date);
  };

  const handleDelete = (productId: string) => {
    setSubscriptions((prevSubscriptions) =>
      prevSubscriptions.map((subscription) =>
        subscription.productId === productId
          ? { ...subscription, nextInvoiceDate: null }
          : subscription
      )
    );
  };

  return (
    <AccountWrap>
      <div className={styles.container}>
        <h2 className={styles.title}>{t("Manage Payments")}</h2>

        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>
            {t("Purchases & Subscriptions")}
          </h3>
          {isLoadingSubscriptions ? (
            <p>{t("Loading subscriptions...")}</p>
          ) : errorSubscriptions ? (
            <p className={styles.errorText}>{errorSubscriptions}</p>
          ) : subscriptions.length > 0 ? (
            <div className={styles.subscriptionMargin}>
              {subscriptions.map((subscription, index) => (
                <SubscriptionCardWithCoach
                  key={index}
                  subscription={subscription}
                  coachName={subscription?.fullName || ""}
                  coachImage={subscription.profilePicture}
                  formatInvoiceDate={formatInvoiceDate}
                  handleDelete={handleDelete}
                />
              ))}
            </div>
          ) : (
            <p>{t("No subscriptions found.")}</p>
          )}
        </div>

        {/* Payments Section */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>{t("Payments")}</h3>
          {isLoadingPayments ? (
            <p>{t("Loading payments...")}</p>
          ) : errorPayments ? (
            <p className={styles.errorText}>{errorPayments}</p>
          ) : (
            <PaymentsTable payments={payments} />
          )}
        </div>
      </div>
    </AccountWrap>
  );
};

export default ManagePayments;
