import { useCallback, useEffect, useRef, useState } from "react";
import { RootState } from "typedefs";
import { shallowEqual, useSelector } from "react-redux";
import { fetchSpaceMemberships } from "app/actions/space";
import { useActions } from "app/utils/hooks";
import { DemoSpaceModel, fetchDemoSpaces } from "app/actions/DemoSpace";
import useDebounce from "app/utils/hooks/useDebounce";
import SpaceModel from "app/models/SpaceModel";
import { useHistory } from "react-router-dom";
import useQuery from "app/utils/hooks/useQuery";
import { usePagination } from "app/utils/hooks/usePagination";
import SmartListModel from "app/models/SmartListModel";

type queryType = {
  page: number;
  forcePerPage: number;
  perPage: number;
  options: {
    include: string[];
    exclude: string[];
  };
  athlete_name?: string;
  segment_id?: string;
  smart_list_id?: string;
};

const DEFAULT_LIST_VALUE = {
  Object: "athlete_space",
  name: "All Spaces",
  id: "1",
};
const FORCE_PER_PAGE = 100;
const PER_PAGE = FORCE_PER_PAGE;
const INIT_PAGE = 1;

const useSpacesModel = () => {
  const abortController = useRef<AbortController | null>(null);

  const spaceActions = useActions({
    fetchDemoSpaces,
    fetchSpaceMemberships,
  });

  const { self } = useSelector((state: RootState) => state.user);
  const { pending: demoPending } = useSelector(
    (state: RootState) => state.demoSpace,
  );

  const searchParams = useQuery();

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const [showUpgrade, setUpgradeState] = useState(false);
  const [selectedListValue, setSelectedListValue] =
    useState<SmartListModel>(DEFAULT_LIST_VALUE);
  const [searchTerm, setSearch] = useState<string>("");
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const { isModalOpen } = useSelector(
    (state: RootState) => state.space,
    shallowEqual,
  );

  const newSingle = () => {
    if (!self?.planPermissions?.includes("create_spaces")) {
      return upgradeHandler();
    }

    return history.push("/spaces/new");
  };

  const transformQueryName = useCallback((name: string) => {
    const queryName = searchParams.get("name");
    return (
      name.replace(/_/g, " ").charAt(0).toUpperCase() +
      (queryName ?? "").replace(/_/g, " ").slice(1)
    );
  }, []);

  const debouncedSearch = useDebounce(searchTerm, 500);

  const INIT_QUERY: queryType = {
    page: INIT_PAGE,
    forcePerPage: PER_PAGE,
    perPage: PER_PAGE,
    options: {
      include: ["team_info"],
      exclude: ["permissions", "state"],
    },
    athlete_name: debouncedSearch,
  };

  const [spaceQuery, setSpaceQuery] = useState<queryType>(INIT_QUERY);

  // const openUpgrade = () => {
  //   setUpgradeState(true);
  // };

  const closeUpgrade = () => {
    setUpgradeState(false);
  };

  const {
    data,
    pending,
    sentryRef,
    updateData,
    loadInitialData,
    clearData,
  }: any = usePagination({
    initialParams: { ...spaceQuery, athlete_name: debouncedSearch },
    fetchData: (params, signal) =>
      spaceActions.fetchSpaceMemberships(params, signal),
    clearDataAction: () => {},
    dependencies: [spaceQuery, debouncedSearch],
    fetchOnMount: false,
  });

  const isDemo = searchParams.get("object") === "demo_space";

  useEffect(() => {
    if (self?.kind !== "coach") {
      history.push("/home");
    }

    const queryObject = searchParams.get("object");
    const queryName = searchParams.get("name");
    const queryId = searchParams.get("id");

    if (queryObject || queryName) {
      if (queryObject !== "demo_space") {
        const newObject = {
          name: transformQueryName(queryName),
          object: queryObject,
          id: queryId,
        };
        setSelectedListValue(newObject);
        smartListSelectHandler(newObject);
      } else {
        setSelectedListValue(null);
        fetchDemoSpace();
      }
    } else {
      // if there is no query detected in URL need to make data fetch
      loadInitialData(INIT_QUERY);
    }
  }, []);

  const fetchDemoSpace = async () => {
    clearData();
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    try {
      const res: any = await fetchDemoSpaces(
        null,
        null,
        abortController.current.signal,
      );

      if (res && res._hash) {
        const demoSpaces = Object.values(res._hash).reduce(
          (acc: DemoSpaceModel[], item: DemoSpaceModel) => {
            if (item.space) {
              acc.push(item.space);
            }
            return acc;
          },
          [],
        );

        updateData(() => {
          return [...(demoSpaces as SpaceModel[])];
        });
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was aborted.");
      } else {
        console.error(error);
      }
    }
  };

  const spaceClicked = (value: string) => {
    if (value.includes("demo")) {
      route(value);
    } else {
      route(`/spaces/${value}`);
    }
  };

  const updateSearch = useCallback(
    (value: string) => {
      if (value != null && value.length > 0) {
        setSearch(value);
      } else {
        setSearch("");
      }
    },
    [setSearch],
  );

  const smartListSelectHandler = useCallback(
    (value: SmartListModel) => {
      if (value?.object !== "demo_space") {
        setSelectedListValue(value);

        if (debouncedSearch?.length >= 3 || debouncedSearch?.length === 0) {
          if (value?.object === "smart_list") {
            return setSpaceQuery({ ...INIT_QUERY, smart_list_id: value?.id });
          } else if (value?.object === "segment") {
            return setSpaceQuery({ ...INIT_QUERY, segment_id: value?.id });
          }
        }
      }

      if (value?.object === "athlete_space") {
        setSelectedListValue(DEFAULT_LIST_VALUE);
        setSpaceQuery(INIT_QUERY);
        return;
      }

      if (value?.object === "demo_space") {
        setSelectedListValue(null);
        fetchDemoSpace();
        clearData();
        return;
      }
    },
    [setSelectedListValue, clearData],
  );

  const upgradeHandler = () => {
    setUpgradeState((prev) => !prev);
  };

  return {
    spacesData: data,
    pending,
    sentryRef,
    user,
    debouncedSearch,
    isModalOpen,
    showUpgrade,
    closeUpgrade,
    spaceClicked,
    updateSearch,
    selectedListValue,
    smartListSelectHandler,
    upgradeHandler,
    newSingle,
    isDemo,
    demoPending,
  };
};

export default useSpacesModel;
