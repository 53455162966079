import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useActions } from "app/utils/hooks";
import { fetchNotifications } from "app/actions/Notification";
import { NotificationModel } from "app/models/NotificationModel";
import SingleNotification from "../NotificationsList/SingleNotification";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NotificationsModel } from "app/models";

const RecentActivities = () => {
  const history = useHistory();
  const notificationActions = useActions({ fetchNotifications });
  const [data, setData] = useState<NotificationModel[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    notificationActions
      .fetchNotifications({ page: 1, per_page: 4 })
      .then((res: { notifications: NotificationModel[] }) => {
        if (res?.notifications?.length > 0) {
          console.log(res);
          setData(res?.notifications);
        }
      });
  }, []);

  if (data.length === 0) {
    return (
      <div className={styles.empty_container}>
        <p className={styles.empty_text}>{t("No Notifications")}</p>
      </div>
    );
  }

  const route = (path: string) => {
    history.push(path);
  };

  const onNotificationClick = (notification: NotificationsModel) => {
    if (notification?.targetUrl) {
      route(notification.targetUrl);
    }
  };

  return (
    <div className={styles.container}>
      {data.map((activity: NotificationModel) => {
        return (
          <SingleNotification
            key={activity.id}
            notification={activity}
            className={styles.notification}
            onNotificationClick={onNotificationClick}
          />
        );
      })}
    </div>
  );
};

export default RecentActivities;
