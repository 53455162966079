import React from "react";
import styles from "./styles.module.scss";
import { GroupSelectProps } from "./index.types";
import no_team_png from "app/images/null-training-space.png";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import useTeamSelectModal from "./indexModel";
import TeamGroupModel from "app/models/TeamGroupModel";
import ApplyTemplateListsModal from "../ApplyTemplateListsModal";
import { useTranslation } from "react-i18next";

const GroupSelectModal = ({
  onClose,
  isOpen,
  templateId,
}: GroupSelectProps) => {
  const { t } = useTranslation();
  const {
    teamSelectHandler,
    cancelHandler,
    applyHandler,
    onSearchTeam,
    team,
    searchTerm,
    results,
    selectedTeamsId,
  } = useTeamSelectModal({ onClose, templateId });

  if (!isOpen) {
    return null;
  }

  return (
    <ApplyTemplateListsModal
      pending={team.pending || team.data == null}
      applyHandler={applyHandler}
      cancelHandler={cancelHandler}
      hasNoResult={
        searchTerm?.length === 0 && (!team.data || team.data.length === 0)
      }
      noResultImage={no_team_png}
      noResultTitle={t("No Groups")}
      noResultSubText={t("You don't have created a group yet")}
      isOpen={isOpen}
      onClose={onClose}
      searchPlaceholder={t("Search Group...")}
      onSearchChange={onSearchTeam}
      modalSubTitle={t(
        "You can apply this Template to the Group and posts will be added to the General Channel in the set interval and order",
      )}
    >
      <div className={styles.team_list_container}>
        {results &&
          results.map((team: TeamGroupModel) => {
            return (
              <div
                key={team.id}
                className={cls(styles.team_container, {
                  [styles.disabled]: !team.planPermissions.includes("post"),
                })}
                onClick={() =>
                  team.planPermissions.includes("post")
                    ? teamSelectHandler(team.id as string, team)
                    : null
                }
              >
                <div className={styles.avatar}>
                  <UserAvatar
                    sizeWidth={44}
                    sizeHeight={44}
                    className="avatar"
                    userName={team.ownerName}
                    srcName={team.avatar as string}
                    altName={team.name}
                  />
                </div>
                <div className={styles.team}>
                  <div className={cls(styles.team_title, styles.elipsis)}>
                    {team.name}
                  </div>
                  <div className={cls(styles.team_subtitle, styles.elipsis)}>
                    {team.sport}
                  </div>
                </div>
                <span className={styles.checkbox}>
                  {selectedTeamsId.includes(team.id) ? (
                    <i className={cls("fas fa-check-circle", styles.checked)} />
                  ) : (
                    <i className={cls("far fa-circle", styles.unchecked)} />
                  )}
                </span>
              </div>
            );
          })}
      </div>
    </ApplyTemplateListsModal>
  );
};

export default GroupSelectModal;
