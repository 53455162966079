import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import PaymentsTable from "./PaymentTable";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import {
  schedulingFetchGet,
  schedulingFetchPatch,
} from "app/utils/request/scheduling/fetch";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import SubscriptionCard from "./SubscriptionCard";
import OfferingCard from "./OfferingCard";
import { Loading } from "app/components/Wrappers";
import Navbar from "app/components/Navbar";
interface RouteParams {
  id: string;
}

interface Offering {
  offeringTitle: string;
  billingFrequency: number | null;
  nextInvoiceDate: string | null;
  price: number;
  remainingSessions: number;
  productPrice: number;
  currency: string;
  purchaseId: string;
  customerId: string;
  fullName?: string;
  profilePicture: string;
  unlimited?: boolean;
  productType?: string;
  productId?: string;
  coachId?: string;
  studentProfileId?: string;
}

export default function CustomerDetails() {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [purchases, setPurchases] = useState([]);
  const [subscriptions, setSubscriptions] = useState<Offering[]>([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const { firstName, lastName, email, profilePicture, currency } =
    location?.state || {};

  useEffect(() => {
    Promise.all([
      schedulingFetchGet(
        `/students/${id}/subscriptions?coach_id=${userId}&currency=${currency}`
      ).then((data) => {
        if (!data || data?.length === 0) {
          throw new Error("No active subscriptions found");
        }
        setSubscriptions(data);
      }),
      schedulingFetchGet(
        `/students/${id}/purchases?coach_id=${userId}&currency=${currency}`
      ).then((data) => {
        if (!data) {
          throw new Error("Unexpected response format");
        }
        setPurchases(data);
      }),
    ])
      .catch((error) => {
        console.error("Failed to fetch data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, userId]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading isLoading={loading} loadType="spinner" />
      </div>
    );
  }

  const formatInvoiceDate = (isoDate) => {
    if (!isoDate) {
      return t("N/A");
    }

    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(date);
  };

  const updateRemainingSessions = (
    purchaseId: string,
    incrementValue: number
  ) => {
    schedulingFetchPatch(`/stripe_purchases/edit_remaining_sessions`, {
      purchase_id: purchaseId,
      increment_value: incrementValue,
    })
      .then((data) => {
        if (!data || data.error) {
          throw new Error(data.error || t("Error updating remaining sessions"));
        }
        setSubscriptions((prevSubscriptions) =>
          prevSubscriptions.map((sub) =>
            sub.purchaseId === purchaseId
              ? {
                ...sub,
                remainingSessions: sub.remainingSessions + incrementValue,
              }
              : sub
          )
        );
      })
      .catch((error) => {
        console.error("Failed to update remaining sessions:", error);
      });
  };

  const handleDelete = (productId: string) => {
    setSubscriptions((prevSubscriptions) =>
      prevSubscriptions.map((sub) =>
        sub.productId === productId ? { ...sub, nextInvoiceDate: null } : sub
      )
    );
  };

  const navigateBack = () => {
    history.push("/billing?tab=customers");
  };

  return (
    <div className={`billing-page-container`}>
      <Navbar label={t("Customer")} hasSearch={false} hasNotifications={false} onBackClick={navigateBack}/>
      <div className={styles.customerDetailsContainer}>
        <div className={styles.customerHeader}>
          <div className={styles.customerInfo}>
            <UserAvatar
              srcName={profilePicture}
              userName={`${firstName} ${lastName}`}
              altName={t("avatar")}
              sizeHeight={10}
              sizeWidth={10}
              className={cls(styles.avatar)}
            />
            <div>
              <h1 className={styles.customerName}>
                {firstName} {lastName}
              </h1>
              <p className={styles.customerEmail}>{email}</p>
            </div>
          </div>
          <div className={styles.actionButtons}>
            <button
              className={cls("btn", styles.actionButton)}
              onClick={() => {
                window.open(
                  `https://dashboard.stripe.com/customers/${subscriptions?.[0]?.customerId}`,
                  "_blank"
                );
              }}
            >
              {t("View in Stripe")}
            </button>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <h2 className={styles.sectionTitle}>
              {t("Subscriptions & Offerings")} ({subscriptions.length})
            </h2>
          </div>
          {subscriptions.length > 0 ? (
            subscriptions
              .filter(
                (sub) =>
                  sub.productType === "subscription" ||
                  sub.remainingSessions !== 0
              )
              .map((sub, index) => (
                <div key={index} className={styles.marginForSubscriptionCard}>
                  {sub.productType === "subscription" ? (
                    <SubscriptionCard
                      subscription={sub}
                      formatInvoiceDate={formatInvoiceDate}
                      handleDelete={handleDelete}
                      remaining={sub.remainingSessions}
                      updateRemainingSessions={updateRemainingSessions}
                      purchaseId={sub.purchaseId}
                      isCoach={true}
                    />
                  ) : (
                    <OfferingCard
                      subscription={sub}
                      remaining={sub.remainingSessions}
                      updateRemainingSessions={updateRemainingSessions}
                      purchaseId={sub.purchaseId}
                      isCoach={true}
                    />
                  )}
                </div>
              ))
          ) : (
            <div className={styles.customers_placeholder}>
              <p className={styles.no_customers_text}>
                {t(
                  "This athlete does not have any active subscriptions or offerings."
                )}
              </p>
            </div>
          )}
        </div>
        <div className={styles.divider} />
        <div className={styles.section}>
          <h2 className={styles.sectionTitleWithMargin}>
            {t("Payments")} ({purchases?.length})
          </h2>
          <PaymentsTable payments={purchases} />
        </div>
      </div>
    </div>
  );
}
