import UserModel from "app/models/UserModel";
import { useActions, useStoreActions } from "app/utils/hooks";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { fetchConnectedCoaches } from "app/actions/connection";
import { fetchSpaceMemberships } from "app/actions/space";
import { fetchGroups } from "app/actions/groups";

export const useAthleteHomeModel = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const spaceActions = useActions({
    fetchSpaceMemberships,
    fetchGroups,
  });

  const [showAllCoaches, setShowAllCoaches] = useState<boolean>(false);
  const [countSpaces, setCountSpaces] = useState(0);
  const [combinedData, setCombinedData] = useState([]);
  const [loadingSpaces, setLoadingSpaces] = useState(false);
  const [showAllSpaces, setShowAllSpaces] = useState(false);

  const { connection } = useStoreActions({ connection: fetchConnectedCoaches });

  const allCoaches = useMemo((): UserModel[] => {
    return connection?.data || [];
  }, [connection]);

  const initialCoaches = allCoaches?.slice(0, 4) ?? [];

  const filteredCoaches = useMemo(() => {
    return showAllCoaches ? allCoaches : initialCoaches;
  }, [allCoaches, showAllCoaches, initialCoaches]);

  const onViewSpaces = (): void => {
    setShowAllSpaces(true);
  };

  const onColapseSpaces = (): void => {
    setShowAllSpaces(false);
  };

  const onColapseCoaches = (): void => {
    setShowAllCoaches(false);
  };

  const onViewNotifications = (): void => {
    history.push("/notifications");
  };

  const onViewAllCoaches = (): void => {
    setShowAllCoaches(true);
  };

  const combineData = (dataOne = [], dataTwo = []) => [
    ...(Array.isArray(dataOne) ? dataOne : []),
    ...(Array.isArray(dataTwo) ? dataTwo : []),
  ];

  const fetchAllSpacesAndGroups = async (spaceParams) => {
    setLoadingSpaces(true);

    try {
      const [spacesRes = [], groupsRes = []] = await Promise.all([
        spaceActions.fetchSpaceMemberships(spaceParams), // No default params applied
        spaceActions.fetchGroups(),
      ]);

      const combined = combineData(spacesRes, groupsRes);
      setCombinedData(combined);
      setCountSpaces(combined.length);
    } catch (err) {
      console.error("Error fetching spaces or groups:", err);
      setCombinedData([]);
      setCountSpaces(0);
    } finally {
      setLoadingSpaces(false);
    }
  };
  useEffect(() => {
    fetchAllSpacesAndGroups({});
  }, []);

  return {
    t,
    countSpaces,
    setCountSpaces,
    filteredCoaches,
    onViewSpaces,
    onColapseSpaces,
    onViewNotifications,
    onViewAllCoaches,
    onColapseCoaches,
    allCoaches,
    showAllCoaches,
    loadingSpaces,
    combinedData,
    showAllSpaces,
  };
};
