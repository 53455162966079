import React from "react";
import styles from "./styles.module.scss";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { PostMedia } from "app/components/paywalls";
import { useResourcesModel } from "./indexModel";
import ResourcesContainer from "./ResourcesContainer";
import Navbar from "app/components/Navbar";
import ButtonVariant from "app/components/Button/ButtonVariant";

const Resources = () => {
  const {
    user,
    t,
    showPaywallModal,
    closePaywall,
    onSelectMedia,
    onCreateMedia,
  } = useResourcesModel();

  return (
    <>
      <Navbar label={t("Library")} hasSearch={false} hasNotifications={false}>
        <ButtonVariant
          title={t("Upload Media")}
          icon={<i className="ico ico-add" />}
          buttonType="secondary-filled"
          childStyles={styles.create_btn_inner}
          buttonStyles={styles.create_btn}
          onClickFunc={onCreateMedia}
        />
      </Navbar>
      <SegmentsLayout displayNoResult={!user}>
        <div className={styles.container}>
          {/* <PagePanel */}
          {/*   title={t("Library")} */}
          {/*   titleStyles={styles.title} */}
          {/*   buttonTitle={t("+ Add Media")} */}
          {/*   buttonStyles={canAddMedia ? "btn-primary" : "btn-upgrade"} */}
          {/*   onButtonClick={() => onUpgrade()} */}
          {/*   url={canAddMedia && "/resources/new"} */}
          {/* /> */}
          <ResourcesContainer onSelectMedia={onSelectMedia} />
        </div>
        <PostMedia show={showPaywallModal} onClose={closePaywall} />
      </SegmentsLayout>
    </>
  );
};

export default Resources;
