import React from "react";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import closeIcon from "../../../../public/images/close-icon.svg";
import { useTranslation } from "react-i18next";

const PreviewEmail = ({email, closePreview}) => {
  const { t } = useTranslation();
  return (
    <ModalLayout isOpen classNames={`${styles.modal}`}>
      <div className={styles.title_line}>
        <div className={styles.title}>{t("Preview")}</div>
        <img
          src={closeIcon}
          alt="close"
          className={styles.close_icon}
          onClick={closePreview}
        />
      </div>
      <div dangerouslySetInnerHTML={{ __html: email }}></div>
    </ModalLayout>
  );
};

export default PreviewEmail;
