import React, { memo, useMemo } from "react";
import Navbar from "app/components/Navbar";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import TabButton from "app/components/Button/TabButton";
import { useHistory, useLocation } from "react-router-dom";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";

interface IProps {
  children: React.ReactNode;
}

const CoachHomeLayout = ({ children }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const tabs = useMemo(() => {
    return [
      { label: t("Spaces"), path: "/spaces" },
      { label: t("Groups"), path: "/groups" },
      { label: t("Lists"), path: "/lists" },
    ];
  }, [location.pathname]);

  const route = (id: string) => {
    history.push(id);
  };

  const onClickHandler = (path: string) => {
    route(path);
  };

  return (
    <div>
      <Navbar label={t("Home")} />
      <div className={cls(styles.container, "coach-home")}>
        <GettingStartedProgress />
        <div className={styles.tabs_wrapper}>
          {tabs.map((tab) => {
            return (
              <TabButton
                key={tab.label}
                label={tab.label}
                isActive={location.pathname.includes(tab.path)}
                onClick={() => {
                  onClickHandler(tab.path);
                }}
                className={styles.mobile}
              />
            );
          })}
        </div>
        {children}
      </div>
    </div>
  );
};

export default memo(CoachHomeLayout);
