export const filterLocationForPosting = (
  selectedDestinations: { id: string; object: string }[]
): Record<string, string[]> => {
  return selectedDestinations.reduce<Record<string, string[]>>((acc, obj) => {
    const key =
      obj.object === "smart_list"
        ? "smart_list_ids"
        : obj.object === "athlete_space"
        ? "space_id"
        : obj.object === "team"
        ? "team_ids"
        : obj.object === "segment"
        ? "segment_ids"
        : "team_group_ids";

    return { ...acc, [key]: [...(acc[key] || []), obj.id] };
  }, {});
};
