import { useActions, useSelector } from "app/utils/hooks";
import {
  allowMultipleSelect,
  clearDestinations,
  fetchDestinations,
} from "app/actions/destinations";
import { useEffect, useState } from "react";
import { RootState } from "typedefs";
import { CopyPostModel } from "app/models/CopyPostModel";
import TagModel from "app/models/TagModel";
import { MovePostDestination, MovePostModel } from "app/models/MovePostModel";
import {
  clearCopy,
  copyLocation,
  createCopyPost,
  fetchPost,
} from "app/actions/copy";
import {
  clearMove,
  moveLocation,
  moveObjectLocation,
  movePost,
} from "app/actions/move";
import { clearSegments, fetchSegments } from "app/actions/segments";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { filterLocationForPosting } from "../helpers/filterLocationForPosting";

const useCopyMovePostModel = () => {
  const actions = useActions({ fetchDestinations, clearDestinations });
  const postActions = useActions({ fetchPost, createCopyPost, clearCopy });
  const movePostActions = useActions({ movePost, clearMove });
  const segmentActions = useActions({ fetchSegments, clearSegments });

  const history = useHistory();

  const location = useLocation();

  const route = (path: string) => {
    history.push(path);
  };

  const [isMove] = useState(location?.pathname.includes("move"));

  const {
    data: post,
    pending: postPending,
    dataCopy,
  } = useSelector((state: RootState) => state.copy, shallowEqual);
  const dispatch = useDispatch();
  const moveLocationSelector = useSelector(
    (state: RootState) => state.move.moveLocation,
    shallowEqual
  );

  const copyLocationSelector = useSelector(
    (state: RootState) => state.copy.copyLocation,
    shallowEqual
  );
  const copyPending = useSelector(
    (state: RootState) => state.copy.pendingCopy,
    shallowEqual
  );

  const { dataMove } = useSelector(
    (state: RootState) => state.move,
    shallowEqual
  );

  const { selectedDestinations } = useSelector(
    (state: RootState) => state.destinations,
    shallowEqual
  );

  const [postDetails, setPostDetails] = useState("");
  const [postTags, setPostTags] = useState([]);

  // modals
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [movePostReplies, setMovePostReplies] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [btnLabel, setBtnLabel] = useState<string>("Copy");
  const [isPostMove, setIsPostMove] = useState(false);
  const [moveLocationUrl, setMoveLocationUrl] = useState("spaces");
  const currentUrl = window.location.pathname;

  useEffect(() => {
    const id = window.location.pathname.split("/")[2];
    postActions.fetchPost(id).then((res) => {
      setPostTags(res?.tags);
      setPostDetails(res?.details);
    });

    segmentActions.fetchSegments();

    return () => {
      dispatch(clearDestinations());
      dispatch(clearCopy());
      dispatch(clearMove());
      dispatch(clearSegments());
      setPostDetails("");
      setPostTags([]);
      setErrorMessage(null);
      setMovePostReplies(false);
      setSendNotification(false);
      setOpenMoveModal(false);
      setOpenCopyModal(false);
    };
  }, [location]);

  useEffect(() => {
    dispatch(allowMultipleSelect(!isMove));
    isMove ? setBtnLabel("Move") : setBtnLabel("Copy");
    if (currentUrl.includes("move")) {
      setIsPostMove(true);
    } else {
      setIsPostMove(false);
    }

    return () => {
      dispatch(allowMultipleSelect(true));
    };
  }, []);

  const handleMoveClick = () => {
    if (!isPostMove) {
      return;
    }

    if (!selectedDestinations || selectedDestinations?.length < 1) {
      setErrorMessage("Please select location");
    } else {
      setErrorMessage(null);
      setOpenMoveModal(true);
    }
  };

  const onTagsChange = (tags: TagModel[]) => {
    setPostTags(tags as any);
  };

  useEffect(() => {
    if (post && !postPending) {
      setPostDetails(post.details);
    }
  }, [postPending, post]);

  const copyRedirect = () => {
    if (copyPending) {
      return;
    }
    history.goBack();
    postActions.clearCopy();
  };

  useEffect(() => {
    if (selectedDestinations && selectedDestinations?.length > 0) {
      switch (selectedDestinations[0].object) {
        case "smart_list":
          dispatch(moveObjectLocation("smart_list"));
          setMoveLocationUrl("smartList");
          break;
        case "athlete_space":
          dispatch(moveObjectLocation("athlete_space"));
          setMoveLocationUrl("spaces");
          break;
        case "team":
          dispatch(moveObjectLocation("team"));
          setMoveLocationUrl("groups");
          break;
        case "team_group":
          dispatch(moveObjectLocation("team_group"));
          setMoveLocationUrl("channels");
          break;
        default:
          return;
      }
      dispatch(moveLocation(selectedDestinations[0].id));
    }
  }, [selectedDestinations]);

  useEffect(() => {
    if (dataMove && moveLocationSelector) {
      if (moveLocationUrl === "channels") {
        route(
          `/groups/${selectedDestinations[0].team.id}/channels/${moveLocationSelector}`
        );
      }
      if (moveLocationUrl !== "channels") {
        route(`/${moveLocationUrl}/${moveLocationSelector}`);
      } else {
        history.goBack();
      }
      movePostActions.clearMove();
    }
  }, [dataMove]);

  function handleCancel() {
    history.goBack();
  }

  useEffect(() => {
    actions.fetchDestinations();
  }, []);

  const handleCopyClick = () => {
    if (!isPostMove && selectedDestinations) {
      const pinIds = selectedDestinations.map((pin: any) => pin.id);
      dispatch(copyLocation(pinIds));
    }

    const filteredDestinations = filterLocationForPosting(selectedDestinations);

    if (
      !isPostMove &&
      (!copyLocationSelector || copyLocationSelector?.length < 1)
    ) {
      setErrorMessage("Please select at least one location");
    } else {
      setErrorMessage(null);
    }

    if (copyPending) {
      return;
    }

    const postModel: CopyPostModel = {
      details: postDetails,
      ...filteredDestinations,
      tags: postTags?.map((tag: { name: string }) => tag.name),
    };

    if (post.type !== "note") {
      postModel.resource_id = post[post.type].id;
      postModel.resource_type = post.type;
    }

    postActions.createCopyPost(postModel);
  };

  useEffect(() => {
    if (dataCopy) {
      setOpenCopyModal(true);
    }
  }, [dataCopy]);

  const createMovePost = () => {
    const postModel: MovePostModel = {
      post: {
        details: postDetails,
        tags: postTags?.map((tag: { name: string }) => tag.name),
      },
      destination: getSingleDestination(selectedDestinations),
      include_replies: movePostReplies,
      skip_post_notifications: sendNotification,
    };

    movePostActions.movePost(post.id, postModel);
  };

  function getSingleDestination(locations: any): MovePostDestination {
    const location = locations[0];

    if (location.object === "smart_list") {
      return {
        id: location.id,
        type: "smart_list",
      };
    }

    if (location.object === "athlete_space") {
      return {
        id: location.id,
        type: "space",
      };
    }

    if (location.object === "team_group") {
      return {
        id: location.id,
        type: "team_group",
      };
    }

    if (location.object === "team") {
      return {
        id: location.id,
        type: "team",
      };
    }

    throw new Error("Invalid location object");
  }

  return {
    isMove,
    handleCancel,
    post,
    postPending,
    setPostDetails,
    postDetails,
    createMovePost,
    setPostTags,
    postTags,
    openMoveModal,
    setOpenMoveModal,
    movePostReplies,
    sendNotification,
    setMovePostReplies,
    setSendNotification,
    errorMessage,
    handleMoveClick,
    openCopyModal,
    setOpenCopyModal,
    copyRedirect,
    btnLabel,
    selectedDestinations,
    onTagsChange,
    isPostMove,
    handleCopyClick,
  };
};

export default useCopyMovePostModel;
