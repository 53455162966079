import { useEffect, useRef, useState } from "react";
import {
  useActions,
  useLoc,
  useSelector,
  useStoreActions,
} from "app/utils/hooks";
import { selectedDestinations } from "app/actions/destinations";
import { fetchTeams } from "app/actions/team";
import { clearList, fetchLists } from "app/actions/list";
import { RootState } from "typedefs";
import { shallowEqual, useDispatch } from "react-redux";
// import { fetchSmartLists } from "app/actions/smartList";
import { LocationsDropdownProps } from "./index.types";
// import SmartListModel from "app/models/SmartListModel";
import ListModel from "app/models/ListModel";
import TeamModel from "app/models/TeamModel";
import TeamGroupModel from "app/models/TeamGroupModel";
// import { useTranslation } from "react-i18next";
import useSmartListsData from "../helpers/useSmartListsData";
import useSpacesModel from "app/routes/Spaces/indexModel";

type SmartListModel = {
  object: string;
  id: string;
  name: string;
};

type DropdownState = {
  smartList: SmartListModel[];
  list: {
    data: ListModel[];
    pending?: boolean;
  };
  team: {
    data: TeamModel[];
    pending?: boolean;
  };
};

const useLocationsModel = ({ onCheckboxChange }: LocationsDropdownProps) => {
  // const { t } = useTranslation();
  // const { smartList } = useStoreActions({ smartList: () => fetchSmartLists() });
  const { list } = useStoreActions({ list: () => fetchLists() });
  const { team } = useStoreActions({
    team: () => fetchTeams({ "options[include]": ["team_groups"] }),
  });
  const dispatch = useDispatch();
  const actions = useActions({ clearList });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isSmartList, setIsSmartList] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { formatMessage } = useLoc();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedLists, setSelectedLists] = useState<Set<any>>(new Set());
  const smartListsData = useSmartListsData();

  const { spacesData, pending, sentryRef, updateSearch } = useSpacesModel();

  const allowMultipleSelect = useSelector(
    (state: RootState) => state.destinations.allowMultipleSelect,
    shallowEqual,
  );

  const toggleListClick = (item: any) => {
    if (!allowMultipleSelect) {
      setSelectedLists(new Set([item]));
    } else {
      setSelectedLists((selectedLists) => {
        const newSelectedLists = new Set(selectedLists);
        if (newSelectedLists.has(item)) {
          newSelectedLists.delete(item);
        } else {
          newSelectedLists.add(item);
        }
        return newSelectedLists;
      });
    }
  };

  useEffect(() => {
    dispatch(selectedDestinations(Array.from(selectedLists)));
    if (onCheckboxChange) {
      onCheckboxChange(Array.from(selectedLists));
    }
  }, [selectedLists]);

  const [dropDownData, setDropdownData] = useState<DropdownState>({
    smartList: smartListsData,
    list: { data: [], pending: true },
    team: { data: [], pending: true },
  });

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    updateSearch(value);
  };

  const modalHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, dropdownRef]);

  useEffect(() => {
    const url = window.location.pathname.includes("smart_list");
    setIsSmartList(url);

    return () => {
      actions.clearList();
    };
  }, []);

  useEffect(() => {
    setDropdownData((prev) => ({
      ...prev,
      list: { data: list.data, pending: list.pending },
      team: { data: team.data, pending: team.pending },
    }));
  }, [list, team]);

  const filteredSmartListData = Array.isArray(dropDownData.smartList)
    ? dropDownData.smartList.filter((item: SmartListModel) => {
        if (item.name) {
          return item.name.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      })
    : [];

  const filteredListData = Array.isArray(dropDownData.list.data)
    ? dropDownData.list.data.filter((item: ListModel) => {
        if (item.name) {
          return item.name.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      })
    : [];

  const filteredTeamData = Array.isArray(dropDownData.team.data)
    ? dropDownData.team.data.filter((item: TeamModel) => {
        if (item.name) {
          if (Array.isArray(item.teamGroups)) {
            const filteredGroups = item.teamGroups.filter(
              (group: TeamGroupModel) =>
                group.name.toLowerCase().includes(searchQuery.toLowerCase()),
            );
            return (
              item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              filteredGroups.length > 0
            );
          }
          return item.name.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      })
    : [];

  const filteredDropDownData: {
    smartList: SmartListModel[];
    list: { data: ListModel[]; pending: false };
    team: { data: TeamModel[]; pending: false };
  } = {
    smartList: filteredSmartListData,
    list: {
      data: filteredListData,
      pending: false,
    },
    team: {
      data: filteredTeamData,
      pending: false,
    },
  };

  return {
    isSmartList,
    modalHandler,
    dropdownRef,
    isOpen,
    dropDownData,
    formatMessage,
    toggleListClick,
    selectedLists,
    filteredDropDownData,
    handleSearchChange,
    searchQuery,
    pending,
    spacesData,
    sentryRef,
  };
};

export default useLocationsModel;
