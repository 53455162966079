import React from 'react';
import styles from "./styles.module.scss";
import UserAvatar from 'app/components/UserAvatar';
import StatusIndicator from '../../StatusIndicator';

export default function AthleteListItem({ athlete }) {
  return (
    <div className={styles.athlete_list_item}>
      <div className={styles.name_container}>
        <div className={styles.avatar}>
          <UserAvatar
              srcName={athlete.profilePicture}
              userName={athlete.firstName + " " + athlete.lastName}
              altName="avatar"
              className={styles.avatar}
              sizeHeight={40}
              sizeWidth={40}
            />
        </div>
        <div>{athlete.firstName} {athlete.lastName}</div>
      </div>
        <div className={styles.purchases}>{athlete.purchaseCount}</div>
        <StatusIndicator payment_status={athlete.lastPurchaseStatus}/>
    </div>
  );
}
