import { useEffect, useRef, useState } from "react";
import { clearPost, fetchPosts, reportPost } from "app/actions/posts";
// import { scheduledPosts } from "app/actions/schedule";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { deletePost, editPost } from "app/actions/postingForm";
import { allowMultipleSelect } from "app/actions/destinations";
import PostModel from "app/models/PostModel";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { FilterModel } from "app/models/FilterModel";

export const FeedTimelineModel = () => {
  const dispatch = useDispatch();
  const postsActions = useActions({
    fetchPosts,
    clearPost,
    editPost,
    deletePost,
    reportPost,
  });

  const { pending, hasMore } = useSelector(
    (state: RootState) => state.posts,
    shallowEqual
  );

  const [newScehduleModalOpen, setNewScheduleModalOpen] =
    useState<boolean>(false);
  const [scheduledAt, setScheduledAt] = useState(null);
  const [reportModal, setReportModal] = useState({ isOpen: false, post: null });
  const [data, setData] = useState<PostModel[]>([]);

  const postsParams = {
    "options[include]": ["replies", "space"],
    limit: 20,
    reply_limit: 3,
  };

  const [query, setQuery] = useState<{
    limit?: number;
    skip?: number;
    reply_limit?: number;
  }>({ ...postsParams, skip: 0 });

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchPage = async (queryVal: {}) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    try {
      const res = await postsActions.fetchPosts(
        queryVal,
        abortControllerRef.current.signal
      );
      if (!abortControllerRef?.current?.signal?.aborted) {
        if (res) {
          setData((prevData: any) => [...prevData, ...res]);
          setQuery(queryVal);
        } else {
          console.error("API response is null or undefined");
        }
      }
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching posts:", error);
      }
    }
  };

  const initialLoadPosts = (queryVal: {}) => {
    setData([]);
    postsActions.clearPost();
    fetchPage({ ...queryVal, skip: 0 });
  };

  const loadMore = () => {
    if (!pending) {
      const newQuery = { ...query, skip: query.skip + postsParams.limit };
      fetchPage(newQuery);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    initialLoadPosts({ ...postsParams, skip: 0 });
    dispatch(allowMultipleSelect(true));
    return () => {
      // dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      postsActions.clearPost();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      setQuery(postsParams);
    };
  }, [dispatch, postsActions]);

  const handleNewSchedulePost = (): void => {
    setNewScheduleModalOpen((prev: boolean): boolean => !prev);
  };

  const onReport = (post: PostModel, message?: string) => {
    postsActions
      .reportPost(post.id, message)
      .then(() => {
        setData(
          data.filter((filteredPost: PostModel) => filteredPost.id !== post.id)
        );
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
      });
  };

  const onPostReportClick = (post: PostModel) => {
    setReportModal({ isOpen: true, post });
  };

  const reportModalClose = () => {
    setReportModal({ isOpen: false, post: null });
  };

  const onReportHandler = (reason: string) => {
    onReport(reportModal?.post, reason);
    reportModalClose();
  };

  const onPostDelete = (post: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmation) {
      postsActions
        .deletePost(post.id)
        .then(() => {
          setData(
            data.filter(
              (filteredPost: PostModel) => filteredPost.id !== post.id
            )
          );
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onPostUpdate = (post: PostModel) => {
    if (post) {
      postsActions.editPost(post);
    }
  };

  function filterHandler(filters: FilterModel) {
    const filteredQuery = { ...postsParams, ...filters };
    initialLoadPosts(filteredQuery);
  }

  function onClearFilters() {
    initialLoadPosts(postsParams);
  }

  const onPost = (post: PostModel) => {
    setData([post, ...data]);
    if (post?.scheduledAt) {
      setScheduledAt(post.scheduledAt);
      handleNewSchedulePost();
    }
  };

  return {
    data,
    pending,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostDelete,
    onPostUpdate,
    sentryRef,
    filterHandler,
    onClearFilters,
    onPost,
    newScehduleModalOpen,
    handleNewSchedulePost,
    scheduledAt,
  };
};
