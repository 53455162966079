import React from "react";
import styles from "./styles.module.scss";
import { SpacesScheduledTimelineModel } from "./indexModel";
import SpacePageLayout from "app/components/Layouts/SpacesPageLayout";
import ScheduledPagePostsContainer from "app/components/ScheduledPagePostsContainer";

const SpacesScheduledTimeline = () => {
  const {
    selectedSpace,
    data,
    loading,
    onPostDelete,
    onPostUpdate,
    sentryRef,
  } = SpacesScheduledTimelineModel();

  return (
    <SpacePageLayout>
      {selectedSpace && (
        <div className={styles.content_container}>
          <ScheduledPagePostsContainer
            displayPostLocations
            onPostDelete={onPostDelete}
            onPostUpdate={onPostUpdate}
            containerRef={sentryRef}
            posts={data}
            pending={loading}
            hideReply
            hideLikes
          />
        </div>
      )}
    </SpacePageLayout>
  );
};

export default SpacesScheduledTimeline;
