/**
 * @module Actions.Connection
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet } from "app/utils/request";
import { actionUpdater } from "app/utils/actions";
import { UserModel } from "app/models";

const connectionGet = () => {
  return fetchGet("connections", {}, { version: 1 });
};

const coachesGet = () => {
  return fetchGet("connections", { kind: "coach" }, { version: 2 });
};

const pending = () => action("@connection.fetch.pending");
const error = (err: string) => action("@connection.fetch.error", err);
const success = (model: UserModel[], updater: typeof fetchConnections) =>
  action("@connection.fetch.success", { model, updater });

const thisActions = { pending, error, success };

export type ConnectionAction = ActionType<typeof thisActions>;

export function fetchConnections() {
  return (dispatch: Dispatch<ConnectionAction>) => {
    dispatch(pending());

    const updaterFunc = actionUpdater(dispatch, fetchConnections);

    return connectionGet()
      .then((model: UserModel[]) => dispatch(success(model, updaterFunc)))
      .catch((err) => dispatch(error(err)));
  };
}

export function fetchConnectedCoaches() {
  return (dispatch: Dispatch<ConnectionAction>) => {
    dispatch(pending());

    const updaterFunc = actionUpdater(dispatch, fetchConnectedCoaches);

    return coachesGet()
      .then((model: UserModel[]) => dispatch(success(model, updaterFunc)))
      .catch((err) => dispatch(error(err)));
  };
}

export const actions = { fetchConnections, fetchConnectedCoaches };
