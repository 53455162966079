import React from 'react';
import styles from "./styles.module.scss";
import SessionSvg from "../../../../public/images/session.svg";
import { cls } from "app/utils";
import { schedulingFetchPost } from 'app/utils/request/scheduling/fetch';
import ToolTip from 'app/routes/Scheduling/ToolTip';
import { useTranslation } from "react-i18next";
import { currency_symbol } from '../NewOffering/utils';
export default function AthleteOffering({ hasSubscription, product, coachId, studentId }) {

  const { t } = useTranslation();
  const handleCheckout = async () => {
    try {
      schedulingFetchPost(`/stripe_checkout?coach_id=${coachId}&student_id=${studentId}&stripe_product_id=${product.stripeProdId}`)
        .then((data) => {
          if (!data || !data.url) {
            throw new Error('Failed to checkout.');
          }
          window.location.href = data.url;
        })
        .catch((error) => {
          console.error('Error connecting to Stripe:', error);
        });
    } catch (error) {
      console.error('Error connecting to Stripe:', error);
    }
  };

  const buttonText = () => {
    if (product?.productType === 'product') {
      return t('Buy Now');
    } else if (product.subscribed) {
      return t('Subscribed');
    } else {
      return t('Subscribe Now');
    }
  };

  const buttonDisabled =
      hasSubscription !== null &&
      hasSubscription &&
      product.productType === "subscription";

  return (
    <>
    <div className={styles.product_card}>
      <div className={styles.product_body}>
        <div className={styles.product_status}>
          <div className={styles.titleRow}>
            <div
              className={styles.truncatedText}
              title={product.title?.length > 27 ? product.title : ''}
            >
              {product.title?.length > 27 ? `${product?.title?.substring(0, 27)}...` : product.title}
            </div>
            {product.title?.length > 27 && (
              <div className={styles.toolTipPosition}>
                <ToolTip text={product.title} orientation="right" />
              </div>
            )}
          </div>
        </div>
        <div className={styles.product_info}>
        {product?.numberOfSessions ?
        (
        <span className={styles.product_session}>
          <img src={SessionSvg} alt="tags" className={styles.card_icon}/>
          {product?.numberOfSessions === -1 ?
          (
          <div> &infin; {t('Sessions')}</div>
          ) : t("count_sessions", { count: Number(product?.numberOfSessions) }) }
          </span>
          ) : <span className={styles.product_session_empty}> </span> }
          <div className={styles.product_details}>
            {product.description}
          </div>
        </div>
      </div>
      <hr/>

      <div className={styles.product_footer}>
        <div className={product.enabled ? styles.product_price: styles.product_price_inactive}>{currency_symbol[product.currency]}{product.price}</div>
        <p className={styles.payment_info}>{product?.productType === 'product'? 'One time payment' : 'Monthly recurring billing'}</p>
      </div>
      <div className={styles.button_container}>
      <button
            disabled={buttonDisabled}
            className={cls("btn btn-primary", styles.offering_button, {
              ["disabled"]:
                false,
            })}
            onClick={handleCheckout}
          > {buttonText()}
        </button>
    </div>
    </div>
    </>
  );
}

