import React, { useState } from "react";
import styles from "./styles.module.scss";
import UserAvatar from "../UserAvatar";
import DropdownButton from "../DropdownButton";
import UserModel from "app/models/UserModel";
import { cls } from "app/utils";
import EditMemberModal from "app/routes/Groups/_components/EditMemberModal";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";

interface Props {
  member: UserModel;
  role?: string | null;
  onRemoveMember?: (id: string, userName: string) => void;
  memberEditModal?: boolean;
  onMemberUpdate?: (member: UserModel) => void;
}

const GroupMember = ({
  member,
  role,
  onRemoveMember,
  onMemberUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const [memberEditModal, setMemberEditModal] = useState(false);

  const memberModalHandler = () => {
    setMemberEditModal((prev) => !prev);
  };

  const memberMenu = [
    {
      title: t("Edit Member"),
      onClick: () => {
        memberModalHandler();
      },
    },
    {
      title: t("Remove From Group"),
      onClick: (id: string) => {
        onRemoveMember(id, member.name);
      },
      className: styles.delete_btn,
    },
  ];

  const canManageMembers = () => {
    const hasManagePermissions =
      selectedGroup.permissions.includes("manage_members");
    const hasPlanManagePermissions =
      selectedGroup.planPermissions.includes("manage_members");

    return !role && hasManagePermissions && hasPlanManagePermissions;
  };

  const onMemberUpdateHandler = (user: UserModel) => {
    onMemberUpdate(user);
    memberModalHandler();
  };

  return (
    <div className={styles.member_container}>
      <div className={styles.member_wrapper}>
        <UserAvatar
          altName="member"
          srcName={member.avatar as string}
          userName={member.name}
          className={styles.avatar}
          sizeHeight={44}
          sizeWidth={44}
        />
        <div className={styles.member_info}>
          <div className={styles.username}>
            {member.name}{" "}
            {!member.email && (
              <b className={styles.error_text}>({t("Missing Email")})</b>
            )}
            {member.nickname && (
              <span className={styles.nickname_container}>
                {member.nickname}
              </span>
            )}
          </div>
          {role && <div className={styles.role}>{role}</div>}
        </div>
      </div>
      {canManageMembers() && (
        <DropdownButton
          closeOnClick
          dropdownWrapperClass={styles.dropdown_wrapper}
          btnContent={<i className="bullets" />}
        >
          {memberMenu.map((item) => (
            <div key={item.title} className={styles.container}>
              <button onClick={() => item.onClick(member.id)}>
                <p className={cls(item.className)}>{item.title}</p>
              </button>
            </div>
          ))}
        </DropdownButton>
      )}
      {memberEditModal && (
        <EditMemberModal
          member={member}
          isOpen={memberEditModal}
          onClose={memberModalHandler}
          onMemberUpdate={onMemberUpdateHandler}
        />
      )}
    </div>
  );
};

export default GroupMember;
