import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import info_bulb from "app/images/bulb.svg";

const DemoSpacesDescription = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.content_header_info}>
      <img src={info_bulb} alt="" />
      <div className={styles.main_content}>
        <div className={styles.content_header_content}>
          <p>
            {t(
              "Our founder Spencer Dennis has been kind enough to highlight one of his relationships. Inside the Space you'll see some interactions with the athlete, their parent and Spencer over a 6 month period.",
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DemoSpacesDescription;
