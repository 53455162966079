import React, { memo } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { cls } from "app/utils";
import DropdownButton from "app/components/DropdownButton";
import TabButton from "app/components/Button/TabButton";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import TeamsSettings from "./TeamSettings";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TeamsTabsContainer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const groupId = selectedGroup ? selectedGroup.id : null;
  const teamId = selectedTeam ? selectedTeam.id : null;

  const onTimelineClick = () => {
    if (location.pathname.includes("members")) {
      route(`/groups/${groupId}/channels/${teamId}`);
    }
  };

  const onMembersClick = () => {
    if (!location.pathname.includes("members")) {
      route(`/groups/${groupId}/channels/${teamId}/members`);
    }
  };

  if (!selectedGroup || !selectedTeam) {
    return null;
  }

  const canViewMembers = () => {
    return (
      selectedGroup?.permissions?.includes("view_members") ||
      selectedGroup?.ownerId === self?.id
    );
  };

  const userIcon = () => {
    return (
      <i
        className={cls("tab-icon ico", {
          ["ico-user"]: location.pathname.includes("members"),
          ["ico-user-o"]: !location.pathname.includes("members"),
        })}
      />
    );
  };

  const dropdownContent = () => {
    return (
      <DropdownButton
        btnClassName={styles.dropdown_btn_className}
        btnContent={
          <i className="dropdown-toggle btn btn-outline-secondary ico ico-cog dark" />
        }
      >
        <TeamsSettings />
      </DropdownButton>
    );
  };

  return (
    <div className={styles.container}>
      <PagePanel
        panelClassName={styles.panel}
        title={selectedTeam.name}
        titleStyles={cls("text-muted", styles.page_panel_title)}
        subTitle={selectedTeam.description ? selectedTeam.description : null}
        headerChildren={dropdownContent()}
      >
        <div className={styles.navbar_wrapper}>
          <TabButton
            label={t("Timeline")}
            icon={<i className={cls("tab-icon ico ico-feed-o")} />}
            isActive={!location.pathname.includes("members")}
            onClick={onTimelineClick}
            className={styles.mobile}
          />
          {canViewMembers() && (
            <TabButton
              label={t("Channel Members")}
              icon={userIcon()}
              isActive={location.pathname.includes("members")}
              onClick={onMembersClick}
              className={styles.mobile}
            />
          )}{" "}
        </div>
      </PagePanel>
    </div>
  );
};

export default memo(TeamsTabsContainer);
