import React, { useEffect, useState } from "react";
import SelectBox, { SelectProps } from "app/components/inputs/SelectBox/Base";
import { cls } from "app/utils";
import {
  clearSpaceTemplate,
  fetchSpacesTemplates,
} from "app/actions/spaceTemplate";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";

type PropsDef = SelectProps & {
  value?: { label: string; value: string };
  defaultValue?: { label: string; value: string };
};

export default function TemplateSelect(props: PropsDef) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: spaceTemplates } = useSelector(
    (state: RootState) => state.spaceTemplate || [],
    shallowEqual,
  );

  const { className, isCreatable, isClearable, value, defaultValue, ...rest } =
    props;
  useEffect(() => {
    dispatch(fetchSpacesTemplates());

    return () => {
      dispatch(clearSpaceTemplate());
    };
  }, [dispatch]);

  const [templpateOptions, setTemplateOptions] = useState([]);

  const createTemplateOption = (val: {
    name?: string;
    id?: string;
    members?: UserModel[];
  }): any => {
    return {
      value: val.name,
      label: val.name,
      id: val.id,
      members: val?.members ?? [],
    };
  };

  useEffect(() => {
    if (spaceTemplates?.length) {
      const templateOptions = spaceTemplates?.map(createTemplateOption);
      setTemplateOptions(templateOptions);
    }
  }, [spaceTemplates]);

  return (
    <SelectBox
      className={cls("sport-select", className)}
      placeholder={t("Select Template")}
      isCreatable={isCreatable}
      isClearable={isClearable || isCreatable}
      options={templpateOptions}
      defaultValue={defaultValue}
      value={value}
      {...rest}
    />
  );
}
