import React from "react";
import styles from "./styles.module.scss";
import { GroupSelectProps } from "./index.types";
import no_team_png from "app/images/null-training-space.png";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import useTeamSelectModal from "./indexModel";
import TeamGroupModel from "app/models/TeamGroupModel";
import ListModel from "app/models/ListModel";
import ApplyTemplateListsModal from "../ApplyTemplateListsModal";
import { useTranslation } from "react-i18next";

const ChannelSelectModal = ({
  onClose,
  isOpen,
  templateId,
}: GroupSelectProps) => {
  const { t } = useTranslation();
  const {
    channelSelectHandler,
    cancelHandler,
    applyHandler,
    onSearchChannel,
    team,
    searchTerm,
    results,
    selectedChannelsId,
  } = useTeamSelectModal({ onClose, templateId });

  if (!isOpen) {
    return null;
  }

  return (
    <ApplyTemplateListsModal
      pending={team.pending || team.data == null}
      applyHandler={applyHandler}
      cancelHandler={cancelHandler}
      hasNoResult={
        searchTerm.length === 0 && (!team.data || team.data.length === 0)
      }
      noResultImage={no_team_png}
      noResultTitle={t("No Channels")}
      noResultSubText={t("You don't have created a channel yet")}
      isOpen={isOpen}
      onClose={onClose}
      searchPlaceholder={t("Search Channel...")}
      onSearchChange={onSearchChannel}
      modalSubTitle={t(
        "You can apply this Template to the Channel and posts will be added in the set interval and order",
      )}
    >
      <div className={styles.team_list_container}>
        {results &&
          results.map((team: TeamGroupModel) => {
            if (team && team.teamGroupsCount > 0) {
              return (
                <div
                  key={team.id}
                  className={cls(styles.team_channel_container)}
                >
                  <div className={styles.team_container}>
                    <div className={styles.avatar}>
                      <UserAvatar
                        sizeWidth={44}
                        sizeHeight={44}
                        className="avatar"
                        userName={team.name}
                        srcName={team.logo as string}
                        altName={team.name}
                      />
                    </div>
                    <div className={styles.team}>
                      <div className={cls(styles.team_title, styles.elipsis)}>
                        {team.name}
                      </div>
                      <div
                        className={cls(styles.team_subtitle, styles.elipsis)}
                      >
                        {team.sport}
                      </div>
                    </div>
                  </div>
                  <div className={styles.channel_container}>
                    {team.teamGroups &&
                      team.teamGroups.map((channel: ListModel) => {
                        return (
                          <div key={channel.id}>
                            <div className={styles.channel}></div>

                            <div
                              className={cls(
                                styles.team_container,
                                styles.channel_hover,
                                {
                                  [styles.disabled]:
                                    !channel.planPermissions.includes("post"),
                                },
                              )}
                              onClick={() =>
                                team.planPermissions.includes("post")
                                  ? channelSelectHandler(
                                      channel.id as string,
                                      channel,
                                    )
                                  : null
                              }
                            >
                              <UserAvatar
                                sizeWidth={44}
                                sizeHeight={44}
                                className="avatar"
                                userName={channel.ownerName}
                                srcName={channel.avatar as string}
                                altName={channel.name}
                              />
                              <div className={styles.team}>
                                <div
                                  className={cls(
                                    styles.team_title,
                                    styles.elipsis,
                                  )}
                                >
                                  {channel.name}
                                </div>
                                <div className={cls(styles.team_subtitle)}>
                                  {t("Channel")}
                                </div>
                              </div>
                              <span className={styles.checkbox}>
                                {selectedChannelsId.includes(channel.id) ? (
                                  <i
                                    className={cls(
                                      "fas fa-check-circle",
                                      styles.checked,
                                    )}
                                  />
                                ) : (
                                  <i
                                    className={cls(
                                      "far fa-circle",
                                      styles.unchecked,
                                    )}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    </ApplyTemplateListsModal>
  );
};

export default ChannelSelectModal;
