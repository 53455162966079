import React from "react";
import styles from "./styles.module.scss";
import TemplatePageLayout from "app/components/Layouts/TemplatePageLayout";
import { TemplateTimelineModel } from "./indexModel";
import TemplatePostsContainer from "app/components/TemplatePostsContainer";
import TemplatePostingForm from "../TemplatePostingForm";

const TemplateTimeline = () => {
  const {
    selectedTemplate,
    data,
    groupedPosts,
    pending,
    onPostDelete,
    onPostUpdate,
    sentryRef,
    onPost,
  } = TemplateTimelineModel();

  return (
    <TemplatePageLayout>
      {selectedTemplate && (
        <div className={styles.content_container}>
          <TemplatePostingForm onPost={onPost} />
          <TemplatePostsContainer
            onPostUpdate={onPostUpdate}
            onPostDelete={onPostDelete}
            containerRef={sentryRef}
            posts={data}
            groupedPosts={groupedPosts}
            pending={pending}
            hidePinBadge={true}
          />
        </div>
      )}
    </TemplatePageLayout>
  );
};

export default TemplateTimeline;
