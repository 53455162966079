import { ActionType, action } from "typesafe-actions";

export const setAutoRegister = (autoRegister: boolean) =>
  action("@program.setAutoRegister", autoRegister);

const thisActions = {
  setAutoRegister
};

export type ProgramRegistrationAction = ActionType<typeof thisActions>;
