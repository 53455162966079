import React from "react";
import styles from "./styles.module.scss";
import SearchBar from "app/components/inputs/SearchBar";
import { useGroupsModel } from "./indexModel";
import { CreateNewGroupModal } from "./_components/CreateNewGroupModal";
import TransferSuccessModal from "./_components/TransferSuccessModal";
import NoGroupsResult from "./_components/NoGroups";
import { useTranslation } from "react-i18next";
import CoachHomeLayout from "app/components/Layouts/CoachHomeLayout";
import ButtonVariant from "app/components/Button/ButtonVariant";
import GroupsList from "./GroupsList";

const LayoutWrapper = ({
  children,
  onSearchGroup,
  isOpenCreateGroupModal,
  createGroupModalHandler,
}: {
  children: React.ReactNode;
  onSearchGroup: (term: string) => void;
  isOpenCreateGroupModal: boolean;
  createGroupModalHandler: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <CoachHomeLayout>
      <SearchBar
        className={styles.search}
        placeholder={t("Search groups...")}
        onChange={onSearchGroup}
      />
      {children}
      {isOpenCreateGroupModal && (
        <CreateNewGroupModal
          isOpen={isOpenCreateGroupModal}
          onClose={createGroupModalHandler}
        />
      )}
    </CoachHomeLayout>
  );
};

const Groups = () => {
  const {
    results,
    searchTerm,
    onSearchGroup,
    createGroupModalHandler,
    isOpenCreateGroupModal,
    canCreateGroup,
    pending,
  } = useGroupsModel();

  if (results?.length === 0 && !pending && searchTerm?.length > 0) {
    return (
      <LayoutWrapper
        onSearchGroup={onSearchGroup}
        isOpenCreateGroupModal={isOpenCreateGroupModal}
        createGroupModalHandler={createGroupModalHandler}
      >
        <NoGroupsResult searchResult={results} searchedText={searchTerm} />
        {canCreateGroup && (
          <div className={styles.btn_wrapper}>
            <ButtonVariant
              buttonStyles={styles.add_btn}
              buttonType="primary"
              title={"Add new Group"}
              icon={<i className="ico ico-add" />}
              onClickFunc={createGroupModalHandler}
            />
          </div>
        )}
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper
      onSearchGroup={onSearchGroup}
      isOpenCreateGroupModal={isOpenCreateGroupModal}
      createGroupModalHandler={createGroupModalHandler}
    >
      <GroupsList
        groupsData={results}
        pending={pending}
        onClickCreateGroup={createGroupModalHandler}
      />
      <TransferSuccessModal />
    </LayoutWrapper>
  );
};

export default Groups;
