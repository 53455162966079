import React from "react";
import styles from "./styles.module.scss";
import { SpaceModel } from "app/models";
import { cls } from "app/utils";
import { Loading } from "app/components/Wrappers";
import { uuid } from "app/utils/uuid";
import SpaceCardXl from "app/components/SpaceCardXl";
import { Link, useHistory } from "react-router-dom";
import CreateNewXlButton from "app/components/Button/CreateNewXlButton";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
export interface SpacesListProps {
  spacesData?: SpaceModel[];
  pending?: boolean;
  containerRef?: any;
  upgradeHandler: () => void;
  isDemo?: boolean;
  selectedListValue?: any;
}

const SpacesList = ({
  spacesData,
  pending,
  containerRef,
  upgradeHandler,
  isDemo,
  selectedListValue,
}: SpacesListProps) => {
  const history = useHistory();

  const { self } = useSelector((state: RootState) => state.user);

  const newSingle = () => {
    if (!self?.planPermissions?.includes("create_spaces")) {
      return upgradeHandler();
    }

    return history.push("/spaces/new");
  };

  const CreateNewSpace = () => {
    if (
      (pending && spacesData?.length === 0) ||
      isDemo ||
      selectedListValue?.object === "segment"
    ) {
      return null;
    }

    return (
      <span className={styles.create_space} onClick={newSingle}>
        <CreateNewXlButton className={styles.create_space_button} />
      </span>
    );
  };

  return (
    <div className={cls(styles.spaces_list_container)}>
      <div className={styles.space_cards_list}>
        <CreateNewSpace />
        {spacesData?.map((data: SpaceModel) => {
          if (data?.space) {
            return (
              <Link
                key={data.space?.id || uuid()}
                to={`/spaces/${data.space?.id}`}
              >
                <SpaceCardXl spaceData={data.space} />
              </Link>
            );
          }
          return (
            <Link key={data?.id || uuid()} to={data?.timelineUrl}>
              <SpaceCardXl spaceData={data} />
            </Link>
          );
        })}
      </div>
      {pending && (
        <Loading isLoading loadType="spinner" className={styles.loading} />
      )}
      {spacesData?.length > 0 && spacesData?.[0].space && (
        <div ref={containerRef} />
      )}
    </div>
  );
};

export default SpacesList;
