/**
 * @module Components.Selectbox
 *
 */
import React, { useState, useEffect } from "react";
import moment from "moment";
import Selectbox, {
  DefaultOption,
  OptionsType,
} from "app/components/inputs/SelectBox/Base";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropsDef = {
  className?: string;
  maxDate?: number | string;
  minDate?: number | string;
  value?: string;
  defaultValue?: string;
  onChange?: (value: DefaultOption) => void;
};

const normailzeValue = (
  value?: string | null,
  label?: string
): DefaultOption | null => {
  if (!value) {
    return {
      value: "",
      label,
    };
  }
  return {
    value,
    label: value,
  };
};

/**
 * @class YearSelect
 *
 */
export default function YearSelect(props: PropsDef) {
  const { t } = useTranslation();

  const {
    className,
    minDate,
    defaultValue,
    onChange,
    value,
    maxDate,
    ...rest
  } = props;
  const [__options, setOptions] = useState<OptionsType<DefaultOption>>(null);
  const [selectedYear, setSelectedYear] = useState<DefaultOption | null>(
    normailzeValue(value, t("Year"))
  );

  useEffect(
    // @ts-ignore
    () => setOptions(generateYears(minDate, maxDate)),
    [minDate, maxDate]
  );

  const onChangeFunc = (val: any) => {
    if (onChange) {
      setSelectedYear(val);
      onChange(val);
    }
  };

  useEffect(() => {
    if (__options) {
      const options = __options.map((opt) => opt.value);

      if (options.includes(value)) {
        setSelectedYear(normailzeValue(value, t("Year")));
      } else {
        setSelectedYear(normailzeValue(null, t("Year")));
      }
    }
  }, [value, __options]);

  return (
    <Selectbox
      isCreatable={false}
      isClearable={false}
      isMulti={false}
      className={cls(styles.select, className, "c-inputs-month-select")}
      options={__options}
      value={selectedYear}
      onChange={onChangeFunc}
      defaultValue={defaultValue}
      {...rest}
    />
  );
}

function generateYears(
  minDate: number,
  maxDate: number
): OptionsType<DefaultOption> {
  const min =
    minDate != null ? moment(minDate) : moment().subtract(10, "years");
  const max = maxDate != null ? moment(maxDate) : moment();
  const length = max.year() - min.year() + 1;

  let index = max;
  const increment = () =>
    (index = moment(index.valueOf()).subtract(1, "years"));

  return Array.apply(null, Array(length)).map(() => {
    const year = index.format("YYYY");
    increment();
    return { value: year, label: year };
  });
}
