
import { ProgramRegistrationAction } from "app/actions/scheduling/programRegistration";

export type ProgramRegistrationState = {
  autoRegister: boolean;
};

const initialState: ProgramRegistrationState = {
 autoRegister: false,
};

export default function programRegistration(
  state = initialState,
  action: ProgramRegistrationAction
): ProgramRegistrationState {
  switch (action.type) {
    case "@program.setAutoRegister":
      return { ...state, autoRegister: action.payload };
    default:
      return state;
  }
}
