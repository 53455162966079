import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import { Trans, useTranslation } from "react-i18next";
import feedPostIcon from "app/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "app/images/smart-list/15-calendar-icon.svg";
import thirtyDaysIcon from "app/images/smart-list/30-calendar-icon.svg";
import sixtyDaysIcon from "app/images/smart-list/60-plus-calendar-icon.svg";
import SmartListModel from "app/models/SmartListModel";
import { cls } from "app/utils";
import { useHistory } from "react-router-dom";

interface IProps {
  selectedListValue: SmartListModel;
}

const SmartListDescription = ({ selectedListValue }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getHeaderContentInfo = () => {
    if (selectedListValue) {
      if (selectedListValue?.name?.includes("Unanswered")) {
        return {
          icon: feedPostIcon,
          title: t("Unanswered by me"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_athlete_post_no_reply"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedListValue?.name?.includes("15 days inactive")) {
        return {
          icon: fifteenDaysIcon,
          title: t("15 days inactive"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_no_post_15_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedListValue?.name?.includes("15 days active")) {
        return {
          icon: fifteenDaysIcon,
          title: t("15 days active"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_posted_15_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedListValue?.name?.includes("30")) {
        return {
          icon: thirtyDaysIcon,
          title: t("30 days inactive"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_no_post_30_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      } else if (selectedListValue?.name?.includes("60+")) {
        return {
          icon: sixtyDaysIcon,
          title: t("60+ days inactive"),
          infoText: (
            <span>
              <Trans
                i18nKey="space_no_post_60_days"
                components={{ strong: <strong /> }}
              />
            </span>
          ),
        };
      }
    }
    return null;
  };

  const HeaderTitle = () => {
    const contentInfo = getHeaderContentInfo();

    const onPostToListClick = useCallback(() => {
      history.push(
        `/spaces/smartList/${selectedListValue.name
          .toLocaleLowerCase()
          .replace(/\s+/g, "_")}/post_to_smart_list/${selectedListValue?.id}`
      );
    }, [selectedListValue]);

    return (
      contentInfo && (
        <div className={styles.content_header_info}>
          <span className={styles.img_wrapper}>
            <img src={contentInfo.icon} alt="" />
          </span>
          <div className={styles.main_content}>
            <div className={styles.content_header_content}>
              {contentInfo.title && <h3>{contentInfo.title}</h3>}
              {contentInfo.infoText && <p>{contentInfo.infoText}</p>}
            </div>
            {selectedListValue.object === "smart_list" && (
              <button
                className={cls("btn btn-primary", styles.post_to_list_btn)}
                onClick={onPostToListClick}
              >
                {t("Post to list")}
              </button>
            )}
          </div>
        </div>
      )
    );
  };

  return <HeaderTitle />;
};

export default SmartListDescription;
