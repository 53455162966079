import React, { useState } from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import UserModel from "app/models/UserModel";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import { UserSelect } from "app/components/inputs/SelectBox";
import PhoneInput from "react-phone-input-2";
import { IUserPhone } from "app/interfaces/UserTypes";
import { useTranslation } from "react-i18next";

type selectedUserType = {
  name?: string;
  email?: string;
  phone?: null | string;
  phoneCountry?: null | string;
  role?: "coach" | "contributor";
  avatar?: null | string;
};

interface Props {
  onMemberInvite: (user: UserModel) => void;
  invitationError?: string | null;
}

const SpaceMembersInvite = ({ onMemberInvite, invitationError }: Props) => {
  const { t } = useTranslation();
  const [inviteSectionOpen, setInviteSectionOpen] = useState(false);
  const [inviteWithPhone, setInviteWithPhone] = useState(false);
  const [error, setError] = useState<string | null>(
    invitationError ? invitationError : null
  );

  const initialSelectedUserValues: selectedUserType = {
    name: null,
    email: null,
    phone: null,
    phoneCountry: null,
    role: "coach",
    avatar: null,
  };

  const [selectedUser, setSelectedUser] = useState<selectedUserType>(
    initialSelectedUserValues
  );

  const resetStates = () => {
    setError(null);
    setInviteSectionOpen(false);
    setInviteWithPhone(false);
    setSelectedUser(initialSelectedUserValues);
  };

  const toggleInviteSection = () => {
    setSelectedUser(initialSelectedUserValues);
    setInviteSectionOpen(!inviteSectionOpen);
  };

  const selectUser = (user: UserModel | null) => {
    if (!user) {
      setSelectedUser(initialSelectedUserValues);
    } else {
      setSelectedUser({
        ...selectedUser,
        name: user.displayName,
        email: user.email,
        avatar: user.avatar as string,
      });
    }
  };

  const setUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, name: e.target.value });
    }
  };

  const setPhoneNumber = (value: string, data: IUserPhone) => {
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();

    if (selectedUser) {
      setSelectedUser({ ...selectedUser, phone, phoneCountry });
    }
  };

  const onRoleChange = (role: "coach" | "contributor") => {
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, role });
    }
  };

  const onInvite = () => {
    if (!inviteWithPhone) {
      if (!selectedUser.name || !selectedUser) {
        setError(t("Can't be blank"));
        return;
      }
    } else if (inviteWithPhone) {
      if (!selectedUser.phone && !selectedUser.phoneCountry) {
        setError(t("Can't be blank"));
        return;
      }
    }
    onMemberInvite({
      email: selectedUser.email,
      name: selectedUser.name,
      phone: selectedUser.phone,
      phoneCountry: selectedUser.phoneCountry,
      role: selectedUser.role,
    });
    resetStates();
  };

  const onCancel = () => {
    setInviteSectionOpen(false);
    resetStates();
  };

  return (
    <div className={styles.container}>
      {!inviteSectionOpen ? (
        <Button
          buttonType="primary"
          icon={<i className="icon-plus icon-white" />}
          title={t("Invite New Members")}
          buttonStyles={styles.inviteButton}
          onClickFunc={toggleInviteSection}
        />
      ) : (
        <div className={styles.invitation_form_container}>
          <label>{t("Invite New Member")}</label>
          {!inviteWithPhone ? (
            <>
              {selectedUser && selectedUser.name ? (
                <div className={styles.user_info_container}>
                  <div className={styles.user_info_left}>
                    <UserAvatar
                      className={styles.avatar}
                      sizeWidth={44}
                      sizeHeight={44}
                      altName="author"
                      userName={selectedUser.name}
                      srcName={selectedUser.avatar as string}
                    />
                    <div className={styles.user_info}>
                      <h4>{selectedUser.name}</h4>
                      <p>{selectedUser.email}</p>
                    </div>
                  </div>
                  <i
                    className={cls("icon-remove-sign", styles.remove_sign)}
                    onClick={() => selectUser(null)}
                  />
                </div>
              ) : (
                <UserSelect
                  onChange={(user: any) =>
                    user ? selectUser(user[0].model) : null
                  }
                  isCreatable
                />
              )}
            </>
          ) : (
            <div className={styles.invite_by_wrapper}>
              <input
                onChange={setUserName}
                value={selectedUser && selectedUser.name}
                placeholder={t("Name")}
              />
              <PhoneInput
                country={
                  selectedUser.phoneCountry &&
                  selectedUser.phoneCountry.length > 0
                    ? selectedUser.phoneCountry
                    : "us"
                }
                autoFormat
                onChange={setPhoneNumber}
                containerClass={styles.phone_input_container}
                inputClass={styles.form_control}
              />
            </div>
          )}
          {(invitationError || error) && (
            <p className={styles.error}>{invitationError || error}</p>
          )}
          <div className={styles.section_wrapper}>
            <label htmlFor="role">{t("Role")}</label>
            <select
              name="role"
              id="role"
              defaultValue={
                selectedUser.role && selectedUser.role.length > 0
                  ? selectedUser.role
                  : "coach"
              }
              onChange={(e: any) => onRoleChange(e.target.value)}
              placeholder={t("Select Role")}
              className={styles.select_role}
            >
              <option value="coach">{t("Coach")}</option>
              <option value="contributor">{t("Member")}</option>
            </select>
          </div>
          <div
            className={styles.add_by_btn}
            onClick={() => setInviteWithPhone(!inviteWithPhone)}
          >
            {inviteWithPhone
              ? t("Add by Name or Email")
              : t("Add by Phone Number")}
          </div>
          <div className={styles.button_wrapper}>
            <Button
              buttonType="primary"
              title={t("Invite member")}
              onClickFunc={onInvite}
            />
            <Button
              buttonType="secondary-outline"
              title={t("Cancel")}
              onClickFunc={onCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SpaceMembersInvite;
