import { createSegment } from "app/actions/segments";
import { useActions } from "app/utils/hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "typedefs";

interface IProps {
  modalHandler?: () => void;
  onUpgrade?: () => void;
}

export const useCreateNewSegment = ({ modalHandler, onUpgrade }: IProps) => {
  const history = useHistory();
  const segmentActions = useActions({ createSegment });
  const { self } = useSelector((state: RootState) => state.user);

  const [newListName, setNewListName] = useState<string | null>(null);
  const [newListError, setNewListError] = useState<boolean>(false);

  const canCreateSegments = self?.planPermissions?.includes("create_segments");

  const onNewListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewListName(e.target.value);
    setNewListError(false);
  };

  const onSubmit = async () => {
    if (canCreateSegments) {
      if (!newListName || newListName.trim().length === 0) {
        setNewListError(true);
        return;
      }

      try {
        const res = await segmentActions.createSegment({
          name: newListName.trim(),
        });

        if (res.id) {
          const cancelUrl = `/lists/${res.id}/spaces/edit`;
          // window.location.pathname = cancelUrl;
          // route(cancelUrl);
          history.push(cancelUrl);
        } else {
          console.error("Segment creation response is missing 'id'.");
        }
      } catch (error) {
        console.error("Error creating segment:", error);
      }
    } else {
      onUpgrade();
    }
    modalHandler();
  };

  return { onSubmit, newListName, onNewListNameChange, newListError };
};
