import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import Button from "app/components/Button/ButtonVariant";
import SearchBar from "app/components/inputs/SearchBar";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import {
  addMembersToTeam,
  fetchMembersForSelect,
} from "app/actions/team-group";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Loading } from "app/components/Wrappers";
import useDebounce from "app/utils/hooks/useDebounce";
import { inviteModalType } from "app/actions/team-group-member-import";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import MembersList from "../MembersList";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onMemberInvite?: (members: UserModel[]) => void;
}

const TeamsMemberInviteModal = ({ isOpen, onClose, onMemberInvite }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inviteActions = useActions({ fetchMembersForSelect, addMembersToTeam });
  const { error } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );
  const [selectedMembers, setSelectedMembers] = useState<UserModel[]>([]);
  const [membersList, setMembersList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [query] = useState<{
    page: number;
    perPage: number;
    userName?: string;
  }>({
    page,
    perPage: 20,
  });
  const { teamId } = useParams<{ teamId: string }>();

  const fetchMembers = useCallback(
    ({ page, userName }: { page?: number; userName?: string }) => {
      let params = { ...query };

      if (userName?.length > 2) {
        params = { ...params, userName };
      }

      setLoading(true);
      inviteActions
        .fetchMembersForSelect(teamId, params)
        .then((res: UserModel[]) => {
          setMembersList((prevList) =>
            page > 1 ? [...prevList, ...res] : res,
          );
          setPage((prevPage) => prevPage + 1);
          setHasMore(res.length >= query.perPage);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [teamId, query],
  );

  const debouncedSearch = useDebounce(searchValue, 300);

  const onSearchChange = useCallback((search?: string) => {
    setSearchValue(search);
  }, []);

  useEffect(() => {
    setSelectedMembers([]);
    fetchMembers({ page: 1, userName: debouncedSearch });

    return () => {
      setMembersList([]);
    };
  }, [debouncedSearch]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: () => fetchMembers({ page, userName: debouncedSearch }),
    disabled: false,
    rootMargin: "0px 0px 0px 0px",
  });

  const onSelectHandler = useCallback(
    (key: string, value: boolean) => {
      setSelectedMembers((prev) => {
        if (value) {
          const selected = membersList.find((m: UserModel) => m.id === key);
          if (selected && !prev.some((m) => m.id === key)) {
            return [...prev, selected];
          }
        } else {
          return prev.filter((m) => m.id !== key);
        }
        return prev;
      });
    },
    [membersList],
  );

  const onSubmitHandler = useCallback(() => {
    if (selectedMembers.length === 0) {
      return;
    }

    inviteActions
      .addMembersToTeam(
        teamId,
        selectedMembers.map((m) => m.id),
      )
      .then((res) => {
        if (onMemberInvite) {
          onMemberInvite(res);
        }
        onClose();
      });
  }, [selectedMembers, teamId, inviteActions, onClose, onMemberInvite]);

  const onInviteGroupMembers = () => {
    dispatch(inviteModalType("invite-members"));
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Add Members to Channel")}
    >
      <div className={styles.container}>
        <div className={styles.content_wrapper}>
          <SearchBar
            className={styles.search_input}
            placeholder={t("Search Members")}
            onChange={onSearchChange}
          />
          <MembersList
            membersList={membersList}
            onSelectHandler={onSelectHandler}
            selectedMembers={selectedMembers}
            sentryRef={sentryRef}
          />
          {membersList?.length === 0 && !loading && (
            <div className={styles.null_list_container}>
              {error?.originalErrors ? (
                <span className={styles.error}>
                  {t("Something went wrong")}
                </span>
              ) : (
                <span>{t("No members found")}</span>
              )}
              <Button
                title={t("Invite Members to Group")}
                buttonType="primary"
                onClickFunc={onInviteGroupMembers}
              />
            </div>
          )}
          {loading && (
            <Loading
              loadType="spinner"
              isLoading={loading}
              className={styles.loading_container}
            />
          )}
        </div>
        <div className={styles.button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Skip")}
            onClickFunc={onClose}
          />
          <Button
            buttonType="primary"
            title={t("Add Selected")}
            onClickFunc={onSubmitHandler}
            disabled={selectedMembers?.length === 0}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default TeamsMemberInviteModal;
