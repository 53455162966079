import styles from "./styles.module.scss";
import ErrorTippy from "../ErrorTippy";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import React from "react";
import ToolTip from "app/routes/Scheduling/ToolTip";

const FormInput = ({
  tagTitle,
  fieldName,
  image = null,
  defaultValue = null,
  isRequired = false,
  shouldCheck = false,
  regex = null,
  isEditable = true,
  highField = false,
  checkMessage = null,
  error = null,
  hideLabel = false,
  longField = false,
  addDollar = false,
  placeholder = null,
  isNumberOnly = null,
  tooltipText = null,
  tooltipColor = null,
  currency = { currency: "" },
  maxLength = null,
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const registerProps = isRequired
    ? shouldCheck
      ? register(fieldName, {
          required: t("This field is required"),
          pattern: {
            value: regex,
            message: checkMessage,
          },
        })
      : register(fieldName, { required: t("This field is required") })
    : register(fieldName);

  const inputStyling = isEditable
    ? addDollar
      ? styles.input_with_dollar
      : styles.input_container
    : styles.input_container_not_editable;
  const required_star = <span className={styles.required}>*</span>;
  const required_dollar = addDollar ? <span>{currency?.currency}</span> : null;

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9.\b]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.grow1}>
      {!hideLabel && (
        <div className={styles.select_container}>
          <p
            className={`${styles.tag} ${
              highField ? styles.high_field_tag : ""
            }`}
          >
            {tagTitle}
          </p>
          <p
            className={`${styles.tag} ${
              highField ? styles.high_field_tag : ""
            }`}
          >
            {isRequired ? required_star : null}
          </p>
          {tooltipText && (
            <ToolTip text={tooltipText} iconColor={tooltipColor} />
          )}
        </div>
      )}
      <div
        className={`${
          error
            ? styles.error_line_container
            : addDollar
            ? styles.dollar_line_container
            : styles.line_container
        } ${highField ? styles.p_13_8 : styles.p_10} `}
      >
        {required_dollar}
        <ErrorTippy placement="top-end" errors={errors} field_name={fieldName}>
          <input
            defaultValue={defaultValue}
            data-cy={fieldName}
            data-testid={fieldName}
            placeholder={placeholder}
            className={`${inputStyling} ${image ? styles.ml_10 : ""} ${
              longField ? styles.long_field : ""
            } ${addDollar ? styles.input_with_dollar : ""}`}
            {...registerProps}
            readOnly={!isEditable}
            onKeyPress={addDollar || isNumberOnly ? handleKeyPress : null}
            maxLength={maxLength || undefined}
          />
        </ErrorTippy>
      </div>
    </div>
  );
};

export default FormInput;
