/**
 * @module Reducer
 *
 */
import { combineReducers } from "redux";
import user from "./user";
import userSettings from "./user-settings";
import coachNowcoachProfile from "./coachNowCoachProfile";
import coupon from "./coupon";
import connection from "./connection";
import demoSpace from "./DemoSpace";
import invitation from "./invitation";
import list from "./list";
import paymentInfo from "./payment-info";
import space from "./space";
import sports from "./sports";
import stripe from "./stripe";
import subscription from "./subscription";
import subscriptionPlan from "./subscription-plan";
import tag from "./tag";
import team from "./team";
import teamGroup from "./team-group";
import teamGroupMemberImport from "./team-group-member-import";
import views from "./views";
import currentLikes from "./currentLikes";
import mediaLibraryData from "./media-library-data";
import destinations from "./destinations";
import copy from "./copy";
import move from "./move";
import segments from "./segments";
import onboarding from "./onboarding";
import schedulePost from "./schedule";
import applyTemplate from "./applyTemplate";
import smartList from "./smartList";
import spaceApplication from "./spaceApplication";
import addToRecruitPost from "./addToRecruitPost";
import postingForm from "./postingForm";
import posts from "./posts";
import filter from "./filter";
import timezone from "./timezone";
import createSpace from "./spaceCreation";
import groups from "./groups";
import notification from "./notification";
import spaceTemplate from "./spaceTemplate";
import search from "./search";
import organizations from "./organizations";
import searchAll from "./searchAll";
import alerts from "./alert";
import events from "./scheduling/events";
import coachProfile from "./scheduling/coachProfile";
import bookSession from "./scheduling/bookSession";
import program from "./scheduling/program";
import timeZone from "./scheduling/timeZone";
import studentProfile from "./scheduling/studentProfile";
import programRegistration from "./scheduling/programRegistration";

export const rootReducer = combineReducers({
  user,
  userSettings,
  coachNowcoachProfile,
  coupon,
  connection,
  demoSpace,
  invitation,
  list,
  paymentInfo,
  space,
  createSpace,
  spaceApplication,
  groups,
  spaceTemplate,
  sports,
  stripe,
  subscription,
  subscriptionPlan,
  tag,
  team,
  teamGroup,
  teamGroupMemberImport,
  views,
  currentLikes,
  mediaLibraryData,
  destinations,
  copy,
  move,
  segments,
  onboarding,
  schedulePost,
  applyTemplate,
  smartList,
  addToRecruitPost,
  postingForm,
  posts,
  filter,
  timezone,
  notification,
  search,
  organizations,
  searchAll,
  alerts,
  // Scheduling
  events,
  coachProfile,
  studentProfile,
  bookSession,
  program,
  schedulingTimeZones: timeZone,
  programRegistration,
});

export type RootState = ReturnType<typeof rootReducer>;
