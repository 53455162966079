import React, { useEffect } from "react";
import PagePanel from "app/components/PagePanel";
import styles from "./styles.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import CopyField from "../CopyField";
import Navbar from "../Navbar";
import ReturnBar from "../Navbar/ReturnBar";
import { useActions, useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import { fetchCoachProfile } from "app/actions/scheduling/coachProfile";
import { Loading } from "app/components/Wrappers";
import { useGoogleLogin } from "@react-oauth/google";
import {
  schedulingFetchGet,
  schedulingFetchPatch,
  schedulingFetchPost,
} from "../../../utils/request/scheduling/fetch";
import CalendarIntegrationModal from "../../../components/modals/CalendarIntegrationModal";
import { useTranslation } from "react-i18next";
import { icalUrlRegex } from "app/routes/Calendar/EventForm/constants";
import SwitchToggle from "../../../components/OptionButtonGroup/SwitchToggle";
import {default as SwitchTogglePublic} from "../SwitchToggle";
import { toast } from 'react-toastify';
import {useHistory} from "react-router-dom";

interface FormValues {
  calendarUrl: string;
  publicityCalendarEnabled: boolean;
  publicityCalendarUrl: string;
  calendarSyncEnabled: boolean;
}

export default function CalendarSettings() {
  const [calendarSync, setCalendarSync] = React.useState(false);
  const [calendars, setCalendars] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedCalendars, setSelectedCalendars] = React.useState([]);
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [iCalUrl, setICalUrl] = React.useState("");
  const [iCalIntegration, setICalIntegration] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const onSuccess = (res: any) => {
    const params = {
      integration: {
        auth_code: res.code,
        name: "google",
        action: "activate",
      },
    };

    schedulingFetchPost(`/coach_profiles/${userId}/integrations`, params).then(
      (res) => {
        setCalendars(res.integration.authInfo.calendars);
        setModal(true);
      }
    );
  };

  const login = useGoogleLogin({
    onSuccess,
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events.owned.readonly",
  });

  const { register, watch, setValue, handleSubmit  } = useForm({
    defaultValues: {
      calendarUrl: "https://example.com/calendar.ics",
      publicityCalendarEnabled: false,
      publicityCalendarUrl: "https://example.com/public-calendar.ics",
      calendarSyncEnabled: false,
    },
  });

  const coachProfile = useSelector((state: RootState) => state.coachProfile);
  const userId: any = useSelector((state: RootState) => state.user.self.id);
  const coachProfileActions = useActions({
    fetchCoachProfile,
  });

  const coachIntegrations = (coachId: number) => {
    return schedulingFetchGet(`/coach_profiles/${coachId}/integrations`).then(
      (integrations) => {
        return integrations;
      }
    );
  };

  useEffect(() => {
    if (coachProfile.data === null) {
      coachProfileActions.fetchCoachProfile(userId);
    }

    coachIntegrations(userId).then((integrations) => {
      integrations.forEach((integration: any) => {
        if (integration.name === "google" && integration.status === "active") {
          setCalendarSync(true);
        } else if (
          integration.name === "ical" &&
          integration.status === "active"
        ) {
          setICalIntegration(true);
          setICalUrl(integration.userIdentifier);
        }
      });
    });
  }, []);

  useEffect(() => {
    schedulingFetchGet(`coach_profiles/${userId}`)
      .then((data) => {
        setValue('publicityCalendarEnabled', data['publicCalendar']);
        setValue('publicityCalendarUrl', data['publicCalendarUrl']);
      }).catch((error) => {
      console.error(error);
    });
  }, []);

  if(coachProfile.error) {
    toast.error("You don’t have permission to access this page");
    history.push('/');
    return null;
  }

  if (coachProfile.pending) {
    return (
      <Loading loadType="spinner" isLoading className={styles.loadingSpinner} />
    );
  }

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
  };
  const isCalendarPublic = watch("publicityCalendarEnabled");

  const handlePublicCalendarChange = async () => {
    const data = {public_calendar:  !isCalendarPublic};
    schedulingFetchPatch(`coach_profiles/${userId}/calendar_settings`, data)
      .then((_) => {
        toast.success(t('Update successful.'));
      }).catch((_) => {
        toast.error(t('An unexpected error has occurred.'));
    });

  };
  const removeIntegration = () => {
    const params = {
      integration: {
        auth_code: "mock_code",
        name: "google",
        action: "inactivate",
      },
    };

    schedulingFetchPost(`/coach_profiles/${userId}/integrations`, params).then(
      () => {
        setCalendarSync(false);
        setLoading(false);
        setModal(false);
        setSelectedCalendars([]);
        setIsPrivate(false);
      }
    );
  };

  const handleSync = () => {
    const params = {
      coach_integration: {
        calendar_ids: selectedCalendars,
        is_private: isPrivate,
      },
    };

    schedulingFetchPatch(`coach_profiles/${userId}/integrations`, params).then(
      () => {
        setLoading(true);
        setCalendarSync(true);
      }
    );
  };

  const handleIcalConnect = () => {
    const params = {
      integration: {
        user_identifier: iCalUrl,
        name: "ical",
        action: "activate",
      },
    };

    schedulingFetchPost(`coach_profiles/${userId}/integrations`, params).then(
      () => {
        setLoading(true);
        setModal(true);
        setICalIntegration(true);
      }
    );
  };

  const handleIcalDisconnect = () => {
    const params = {
      integration: {
        user_identifier: iCalUrl,
        name: "ical",
        action: "inactivate",
      },
    };

    schedulingFetchPost(`/coach_profiles/${userId}/integrations`, params).then(
      () => {
        setICalIntegration(false);
      }
    );
  };

  const nextDisabled = selectedCalendars.length === 0;

  const buttons = (
    <div className={styles.buttons_container}>
      <button className={styles.cancelButton} onClick={removeIntegration}>
        {t("Cancel")}
      </button>
      <button
        style={{
          cursor: nextDisabled ? "not-allowed" : "pointer",
          backgroundColor: nextDisabled ? "#c2c8d8" : "var(--brand-color)",
        }}
        disabled={nextDisabled}
        className={
          selectedCalendars.length === 0
            ? styles.next_enabled
            : styles.next_disabled
        }
        onClick={handleSync}
      >
        {t("Next")}
      </button>
    </div>
  );

  const handleCheckboxChange = (calendarId: string) => {
    const isSelected = selectedCalendars.includes(calendarId);
    if (isSelected) {
      setSelectedCalendars(selectedCalendars.filter((id) => id !== calendarId));
    } else {
      setSelectedCalendars([...selectedCalendars, calendarId]);
    }
  };

  const extendCheckbox = (label: string, value: string) => {
    return (
      <label
        key={label}
        className={styles.wrapper}
        onClick={() => handleCheckboxChange(value)}
      >
        {label}
        <input
          type="checkbox"
          style={{ cursor: "pointer" }}
          onClick={(e) => e.stopPropagation()}
        />
        <span className={styles.checkmark}></span>
      </label>
    );
  };

  const resetForm = () => {
    setLoading(false);
    setModal(false);
    setSelectedCalendars([]);
    setIsPrivate(false);
  };

  const loadingContent = () => (
    <div className={styles.loading_container}>
      <div className={styles.loading_content_container}>
        <div className={styles.loading_text}>
          <span className={styles.loading_title}>
            {t("Your calendar sync is in progress")}
          </span>
          <span className={styles.loading_subtitle}>
            {t(
              "It may take a short while until you can see all appointments in the calendar."
            )}
          </span>
        </div>
      </div>
      <button className={styles.loading_button} onClick={resetForm}>
        {t("Ok, got It")}
      </button>
    </div>
  );

  const modalContent = () => (
    <div className={styles.content_container}>
      <div className={styles.content_title}>{t("Import Preferences")}</div>
      <div className={styles.content_text}>
        {t(
          "External calendar appointments will be imported into your calendar, making these times unavailable for bookings."
        )}
      </div>
      <SwitchToggle
        checked={isPrivate}
        onChange={() => setIsPrivate(!isPrivate)}
        title=""
        customText={t(
          "Keep my external calendar private. Make Google Calendar event information visible to me only."
        )}
      />
      <div className={styles.modalHorizontalLine} />
      <div className={styles.option_title}>{t("Synced Calendars")}</div>
      <div className={styles.calendar_list_container}>
        <div className={styles.calendar_options_container}>
          <div className={styles.option_text_container}>
            {t("Select at least one of your calendars.")}
          </div>
          <div className={styles.checkbox_container}>
            {calendars.map((calendar) =>
              extendCheckbox(calendar.summary, calendar.id)
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const isIcalDisabled = (iCalUrl && !icalUrlRegex.test(iCalUrl)) || !iCalUrl || (iCalUrl.trim() === coachProfile.data.calendarSubscriptionUrl.trim());

  return (
    <>
      <ReturnBar />
      <div className={styles.container}>
        <Navbar location={"calendar"} />
        <div className={styles.insideContainer}>
          <PagePanel title={t("Calendar Integrations")}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.inputContainer}>
                <label htmlFor="calendarUrl" className={styles.labelStyle}>
                  {t("Subscribe to your CoachNow Calendar")}
                </label>
                <div style={{ marginBottom: "20px", marginTop: "10px" }}>
                  <div className={styles.customText}>
                    {t(
                      "Copy the URL so you can paste it in your preferred calendar app."
                    )}
                  </div>
                  <div className={styles.customText}>
                    <strong className={styles.boldText}>Sessions</strong> and{" "}
                    <strong className={styles.boldText}>
                      unavailable times
                    </strong>{" "}
                    will be synced with your preferred calendar.
                  </div>
                </div>

                <CopyField value={coachProfile.data.calendarSubscriptionUrl} />

                <div className={styles.horizontalLine} />
                <div className={styles.calendarInfoContainer}>
                  <div className={styles.infoTextContainer}>
                    <label htmlFor="calendarUrl" className={styles.labelStyle}>
                      {t("Connect External Calendar")}
                    </label>

                    <div className={styles.connectButtonContainer}>
                      <div className={styles.connectCalendarContainer}>
                        {t(
                          "Connect your external calendar to enable the sync. You can change this selection at any time. Event details will not be displayed to other users."
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.infoTextContainer}>
                    <label htmlFor="calendarUrl" className={styles.labelStyle}>
                      {t("Google Calendar")}
                    </label>

                    <div className={styles.connectButtonContainer}>
                      <div className={styles.calendarTextContainer}>
                        {t(
                          "Connect your Google Calendar to sync events, prevent double booking, and manage your workday from one central hub."
                        )}
                      </div>
                      <button
                        type="button"
                        className={
                          calendarSync
                            ? styles.disconnectButton
                            : styles.connectButton
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          calendarSync ? removeIntegration() : login();
                        }}
                      >
                        {calendarSync ? t("Disconnect") : t("Connect")}
                      </button>
                    </div>
                    <div className={styles.customTextSmall}>
                      {
                        "CoachNow use and transfer to any other app of information received from Google APIs will adhere to "
                      }
                      <a href="https://developers.google.com/terms/api-services-user-data-policy"
                        target="_blank"
                        rel="noopener noreferrer">
                        {"Google APIs Services User Data Policy"}
                      </a>
                      {", including the Limited Use requirements."}
                    </div>
                    {modal && (
                      <CalendarIntegrationModal
                        buttons={loading ? null : buttons}
                        showModal={removeIntegration}
                        closeIcon={!loading}
                      >
                        {loading ? loadingContent() : modalContent()}
                      </CalendarIntegrationModal>
                    )}
                  </div>

                  <div className={styles.infoTextContainer}>
                    <label htmlFor="calendarUrl" className={styles.labelStyle}>
                      {t("iCal URL")}
                    </label>

                    <div className={styles.iCalTextContainer}>
                      {t(
                        "Connect your iCalendar to sync events, prevent double booking, and manage your workday from one central hub."
                      )}
                    </div>
                    <div className={styles.iCalInputContainer}>
                      <div className={styles.iCalInput}>
                        <input
                          placeholder={t("Insert iCal URL")}
                          value={iCalUrl}
                          disabled={iCalIntegration}
                          onChange={(e) => setICalUrl(e.target.value)}
                        />
                      </div>
                      {!iCalIntegration && (
                        <button
                          type="button"
                          disabled={isIcalDisabled}
                          className={
                            isIcalDisabled
                              ? styles.connectButtonDisabled
                              : styles.connectButton
                          }
                          onClick={handleIcalConnect}
                        >
                          {t("Connect")}
                        </button>
                      )}

                      {iCalIntegration && (
                        <button
                          type="button"
                          className={styles.disconnectButton}
                          onClick={handleIcalDisconnect}
                        >
                          {t("Disconnect")}
                        </button>
                      )}

                      {iCalIntegration && (
                        <button
                          type="button"
                          className={styles.disconnectButton}
                          onClick={handleIcalConnect}
                        >
                          {t("Refetch")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.horizontalLine} />
            <label htmlFor="publicityCalendar" className={styles.labelStyle}>
              {t('Publicly Available Calendar')}
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              <div className={styles.customText}>
                {t(' Copy the URL, so you can link it from your website. ')}
              </div>
              <div className={styles.customText}>
                {t('Anyone will be able to view your availability and book sessions.')}
              </div>
            </div>
            <div className={styles.toggleContainerRows}>
              <SwitchTogglePublic
                defaultChecked={watch("publicityCalendarEnabled")}
                title={t('Enable Public Calendar')}
                register={register}
                name="publicityCalendarEnabled"
                setValue={setValue}
                defaultValue={false}
               onChange={handlePublicCalendarChange}
              />
              {isCalendarPublic && (
                <CopyField
                  value={watch("publicityCalendarUrl")}
                />
              )}
            </div>
             {/*
            <div className={styles.horizontalLine} />
            <label htmlFor="externalCalendar" className={styles.labelStyle}>
              Connect External Calendar
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px", maxWidth: '550px' }}>
              <div className={styles.customText}>
                Copy the URL, so you can link it from your website. You can change this selection at any time.
                <br />
                To learn more about calendar sync, visit our{" "}
                <a href="https://support.google.com/calendar" className={styles.link}>
                  Google
                </a>{" "}
                help articles.
              </div>
            </div>
            <div className={styles.horizontalLine} />
            <label htmlFor="calendarSync" className={styles.labelStyle}>
              Enable Calendar Sync
            </label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              <div className={styles.customText}>
                Enable this option to sync your calendar with external services.
              </div>
            </div>
            <div className={styles.toggleContainer}>
              <SwitchToggle
                defaultChecked={false}
                title="Enable calendar sync"
                register={register}
                name="calendarSyncEnabled"
                setValue={setValue}
                defaultValue={false}
              />
            </div> */}
                {/* <button type="submit" className={styles.submitButton}>
              Save changes
            </button> */}
              </div>
            </form>
          </PagePanel>
        </div>
      </div>
    </>
  );
}
