import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styles from "./styles.module.scss";
import Navbar from "app/routes/Facility/Navbar";
import PagePanel from "app/components/PagePanel";
import FacilityItem from "app/routes/Facility/FacilityItem";
import {fetchGet, fetchPut} from "app/utils/request";
import {toast} from "react-toastify";
import FacilityPill from './FacilityPill';
import useCurrentFacility from 'app/utils/hooks/scheduling/useCurrentFacility';
import { schedulingFetchGet, schedulingFetchPatch } from 'app/utils/request/scheduling/fetch';
import { setSchedulingData } from 'app/actions/user';
import { FacilityModel, FacilityResponse } from 'app/models/scheduling/FacilityModel';
import { useDispatch } from 'react-redux';
import TogglePill from './TogglePill';
import LogoContainer from './LogoContainer';
import RoundButton from 'app/components/Programs/Buttons/RoundButton';
import PreviewEmail from './PreviewEmail';

const Facility = () => {
  const { t } = useTranslation();
  const [facilities, setFacilities] = useState([]);
  const currentFacility = useCurrentFacility();
  const [previewBody, setPreviewBody] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchGet("/facilities", {}, {version: 1})
      .then((response) => {
      setFacilities(response);
      }).catch((error) => {
        console.error(error);
      });
  }, []);

   const handlePublicCalendarChange = async (field_name, field_value) => {
     const data = {
       public_field_name: field_name,
       public_field_value: field_value,
     };
     schedulingFetchPatch(
       `facilities/${currentFacility.data?.id}/update_public_field`,
       data
     )
       .then(async (response: FacilityResponse) => {
         dispatch(setSchedulingData(new FacilityModel(response)));
         toast.success(t("Update successful."));
       })
       .catch((_) => {
         toast.error(t("An unexpected error has occurred."));
       });
   };

   const getEmail = () => {
    schedulingFetchGet(`facilities/${currentFacility.data?.id}/email_preview`).then(
      (response) => {
        setPreviewBody(response.preview);
      }
    );
   };

   const updateFacilityLogo = (url) => {
    const data = {
      logo_url: url,
    };
     schedulingFetchPatch(
       `facilities/${currentFacility.data?.id}/update_logo`,
       data
     ).then(async (response: FacilityResponse) => {
        dispatch(setSchedulingData(new FacilityModel(response)));
        toast.success(t("Update successful."));
     });
   };

  const handleEditFacility = (facility) => {
    fetchPut(`/facilities/${facility.id}`, { facility }, {version: 1})
      .then(() => {
        toast.success("Facility updated successfully");
        setFacilities((prevFacilities) =>
          prevFacilities.map((f) =>
            f.id === facility.id ? facility : f
          )
        );
      }).catch((error) => {
        toast.error("Failed to update facility");
        console.error(error);
      });
  };

  return (
    <div className="calendar-page-container">
      <div className={styles.title}>
        <h1>{t("Admin Settings")}</h1>
      </div>
      <div>
        <Navbar location={"general"} />
        <PagePanel title={t("General")} titleStyles={styles.header}>
          {facilities.map((facility) => (
            <div className={styles.row} key={facility.id}>
              <FacilityItem facility={facility} onEdit={handleEditFacility} />
              <FacilityPill
                header={t("TM Master Roster Sync")}
                categories={[
                  {
                    title: t("TM Master Roster Sync Enabled"),
                    text: facility.tm_api_key ? t("Yes") : t("No"),
                  },
                  {
                    title: t("Last Master Roster Sync"),
                    text: facility.tm_master_roster_synced_at || t("N/A"),
                  },
                ]}
              />
              <TogglePill
                onChange={(value) =>
                  handlePublicCalendarChange("public_calendar", value)
                }
                enabled={currentFacility?.data?.publicCalendar}
                inputText={currentFacility?.data?.publicCalendarURL}
                title={t("Calendar Settings")}
                inputLabel={t("Enable Public Calendar")}
                toolTipText={[
                  t("Copy the URL, so you can link to it from your website."),
                  t(
                    "Anyone who accesses the link will see the availability of all coaches and be able to book sessions."
                  ),
                ]}
              />
              <TogglePill
                onChange={(value) =>
                  handlePublicCalendarChange("public_programs", value)
                }
                enabled={currentFacility?.data?.publicPrograms}
                inputText={currentFacility?.data?.publicProgramsURL}
                titleToolTipText={[
                  t(
                    "Public programs allow athletes who are not already connected to your facility to register."
                  ),
                  t(
                    "Public programs will also be listed on the Public Programs page, if enabled."
                  ),
                ]}
                title={t("Public Programs")}
                inputLabel={t("Enable Public List of Programs")}
                toolTipText={[
                  t("Copy the URL, so you can link to it from your website."),
                  t(
                    "Anyone who accesses the link will see the list of programs marked as public, view the details of those programs and register."
                  ),
                ]}
              />
              <LogoContainer
                title={t("Email Branding")}
                label={t(
                  "Add your logo image to be included in email communications to your athletes."
                )}
                footer={t(
                  "The logo must have a height of at least 80 pixels and no more than 150 pixels."
                )}
                imageUrl={currentFacility?.data?.logo_url}
                setImageUrl={updateFacilityLogo}
              >
                <RoundButton
                  customStyle={styles.preview}
                  onClick={() => getEmail()}
                >
                  <p className={styles.preview_text}>{t("Preview Email")}</p>
                </RoundButton>
              </LogoContainer>
            </div>
          ))}
        </PagePanel>
      </div>
      {previewBody &&
       <PreviewEmail email={previewBody} closePreview={() => setPreviewBody(null)}/>
      }
    </div>
  );
};

export default Facility;
