import React, { useCallback, useState } from "react";
import { useActions, useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import plus_circle from "app/images/plus_circle.svg";
import SegmentsPaywall from "app/components/paywalls/Segments";
import { UpgradeToPro } from "app/components/paywalls";
import SegmentList from "./_components/SegmentList";
import CoachHomeLayout from "app/components/Layouts/CoachHomeLayout";
import CreateSegmentModal from "./_components/CreateSegmentModal";
import { clearList, fetchLists } from "app/actions/list";
import { usePagination } from "app/utils/hooks/usePagination";
import useDebounce from "app/utils/hooks/useDebounce";
import SearchBar from "app/components/inputs/SearchBar";
import { useTranslation } from "react-i18next";

const Segments = () => {
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user);
  const segmentActions = useActions({ fetchLists, clearList });
  const canCreateSegments = self?.planPermissions?.includes("create_segments");

  const [searchTerm, setSearch] = useState<string>("");
  const [showModal, setPaywallModalOpen] = useState(false);
  const [newListModalOpen, setNewListModalOpen] = useState<boolean>(false);

  const debouncedSearch = useDebounce(searchTerm, 500);

  const onSearchTeams = useCallback((value: string) => {
    if (value != null && value.length > 0) {
      setSearch(value);
    } else {
      setSearch("");
    }
  }, []);

  const { data, pending, sentryRef } = usePagination({
    initialParams: {
      page: 1,
      perPage: 20,
      name: searchTerm,
    },
    fetchData: (params, signal) => segmentActions.fetchLists(params, signal),
    clearDataAction: segmentActions.clearList,
    dependencies: [debouncedSearch],
  });

  const modalHandler = () => {
    setNewListModalOpen((prev) => !prev);
  };

  const onNewListClick = () => {
    if (canCreateSegments) {
      modalHandler();
    } else {
      onSegmentPaywallOpen();
    }
  };

  const onSegmentPaywallOpen = useCallback(() => {
    setPaywallModalOpen(true);
  }, []);

  const onSegmentPaywallClose = useCallback(() => {
    setPaywallModalOpen(false);
  }, []);

  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const onUpgrade = () => {
    setShowPaywallModal(true);
  };

  const closePaywall = useCallback(() => {
    setShowPaywallModal(false);
  }, []);

  return (
    <CoachHomeLayout>
      <div className={styles.header_wrapper}>
        <SearchBar
          className={styles.search}
          placeholder={t("Search...")}
          onChange={onSearchTeams}
        />
        <button className={styles.add_button} onClick={onNewListClick}>
          <img src={plus_circle} alt="add new list" />
          <p>{t("Add new list")}</p>
        </button>
      </div>
      <SegmentList data={data} pending={pending} containerRef={sentryRef} />
      <CreateSegmentModal
        isOpen={newListModalOpen}
        modalHandler={modalHandler}
        onUpgrade={onUpgrade}
      />
      <SegmentsPaywall show={showModal} onClose={onSegmentPaywallClose} />
      <UpgradeToPro show={showPaywallModal} onClose={closePaywall} />
    </CoachHomeLayout>
  );
};

export default Segments;
