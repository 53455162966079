import style from "./styles.module.scss";
import React from "react";

import { components } from "react-select";
import UserAvatar from "../UserAvatar";
import { SelectStyles } from "app/routes/Calendar/EventForm/constants";
import AsyncPaginate from "react-select-async-paginate";

const UserSelector = ({
  hasInvite = false,
  onInviteClick = () => null,
  hasArrow = false,
  values,
  placeholder,
  selectedUsers,
  setValues,
  singleValue = false,
  value = null,
  showEmail = true,
  paginate = false,
}) => {
  const user_avatar = (data) => {
    return (
      <UserAvatar
        src={data.profilePicture}
        height={40}
        width={40}
        font_size={12}
        first_name={data.firstName}
        last_name={data.lastName}
      />
    );
  };

  const CustomOption = ({ innerProps, isDisabled, data }) => {
    return !isDisabled ? (
      <div
        {...innerProps}
        className={style.option_container}
        data-cy="student-option"
      >
        {user_avatar(data)}
        <div className={style.option_info}>
          <p className={style.option_name}>
            {data.firstName} {data.lastName}
          </p>
          {showEmail && <p className={style.option_email}>{data.email}</p>}
        </div>
      </div>
    ) : null;
  };

  const CustomMenu = (props) => {
    return (
      <components.Menu {...props}>
        {props.children}
        {hasInvite && (
          <div
            className={style.list_add_button}
            onClick={() => {
              onInviteClick();
            }}
          >
            <span
              data-testid="dropdown-button"
              className={style.list_add_button_text}
            >
              Invite New Student
            </span>
          </div>
        )}
      </components.Menu>
    );
  };

  const CustomIndicator = (props) => {
    return <components.IndicatorsContainer {...props} />;
  };

  const addParticipant = (data) => {
    if (singleValue) {
      setValues(data);
      return true;
    }
    if (selectedUsers.some((item) => item.id === data.id)) {
      return null;
    }
    return setValues([...selectedUsers, data]);
  };

  const handleChange = (selection) => {
    addParticipant(selection);
  };

  const staticLoadOptions = async (inputValue: string) => {
    const filteredValues = values.filter((option) => {
      return (
        option.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.lastName.toLowerCase().includes(inputValue.toLowerCase()) ||
        (showEmail && option.email.toLowerCase().includes(inputValue.toLowerCase()))
      );
    });

    return {
      options: filteredValues,
      hasMore: false,
    };
  };

  const inviteInput = (
    <div className={style.input_full_container} data-cy="invite-student-input">
      <AsyncPaginate
        styles={{
          ...SelectStyles,
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        components={{
          IndicatorSeparator: () => null,
          IndicatorsContainer: hasArrow ? CustomIndicator : () => null,
          Option: CustomOption,
          Menu: CustomMenu,
        }}
        loadOptions={paginate ? values : staticLoadOptions}
        value={singleValue ? value : null}
        onChange={handleChange}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        menuPlacement="auto"
      />
    </div>
  );

  return <div className={`${style.container} `}>{inviteInput}</div>;
};

export default UserSelector;
