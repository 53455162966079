import { useTranslation } from "react-i18next";
import styles from "../styles.module.scss";
import React from "react";

export default function LoadingPlaceholder() {
  const { t } = useTranslation();
  return (
    <div data-testid="loading-placeholder">
      <p className={styles.uploading_text}>{t('Uploading...')}</p>
    </div>
  );
}
