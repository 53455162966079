import React, { memo } from "react";
import styles from "./styles.module.scss";
import { Props } from "./index.types";
import { cls } from "app/utils";
import LoadingDots from "app/components/Loading/Dots";

const Button = ({
  onClickFunc,
  title,
  buttonType,
  buttonStyles,
  childStyles,
  icon,
  disabled,
  loading,
  ...rest
}: Props) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={cls("btn", styles.button, buttonStyles, {
        ["btn-primary"]: buttonType === "primary",
        ["btn-secondary"]: buttonType === "secondary",
        [styles.secondary_filled]: buttonType === "secondary-filled",
        ["btn-outline-secondary"]: buttonType === "secondary-outline",
        ["btn-danger"]: buttonType === "danger",
        ["btn-upgrade"]: buttonType === "upgrade",
        ["btn-success"]: buttonType === "success",
        ["btn btn-outline-secondary text-danger"]:
          buttonType === "secondary-outline-danger",
        [styles.disabled]: disabled || loading,
      })}
      onClick={(!loading || !disabled) && onClickFunc}
      {...rest}
    >
      <div className={cls(childStyles)}>
        {loading && <LoadingDots active />}
        <span
          className={cls({
            [styles.isLoading]: loading,
          })}
        >
          {icon && icon} {title}
        </span>
      </div>
    </button>
  );
};

export default memo(Button);
