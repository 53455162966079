import React from "react";
import styles from "./styles.module.scss";
import SpaceMemberModel from "app/models/SpaceMemberModel";

import { useTranslation } from "react-i18next";
import SpaceMemberCard from "app/components/SpaceMemberCard";
import UserModel from "app/models/UserModel";

interface Props {
  label?: string;
  members?: SpaceMemberModel[] | null;
  containerRef?: any;
  onMakeAthlete?: (memberId: string, member: SpaceMemberModel) => void;
  onRemoveUser?: (member: UserModel) => void;
  onCopyLink?: (member: SpaceMemberModel) => void;
  onEdit?: (memberId: string) => void;
  onResend?: (member: SpaceMemberModel) => void;
}

const SpaceMembersList = ({
  label,
  members,
  containerRef,
  onMakeAthlete,
  onRemoveUser,
  onCopyLink,
  onEdit,
  onResend,
}: Props) => {
  const { t } = useTranslation();
  if (members?.length === 0) {
    return (
      <div className={styles.not_found}>
        <h3>{t("Nothing Found")}</h3>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <div className={styles.members_wrapper}>
        {members.map((member) => (
          <div key={member.id} className={styles.grid}>
            <SpaceMemberCard
              member={member}
              onMakeAthlete={onMakeAthlete}
              onRemoveUser={onRemoveUser}
              onCopyLink={onCopyLink}
              onEdit={onEdit}
              onResend={onResend}
            />
          </div>
        ))}
        <div ref={containerRef} />
      </div>
    </div>
  );
};

export default SpaceMembersList;
