import React from "react";
import styles from "./styles.module.scss";
import ListModel from "app/models/ListModel";
import null_list from "app/images/null-lists.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MultiContentCard from "app/components/MultiContentCard";
import SpaceModel from "app/models/SpaceModel";
import { Loading } from "app/components/Wrappers";

interface IProps {
  data: ListModel[];
  pending?: boolean;
  containerRef?: any;
}

const SegmentList = ({ data, pending, containerRef }: IProps) => {
  const { t } = useTranslation();
  if (data?.length === 0 && pending) {
    return <Loading isLoading={pending} loadType={"spinner"} />;
  }

  if (data?.length === 0 && !pending) {
    return (
      <div className={styles.null_list}>
        <img src={null_list} alt="no list yet" />
        <h3>{t("No Lists yet")}</h3>
        <p>{t("All lists created by you will appear here")}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {data?.map((item: ListModel) => {
        const images =
          item?.spaces?.map((space: SpaceModel) => space.avatar)?.slice(-3) ||
          [];
        return (
          <div key={item?.id} className={styles.list_item_wrapper}>
            <Link to={`/lists/${item?.id}/spaces`}>
              <MultiContentCard
                images={images}
                title={item?.name}
                subtitle={t("count_space", { count: item?.spaceIds?.length })}
              />
            </Link>
          </div>
        );
      })}
      <div ref={containerRef} />
    </div>
  );
};

export default SegmentList;
