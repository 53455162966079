import React from "react";
import styles from "./FacilityItem/styles.module.scss";
import { useTranslation } from "react-i18next";

interface CategoryItem {
  title: string;
  text: string;
}

interface Props {
  header: string;
  categories: CategoryItem[];
  editable?: boolean;
  onEdit?: () => null;
}

const FacilityPill = ({
  header,
  categories,
  editable = false,
  onEdit = () => null,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.first_row}>
        <div className={styles.title}>{header}</div>
        {editable && (
          <div className={styles.button} onClick={onEdit}>
            {t("Edit")}
          </div>
        )}
      </div>
      {categories.map((item, index) => {
        return (
          <React.Fragment key={item.title}>
            <div className={styles.row} >
              <div className={styles.title}>{item.title}</div>
              <div className={styles.value}>{item.text}</div>
            </div>
            <div
              className={
                index + 1 !== categories.length ? styles.horizontal_line : ''
              }
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FacilityPill;
