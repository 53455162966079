import React, { memo } from "react";
import styles from "./styles.module.scss";
import UserModel from "app/models/UserModel";
import UserAvatar from "../UserAvatar";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface Props {
  user: UserModel;
  className?: string;
}

const CoachCard = ({ user, className }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onCoachCardClick = () => {
    // window.open(user?.coachProfile?.bookingServiceLink, "_blank").focus();
    history.push(`/home/${user?.id}`);
  };

  return (
    <div
      onClick={onCoachCardClick}
      className={cls(styles.container, className)}
    >
      <UserAvatar
        sizeHeight={61}
        sizeWidth={61}
        className={styles.avatar}
        srcName={user?.avatar as string}
        altName="coach avatar"
        userName={user?.displayName}
      />
      <p className={styles.user_name}>{user?.displayName}</p>
      <p className={styles.sport}>{user?.sport}</p>
      <div className={styles.book_session_btn}>
        {user?.coachProfile?.bookingServiceLink
          ? t("Book a session")
          : t("View Profile")}
      </div>
    </div>
  );
};

export default memo(CoachCard);
