/**
 * @module Actions.Post
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet, request } from "app/utils/request";
import PostModel from "app/models/PostModel";
import { PostsState } from "app/reducers/posts";
import config from "config/environment";
import { IPostView } from "app/utils/hooks/usePostViewsTracking";

const fetchPending = () => action("@post.fetch.pending");

const fetchError = (err: string) => action("@post.fetch.error", err);

const fetchSuccess = (posts: PostModel[]) =>
  action("@post.fetch.success", posts);

export const hasMore = (hasMore: boolean) =>
  action("@post.fetch.hasMore", hasMore);

const fetchReplyPending = () => action("@post.fetch.pendingReply");

const fetchReplyError = (err: string) => action("@post.fetch.errorReply", err);

const fetchReplySuccess = (replies: PostModel[]) =>
  action("@post.fetch.successReply", replies);

export const clearPost = () => action("@post.clear");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  hasMore,
  fetchReplyPending,
  fetchReplyError,
  fetchReplySuccess,
  clearPost,
};

export type PostsAction = ActionType<typeof thisActions>;

export function fetchPosts(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<PostsAction>) => {
    dispatch(fetchPending());

    return fetchGet<PostsAction>(
      "posts",
      params || {},
      {
        version: "2.1",
      },
      signal
    ).then((res: PostModel[] | any) => {
      if (res && Array.isArray(res)) {
        if (res?.length < (params?.perPage || params?.limit)) {
          dispatch(hasMore(false));
        }
        dispatch(fetchSuccess(res));
      }

      return res;
    });
  };
}

export function fetchScheduledPosts(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<PostsAction>, getState: () => PostsState) => {
    dispatch(fetchPending());

    // @ts-ignore
    const state = getState().posts;

    return fetchGet<PostsAction>(
      "posts/scheduled",
      params || {},
      {},
      signal
    ).then((res: PostModel[] | any) => {
      if (res && Array.isArray(res)) {
        if (res.length < (params && (params.perPage || params.limit))) {
          dispatch(hasMore(false));
        }
        dispatch(fetchSuccess(res));
      }

      return res;
    });
  };
}

export function fetchReplies(
  id: string,
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<PostsAction>) => {
    dispatch(fetchReplyPending());

    return fetchGet<PostsAction>(
      `posts/${id}/replies`,
      params || {},
      {},
      signal
    ).then((res: PostModel[] | any) => {
      if (res && Array.isArray(res)) {
        dispatch(fetchReplySuccess(res));
      }

      return res;
    });
  };
}

export function pinUnpinPost(postId: string, pinnedTill: null | string) {
  return () => {
    const url = config.API_SERVER + `/api/v2/posts/${postId}/toggle_pin`;
    const promise = request(url, "POST", { pinnedTill }, { version: 1 });

    return promise;
  };
}

// interface ReportPostResponse {
//   id: string;
//   message: string;
//   object?: string;
// }

export const reportPost = (postId: string, message: null | string) => {
  return async () => {
    const url = config.API_SERVER + `/api/v2/posts/${postId}/report`;
    try {
      const response = await request(
        url,
        "POST",
        { report: { message: message || "" } },
        { version: 1 }
      );

      return response;
    } catch (error) {
      console.error("Error reporting post:", error);
      throw error;
    }
  };
};

export function sendPostViews(postViews: Array<IPostView>) {
  return () => {
    const url = config.API_SERVER + `/api/v2/post_views`;
    const promise = request(url, "POST", { postViews }, { version: 1 });

    return promise;
  };
}

export function sendContentViews(model: PostModel) {
  return () => {
    const url = config.API_SERVER + `/api/v2/post_views`;
    const promise = request(
      url,
      "POST",
      { post_views: [model] },
      { version: 1 }
    );

    return promise;
  };
}

export function requestPostLocations(postId: string) {
  return () => {
    const url = config.API_SERVER + `/api/v2/posts/${postId}/posts`;
    const promise = request(url, "GET", {}, { version: 1 });

    return promise;
  };
}

export function fetchPostById(
  postId: string,
  params: { [key: string]: any } = {},
  signal?: AbortSignal
) {
  return async (dispatch: Dispatch<PostsAction>) => {
    dispatch(fetchPending());

    try {
      const res: PostModel = await fetchGet(
        `posts/${postId}`,
        params,
        {},
        signal
      );

      return res;
    } catch (err) {
      console.error("Error fetching post:", err);
      throw err;
    }
  };
}

export const actions = {
  fetchPosts,
};
