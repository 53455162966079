import React from "react";
import styles from "./styles.module.scss";
import nullGroup from "../../../../../public/images/null-groups.png";
import { useTranslation } from "react-i18next";

interface Props {
  searchResult?: [];
  searchedText?: string;
}

const NoGroupsResult: React.FC<Props> = ({
  searchResult,
  searchedText,
}: Props) => {
  const { t } = useTranslation();
  const noSearchedData = searchResult?.length === 0 && searchedText !== "";

  return (
    <div className={styles.container}>
      {searchResult?.length === 0 && searchedText === "" && (
        <img src={nullGroup} alt="null result" />
      )}
      <h3>{noSearchedData ? t("No Results") : t("No Groups yet")}</h3>
      <p>
        {noSearchedData
          ? t(
              `Nothing found for "{{text}}", please search for something else.`,
              {
                text: searchedText,
              }
            )
          : t("All your groups will appear here")}
      </p>
    </div>
  );
};

export default NoGroupsResult;
