/* eslint-disable no-console */
/**
 * @module Actions.Space
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  request,
} from "app/utils/request";
import { SpaceModel, SpaceQueryModel } from "app/models";
import { SpaceState } from "app/reducers/space";
import config from "config/environment";
import UserModel from "app/models/UserModel";

const fetchPending = () => action("@space.fetch.pending");
const fetchError = (err: string) => action("@space.fetch.error", err);

export const hasMoreSpaces = (hasMore: boolean) =>
  action("@space.fetch.hasMore", hasMore);

const fetchSuccess = (spaces: SpaceModel[]) =>
  action("@space.fetch.success", spaces);

export const spacePage = (page: number) =>
  action("@space.fetch.spacePage", page);

export const spaceQuery = (spaceQuery: SpaceQueryModel) =>
  action("@space.fetch.spaceQuery", spaceQuery);

export const newOwnerData = (userInfo: UserModel) =>
  action("@space.fetch.newOwner", userInfo);

export const setSelectedSpace = (space: SpaceModel) =>
  action("@space.fetch.selectedSpace", space);

export const setModalOpen = (modal: boolean) =>
  action("@space.fetch.isModalOpen", modal);

export const clearSpace = () => action("@space.clear");

export const clearSpaceForSearch = () => action("@space.clearSpaceForSearch");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  spaceQuery,
  spacePage,
  hasMoreSpaces,
  newOwnerData,
  setModalOpen,
  setSelectedSpace,
  clearSpace,
  clearSpaceForSearch,
};

export type SpaceAction = ActionType<typeof thisActions>;

export function fetchSpaces(
  params?: { [key: string]: any },
  signal?: AbortSignal
) {
  return (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());

    return fetchGet<SpaceAction>("spaces", params || {}, {}, signal).then(
      (res: SpaceModel[] | any) => {
        dispatch(fetchSuccess(res));
        return res;
      }
    );
  };
}

export function fetchSpaceById(
  id: string,
  params?: { [key: string]: any },
  signal?: AbortSignal
) {
  return (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());
    return fetchGet<SpaceAction>(`spaces/${id}`, params || {}, {}, signal).then(
      (res: SpaceModel[] | any) => {
        dispatch(setSelectedSpace(res));
        return res;
      }
    );
  };
}

export function putSpaceSettings(space?: { [key: string]: any }) {
  return (dispatch: Dispatch<SpaceAction>) => {
    const url = config.API_SERVER + `/api/v2/spaces/${space.id}`;
    const promise = request(url, "PUT", { space }, { version: 1 });
    promise.then((res) => dispatch(setSelectedSpace(res)));
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function deleteSpace(id: string) {
  return () => {
    const url = config.API_SERVER + `/api/v2/spaces/${id}`;
    const promise = request(url, "DELETE", {}, { version: 1 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function fetchSpaceMemberships(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return async (
    dispatch: Dispatch<SpaceAction>,
    getState: () => SpaceState
  ) => {
    // @ts-ignore
    const state = getState().space;
    dispatch(fetchPending());

    try {
      const res: SpaceModel[] | any = await fetchGet<SpaceAction>(
        "space_memberships",
        params || {},
        {},
        signal
      );

      if (res && Array.isArray(res)) {
        dispatch(fetchSuccess(res));
        dispatch(spacePage(state.page + 1));
        if (res.length < (params && params.force_per_page)) {
          dispatch(hasMoreSpaces(false));
        }
      }

      return res;
    } catch (error) {
      console.error("Error fetching space memberships:", error);
    }
  };
}

export function fetchSpaceMembers(
  spaceId?: string,
  params?: { [key: string]: any }
): any {
  return () => {
    return fetchGet<SpaceAction>(`spaces/${spaceId}/members`, params || {}, {
      version: 1,
    }).then((res: any) => {
      return res;
    });
  };
}

export function fetchSpaceInvitations(
  spaceId: string,
  params?: { [key: string]: any }
): any {
  return () => {
    return fetchGet<SpaceAction>(
      `spaces/${spaceId}/invitations`,
      params || {},
      {}
    ).then((res: any) => {
      return res;
    });
  };
}

export function fetchSpaceInvitationById(
  spaceId: string,
  invitationId: string,
  params?: { [key: string]: any }
): any {
  return () => {
    return fetchGet<SpaceAction>(
      `spaces/${spaceId}/invitations/${invitationId}`,
      params || {},
      {}
    ).then((res: any) => {
      return res;
    });
  };
}

export function inviteMemberToSpace(
  spaceId: string,
  invitation?: { [key: string]: any }
): any {
  return () => {
    return fetchPost<SpaceAction>(
      `spaces/${spaceId}/invitations`,
      { invitation },
      {}
    ).then((res: any) => {
      return res;
    });
  };
}

export function deleteInvitation(spaceId: string, invitationId: string): any {
  return () => {
    return fetchDelete(
      `spaces/${spaceId}/invitations/${invitationId}`,
      {},
      {}
    ).then((res: any) => {
      return res;
    });
  };
}

export function makeMemberAthlete(
  spaceId: string,
  memberId: string,
  member?: { [key: string]: any }
): any {
  return () => {
    return fetchPut<SpaceAction>(
      `spaces/${spaceId}/members/${memberId}`,
      { member },
      {}
    ).then((res: any) => {
      return res;
    });
  };
}

export function deleteMemberFromSpace(spaceId: string, memberId: string): any {
  return () => {
    return fetchDelete(
      `spaces/${spaceId}/members/${memberId}`,
      {},
      { version: 1 }
    ).then((res: any) => {
      return res;
    });
  };
}

export function fetchMySpaces(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());

    return fetchGet<SpaceAction>("spaces/my", params || {}, {}, signal).then(
      (res: SpaceModel[] | any) => {
        if (res && Array.isArray(res)) {
          dispatch(fetchSuccess(res));
        }
        return res;
      }
    );
  };
}

export function fetchOthersSpaces(
  params?: { [key: string]: any },
  signal?: AbortSignal
): any {
  return (dispatch: Dispatch<SpaceAction>, getState: () => SpaceState) => {
    dispatch(fetchPending());

    // @ts-ignore
    const state = getState().space;

    return fetchGet<SpaceAction>("spaces/other", params || {}, {}, signal).then(
      (res: SpaceModel[] | any) => {
        if (res && Array.isArray(res)) {
          dispatch(fetchSuccess(res));
          dispatch(spacePage(state.page + 1));
          if (res.length < (params && params.force_per_page)) {
            dispatch(hasMoreSpaces(false));
          }
        }

        return res;
      }
    );
  };
}

export function putSpaceNotifications(spaceId: string, user_settings: {}) {
  return () => {
    const url = config.API_SERVER + `/api/v2/spaces/${spaceId}/user_settings`;
    const promise = request(url, "PUT", { user_settings }, { version: 1 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function fetchMyOthersSpaces({
  paramsMySpaces,
  paramsOthersSpaces,
  signal,
}: {
  paramsMySpaces?: { [key: string]: any };
  paramsOthersSpaces?: { [key: string]: any };
  signal?: AbortSignal;
}) {
  return async (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());

    try {
      const mySpacesResult: any = await dispatch(
        fetchMySpaces({ ...paramsMySpaces }, signal)
      );
      const othersSpacesResult: any = await dispatch(
        fetchOthersSpaces({ ...paramsOthersSpaces }, signal)
      );
      const combinedResults: SpaceModel[] = [
        ...mySpacesResult,
        ...othersSpacesResult,
      ];
      return combinedResults;
    } catch (error) {
      // @ts-ignore
      console.error("Error fetching spaces:", error);
      throw error;
    }
  };
}

export function transferSpaceOwnership(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<SpaceAction>) => {
    dispatch(fetchPending());
    const url =
      config.API_SERVER + `/api/v2/spaces/${params.id}/transfer_ownership`;
    const promise = request(
      url,
      "POST",
      { membership: { email: params.email } },
      { version: 1 }
    );
    promise.then((res) => {
      dispatch(newOwnerData(res));
    });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export const actions = {
  fetchSpaces,
  fetchSpaceMemberships,
  fetchMySpaces,
  fetchOthersSpaces,
  fetchMyOthersSpaces,
  deleteSpace,
  fetchSpaceInvitations,
  fetchSpaceInvitationById,
  inviteMemberToSpace,
  deleteInvitation,
  makeMemberAthlete,
};
