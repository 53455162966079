import React, { useState } from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import { useActions } from "app/utils/hooks";
import { changePassword, fetchSelf } from "app/actions/user";
import AccountWrap from "..";
import { useTranslation } from "react-i18next";
import { Toast } from "app/components/helpers";

const PasswordEdit = () => {
  const { t } = useTranslation();
  const userActions = useActions({ fetchSelf, changePassword });
  const [password, setPassword] = useState<{
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
  }>({ currentPassword: "", password: "", passwordConfirmation: "" });

  const [error, setError] = useState<{
    currentPasswordErr?: boolean;
    passwordErr?: boolean;
    passwordConfirmationErr?: boolean;
  }>({
    currentPasswordErr: false,
    passwordErr: false,
    passwordConfirmationErr: false,
  });
  const [toastAlert, setToastAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateUser = () => {
    setLoading(true);
    userActions
      .changePassword(
        password.currentPassword,
        password.password,
        password.passwordConfirmation,
      )
      .then(() => {
        userActions.fetchSelf();
        setError({
          currentPasswordErr: false,
          passwordErr: false,
          passwordConfirmationErr: false,
        });
        setPassword({
          currentPassword: "",
          password: "",
          passwordConfirmation: "",
        });
        setToastAlert(t("Your account was successfully updated."));

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (!err) {
          return null;
        }
        if (err.errors[0] === "current_password_invalid") {
          setError({
            currentPasswordErr: true,
          });
        } else if (err.errors[0] === "d") {
          setError({
            passwordConfirmationErr: true,
          });
        } else {
          setError({
            passwordErr: true,
          });
        }
      });
  };

  return (
    <AccountWrap>
      <div className={cls(styles.container, "panel")}>
        <h2 className="text-muted mb-1">{t("Password")}</h2>

        <span
          className={cls(styles.input_wrapper, {
            [styles.error]: error.currentPasswordErr,
          })}
        >
          <label htmlFor="current_password">{t("Current Password")}</label>
          <input
            autoComplete="current-password"
            type="password"
            id="current_password"
            value={password.currentPassword}
            onChange={(e) =>
              setPassword({ ...password, currentPassword: e.target.value })
            }
          />
          {error.currentPasswordErr && (
            <span className="helper-text error js-form-error">
              {t("Please enter your current password.")}
            </span>
          )}
        </span>
        <span
          className={cls(styles.input_wrapper, {
            [styles.error]: error.passwordErr,
          })}
        >
          <label htmlFor="new_password">{t("New Password")}</label>
          <input
            type="password"
            id="new_password"
            value={password.password}
            onChange={(e) =>
              setPassword({ ...password, password: e.target.value })
            }
          />
          {error.passwordErr && (
            <span className="helper-text error js-form-error">
              {t("Please enter your new password")}
            </span>
          )}
        </span>
        <span
          className={cls(styles.input_wrapper, {
            [styles.error]: error.passwordConfirmationErr,
          })}
        >
          <label htmlFor="new_password_confirm">
            {t("New Password Confirmation")}
          </label>
          <input
            type="password"
            id="new_password_confirm"
            value={password.passwordConfirmation}
            onChange={(e) =>
              setPassword({ ...password, passwordConfirmation: e.target.value })
            }
          />
          {error.passwordConfirmationErr && (
            <span className="helper-text error js-form-error">
              {t("Passwords do not match")}
            </span>
          )}
        </span>
        <Button
          buttonType="primary"
          title={t("Save Changes")}
          buttonStyles={styles.save_button}
          onClickFunc={updateUser}
          loading={loading}
        />
      </div>
      <Toast success={toastAlert} onDone={() => setToastAlert(null)} />
    </AccountWrap>
  );
};

export default PasswordEdit;
