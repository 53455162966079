import React from 'react';

import styles from './styles.module.scss';
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { useTranslation } from 'react-i18next';
import Activate from "../../../../public/images/product-activate.svg";
import Deactivate from "../../../../public/images/product-deactivate.svg";

type PropsDef = {
  actions: any,
  data: any,
  cancelButton?: string
};

export default function OfferingActionModal(props: PropsDef) {
  const { t } = useTranslation();
  const { data, actions, cancelButton } = props;
  const buttonStyle = data.operation === 'delete' ? styles.btn_danger : "btn-primary";


  return (
    <ModalLayout isOpen classNames={`${styles.modal}`}>
      <div className={styles.confirmation_modal_content}>
        <div className={styles.close_button} onClick={actions.onCancel}>
          <i className="ico ico-x" />
        </div>
        <div className={styles.title}>
          {props.data.operation === 'delete' ? <img src={Deactivate} alt="deactivate"/>  : <img src={Activate} alt="activate"/>}
          <h2>{props.data.title}</h2>
        </div>
        <div className={styles.content}>
          {props.data.content}
        </div>
        <div className={styles.actions}>
          <button type="button" className={`btn ${buttonStyle}`} onClick={actions.onConfirm}>
            {data.button_text}
          </button>
          <button type="button" className={`btn ${styles.cancel_button}`} onClick={actions.onCancel}>{cancelButton || t('Cancel')}</button>
        </div>
      </div>
    </ModalLayout>
  );
}
