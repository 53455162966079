/**
 * @module Store
 *
 */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';
import settings from 'config/environment';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

/**
 * NOTE: DO NOT REMOVE
 *
 * redux-connect-standalone is a package that helps
 * inject redux into containers that use ngreact
 */

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'bookSession',
    'programRegistration'
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let composeEnhancers = (input: any): any => {
  return input;
};

if (settings.DEBUG) {
  // adds redux dev tools to chrome console
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

// configure middlewares
const middlewares = [ thunk ];

// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};

const store = createStore(persistedReducer, initialState, enhancer);
const persistor = persistStore(store);

export {store, persistor};

