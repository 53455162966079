import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import CreateNewXlButton from "app/components/Button/CreateNewXlButton";
import SpaceCardXl from "app/components/SpaceCardXl";
import { useSelector } from "app/utils/hooks";
import { Loading } from "../Wrappers";
import { Link, useHistory } from "react-router-dom";
import GroupCardXl from "../GroupCardXl";
import { RootState } from "typedefs";
import { SpacesLimit } from "../paywalls";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import SpaceModel from "app/models/SpaceModel";
import { CreateNewGroupModal } from "app/routes/Groups/_components/CreateNewGroupModal";

type dropdownList = {
  label: string;
  icon: string | null;
  onClick: () => void;
};

interface IProps {
  spacesData: SpaceModel[];
  pending: boolean;
  displayingAllSpaces: boolean;
}

const SpacesAndGroupsList = ({
  spacesData,
  pending,
  displayingAllSpaces,
}: IProps) => {
  const history = useHistory();
  const { self } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();

  const [showUpgrade, setUpgradeState] = useState(false);
  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState(false);

  const canCreateSpace = self?.planPermissions?.includes("import_spaces");
  const canCreateGroup = self?.planPermissions?.includes("create_teams");

  const handleUpgrade = (): void => {
    setUpgradeState((prev: boolean): boolean => !prev);
  };

  const createGroupModalHandler = (): void => {
    if (canCreateGroup) {
      setIsOpenCreateGroupModal((prev: boolean): boolean => !prev);
    }
  };

  const onNewSpaceClick = (): void => {
    if (!canCreateSpace) {
      return handleUpgrade();
    }
    history.push("/spaces/new");
  };

  const onNewGroupClick = (): void => {
    if (!canCreateGroup) {
      return handleUpgrade();
    }
    createGroupModalHandler();
  };

  const dropdownList: dropdownList[] = [
    {
      label: t("New Space"),
      icon: "ico ico-tspace",
      onClick: onNewSpaceClick,
    },
    {
      label: t("New Group"),
      icon: "ico ico-team",
      onClick: onNewGroupClick,
    },
  ];

  const DropdownContent = useCallback((): React.ReactElement => {
    return (
      <ul className={styles.dropdown_content}>
        {dropdownList.map((item: dropdownList): React.ReactElement => {
          return (
            <button
              key={item.label}
              className={styles.dropdown_item}
              onClick={item.onClick}
            >
              <i className={cls(item.icon, styles.dropdown_item_icon)} />
              {item.label}
            </button>
          );
        })}
      </ul>
    );
  }, []);

  if (pending) {
    return (
      <div className={styles.loading_wrapper}>
        <Loading loadType="spinner" isLoading className={styles.loading} />
      </div>
    );
  }

  return (
    <div className={styles.space_cards_list}>
      <span className={styles.create_space}>
        <CreateNewXlButton
          children={<DropdownContent />}
          className={styles.create_space_button}
        />
      </span>
      {(displayingAllSpaces ? spacesData : spacesData?.slice(0, 4))?.map(
        (data: any, index: number) => (
          <Link
            key={data.id || index}
            to={
              data.space ? `/spaces/${data?.space?.id}` : `/groups/${data.id}`
            }
            className={styles.space_group_card_container}
          >
            {data.space ? (
              <SpaceCardXl spaceData={data.space} />
            ) : (
              <GroupCardXl groupData={data} />
            )}
          </Link>
        )
      )}
      <SpacesLimit show={showUpgrade} onClose={handleUpgrade} />
      <CreateNewGroupModal
        isOpen={isOpenCreateGroupModal}
        onClose={createGroupModalHandler}
      />
    </div>
  );
};

export default SpacesAndGroupsList;
