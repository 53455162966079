import React from "react";
import styles from "./styles.module.scss";
import PhotoContainer from "app/components/Programs/ImageContainers/PhotoContainer";

interface Props {
  title?: string;
  label?: string;
  footer?: string;
  imageUrl: string;
  setImageUrl: (url: string) => void;
  children?: React.ReactNode;
}

const LogoContainer = ({
  title,
  label,
  footer,
  setImageUrl,
  imageUrl,
  children
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.first_row}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>{label}</div>
        <PhotoContainer
          imageLink={imageUrl}
          type="logo"
          customPlaceholderStyle={styles.image_placeholder}
          setUrl={setImageUrl}
          uploadImage
        />
        <div className={styles.label}>{footer}</div>
        {children}
      </div>
    </div>
  );
};

export default LogoContainer;
