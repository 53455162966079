import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../utils/luxonFormats";
import { useConstants } from "../../../Scheduling/constants";

interface Payment {
  productPrice: string;
  productTitle: string;
  purchaseDate: string;
  purchaseStatus: string;
  currency: string;
}

interface PaymentsTableProps {
  payments: Payment[];
}

function PaymentsTable({ payments }: PaymentsTableProps) {
  const { t } = useTranslation();
  const { currency_list } = useConstants();

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "Paid":
        return styles.statusSuccess;
      case "Pending":
        return styles.statusIncomplete;
      default:
        return styles.statusFailed;
    }
  };

  const getCurrencySymbol = (currencyCode) => {
    const currency = currency_list.find((c) => c.value === currencyCode);
    return currency ? currency.currency : currencyCode;
  };

  const getStatusDisplayText = (status: string) => {
    switch (status) {
      case "Paid":
        return t("Success");
      case "Pending":
        return t("Incomplete");
      case "Past_due":
        return t("Past Due");
      case "Canceled":
        return t("Canceled");
      default:
        return t("Failed");
    }
  };

  return (
    <div className={styles.paymentsTableContainer}>
    <table className={styles.paymentsTable}>
      <thead>
        <tr className={styles.labelTable}>
          <th>{t("Amount")}</th>
          <th>{t("Description")}</th>
          <th>{t("Date")}</th>
          <th>{t("Status")}</th>
        </tr>
      </thead>
      <tbody>
        {payments.map((payment, index) => (
          <tr key={index}>
            <td
              className={
                payment.purchaseStatus === "Paid" ? styles.boldAmount : ""
              }
            >
              {getCurrencySymbol(payment.currency)}
              {payment?.productPrice}
            </td>
            <td>{payment.productTitle}</td>
            <td>{formatDate(payment.purchaseDate)}</td>
            <td>
              <span
                className={`${styles.statusBadge} ${getStatusBadgeClass(
                  payment.purchaseStatus
                )}`}
              >
                {getStatusDisplayText(payment.purchaseStatus)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

export default PaymentsTable;
