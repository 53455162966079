import React from "react";
import UserModel from "app/models/UserModel";
import CoachCard from "../CoachCard";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  coaches: UserModel[];
}

const MyCoachesList = ({ coaches }: Props) => {
  const { t } = useTranslation();

  if (coaches.length === 0) {
    return (
      <div className={styles.empty_container}>
        <p className={styles.empty_text}>{t("No Coaches")}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {coaches?.map((user: UserModel) => {
        return <CoachCard key={user?.id} user={user} />;
      })}
    </div>
  );
};

export default MyCoachesList;
