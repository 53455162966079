import React from "react";
import styles from "./styles.module.scss";
import { GroupsModel } from "app/models";
import { cls } from "app/utils";
import { Loading } from "app/components/Wrappers";
import { uuid } from "app/utils/uuid";
import { useHistory } from "react-router-dom";
import CreateNewXlButton from "app/components/Button/CreateNewXlButton";
import GroupCardXl from "app/components/GroupCardXl";
export interface groupsListProps {
  groupsData?: GroupsModel[];
  pending?: boolean;
  onClickCreateGroup: () => void;
}

const GroupsList = ({
  groupsData,
  pending,
  onClickCreateGroup,
}: groupsListProps) => {
  const history = useHistory();

  // const { self } = useSelector((state: RootState) => state.user);

  const onGroupClickHandler = (group_id: string) => {
    history.push(`/groups/${group_id}`);
  };

  const CreateNewgroup = () => {
    if (pending && groupsData?.length === 0) {
      return null;
    }

    return (
      <span className={styles.create_group} onClick={onClickCreateGroup}>
        <CreateNewXlButton className={styles.create_group_button} />
      </span>
    );
  };

  return (
    <div className={cls(styles.groups_list_container)}>
      <div className={styles.group_cards_list}>
        <CreateNewgroup />
        {groupsData?.map((data: GroupsModel) => {
          return (
            <div
              key={data?.id || uuid()}
              onClick={() => onGroupClickHandler(data?.id)}
              className={styles.group_item_wrapper}
            >
              <GroupCardXl groupData={data} />
            </div>
          );
        })}
      </div>
      {pending && (
        <Loading isLoading loadType="spinner" className={styles.loading} />
      )}
    </div>
  );
};

export default GroupsList;
