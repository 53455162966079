import React from "react";
import { useTranslation } from "react-i18next";

import { cls } from "app/utils";

import styles from "./styles.module.scss";

type PropsDef = {
  onSubmit: () => void;
  onCancel: () => void;
  submitLabel?: string;
  cancelLabel?: string;
  submitDisabled?: boolean;
  style?: string;
  showCancel?: boolean;
};

export default function SaveBar(props: PropsDef) {
  const { t } = useTranslation();
  const {
    onSubmit,
    onCancel,
    style,
    submitLabel = t("Next"),
    cancelLabel = t("Cancel"),
    submitDisabled = false,
    showCancel,
  } = props;

  return (
    <div className={cls(styles.save_bar, style)}>
      {showCancel ? (
        <button onClick={onCancel} className="btn btn-outline-secondary">
          {cancelLabel}
        </button>
      ) : null}
      <button
        disabled={submitDisabled}
        onClick={onSubmit}
        className={cls("btn btn-primary")}
      >
        {submitLabel}
      </button>
    </div>
  );
}
