import React from "react";
import { ApplyTemplateToProps } from "../ApplyTemplateDropdown/index.types";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";

const ApplyTemplateToChannel = ({
  className,
  channelSelectModalHanlder,
}: ApplyTemplateToProps) => {
  const { t } = useTranslation();

  return (
    <div className={cls(className)} onClick={channelSelectModalHanlder}>
      <i className="ico ico-team" />
      <p>{t("Apply to Channel")}</p>
    </div>
  );
};

export default ApplyTemplateToChannel;
