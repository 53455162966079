import DropdownButton from "app/components/DropdownButton";
import SearchBar from "app/components/inputs/SearchBar";
import SmartListModel from "app/models/SmartListModel";
import { RootState } from "app/reducers";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SpaceSmartList from "../SpaceSmartList";
import styles from "./styles.module.scss";

interface IProps {
  selectedListValue: SmartListModel;
  onSmartListSelect: (value: SmartListModel) => void;
  updateSearch: (value: string) => void;
  upgradeHandler: () => void;
}

const SpacesNavbar = ({
  selectedListValue,
  onSmartListSelect,
  updateSearch,
  upgradeHandler,
}: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { self } = useSelector((state: RootState) => state.user);

  const newMulti = () => {
    if (!self?.planPermissions?.includes("create_spaces")) {
      return upgradeHandler();
    }

    return history.push("/spaces/new/multiple");
  };

  return (
    <div className={styles.container}>
      <div className={styles.left_wrapper}>
        <SpaceSmartList
          selectedListValue={selectedListValue}
          onSmartListSelect={onSmartListSelect}
        />
        <SearchBar
          className={styles.search}
          placeholder={t("Search...")}
          onChange={updateSearch}
        />
      </div>
      <span className={styles.right_wrapper}>
        <DropdownButton
          btnClassName={styles.dropdown_btn}
          dropdownWrapperClass={styles.dropdown_wrapper}
          btnContent={<i className="bullets" />}
          hideButtonStyles
          closeOnClick
        >
          <span className={styles.button_wrapper} onClick={newMulti}>
            <i className="ico ico-add" />
            {t("Add Multiple Spaces")}
          </span>
        </DropdownButton>
      </span>
    </div>
  );
};

export default memo(SpacesNavbar);
