import React from "react";
import PublicWrapper from "app/components/PublicWrapper";
import StudentPrograms from "../ManagePrograms/ManageStudentPrograms";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import SiteWrapper from "app/components/Layouts/SiteWrapper";
import { UserState } from "app/reducers/user";
import { Loading } from "app/components/Wrappers";


const PublicFacilityPrograms = () => {
   const userState = useSelector(
     (state: RootState): UserState => state.user
   );

   if (userState.pending) {
    return (
      <Loading />
    );
   }

   if (userState.data) {
     return (
       <SiteWrapper>
         <StudentPrograms isPublic />
       </SiteWrapper>
     );
   } else {
     return (
       <PublicWrapper>
         <StudentPrograms isPublic />
       </PublicWrapper>
     );
   }
};

export default PublicFacilityPrograms;
