import { DateTime } from "luxon";
import i18n from "app/i18n";

export const days_of_the_week = [
  { value: 0, label: i18n.t("Sunday"), short: i18n.t("Sun") },
  { value: 1, label: i18n.t("Monday"), short: i18n.t("Mon") },
  { value: 2, label: i18n.t("Tuesday"), short: i18n.t("Tue") },
  { value: 3, label: i18n.t("Wednesday"), short: i18n.t("Wed") },
  { value: 4, label: i18n.t("Thursday"), short: i18n.t("Thu") },
  { value: 5, label: i18n.t("Friday"), short: i18n.t("Fri") },
  { value: 6, label: i18n.t("Saturday"), short: i18n.t("Sat") },
];

export const parseTime = (time: string) => {
  const [hour, minute] = time.split(":");
  return parseInt(hour, 10) * 60 + parseInt(minute, 10);
};

export const convertTime = (time: number) => {
  const hour = Math.floor(time / 60);
  const minute = time % 60;
  return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
};

export const dateToString = (time_table: any) => {
  let start_date;
  let end_date;

  if (time_table.customPeriod === false) {
    time_table.startDate = null;
    time_table.endDate = null;
  } else {
    if (time_table.startDate.target && time_table.endDate.target) {
      start_date = time_table.startDate.target.value;
      end_date = time_table.endDate.target.value;
    } else {
      start_date = time_table.startDate;
      end_date = time_table.endDate;
    }

    return {
      ...time_table,
      startDate: DateTime.fromJSDate(start_date).toFormat("yyyy-MM-dd"),
      endDate: DateTime.fromJSDate(end_date).toFormat("yyyy-MM-dd"),
    };
  }

  return time_table;
};

export const stringToDate = (time_table: any) => {
  let start_date;
  let end_date;
  if (time_table.customPeriod === false) {
    time_table.startDate = null;
    time_table.endDate = null;
  } else {
    start_date = DateTime.fromFormat(
      time_table.startDate,
      "yyyy-MM-dd",
    ).toJSDate();
    end_date = DateTime.fromFormat(time_table.endDate, "yyyy-MM-dd").toJSDate();
    time_table.startDate = start_date;
    time_table.endDate = end_date;
  }

  return time_table;
};

export const parseSchedules = (schedule: any, days_of_the_week) => {
  let from;
  let to;

  if (schedule.startDate) {
    from = DateTime.fromFormat(schedule.startDate, "yyyy-MM-dd").toJSDate();
  } else {
    from = DateTime.now().startOf("year").toJSDate();
  }

  if (schedule.endDate) {
    to = DateTime.fromFormat(schedule.endDate, "yyyy-MM-dd").toJSDate();
  } else {
    to = DateTime.now().endOf("year").toJSDate();
  }

  return {
    ...schedule,
    startDate: from,
    endDate: to,
    dailyTimeTables: schedule.dailyTimeTables
      .map((day: any) => ({
        ...day,
        active: day.active,
        value: day.day,
        label: days_of_the_week[day.day].label,
        short: days_of_the_week[day.day].short,
        startTime: convertTime(day.beginAt),
        endTime: convertTime(day.endAt),
      }))
      .sort((a: any, b: any) => a.day - b.day),
    customPeriod: schedule.startDate !== null && schedule.endDate !== null,
  };
};

export const currency_list = [
  { value: "USD", label: i18n.t("US Dollar (USD, $)"), currency: "$" },
  { value: "AUD", label: i18n.t("Australian Dollar (AUD, $)"), currency: "$" },
  { value: "GBP", label: i18n.t("British Pound (GBP, £)"), currency: "£" },
  { value: "CAD", label: i18n.t("Canadian Dollar (CAD, $)"), currency: "$" },
  { value: "EUR", label: i18n.t("Euro (EUR, €)"), currency: "€" },
  { value: "JPY", label: i18n.t("Japanese Yen (JPY, ¥)"), currency: "¥" },
  {
    value: "AED",
    label: i18n.t("United Arab Emirates Dirham (AED)"),
    currency: "AED",
  },
  { value: "MXN", label: i18n.t("Mexican Peso (MXN)"), currency: "MXN" },
  { value: "NZD", label: i18n.t("New Zealand Dollar (NZD, $)"), currency: "$" },
  { value: "TRY", label: i18n.t("Turkish Lira (TRY, ₺)"), currency: "₺" },
];

export const time_zone_list = [
  {
    value: "Etc/GMT+12",
    code: "International Date Line West",
    label: i18n.t("(GMT-12:00) International Date Line West"),
  },
  {
    value: "Pacific/Pago_Pago",
    code: "American Samoa",
    label: i18n.t("(GMT-11:00) American Samoa"),
  },
  {
    value: "Pacific/Midway",
    code: "Midway Island",
    label: i18n.t("(GMT-11:00) Midway Island"),
  },
  {
    value: "Pacific/Honolulu",
    code: "Hawaii",
    label: i18n.t("(GMT-10:00) Hawaii"),
  },
  {
    value: "America/Juneau",
    code: "Alaska",
    label: i18n.t("(GMT-09:00) Alaska"),
  },
  {
    value: "America/Los_Angeles",
    code: "Pacific Time (US & Canada)",
    label: i18n.t("(GMT-08:00) Pacific Time (US & Canada)"),
  },
  {
    value: "America/Tijuana",
    code: "Tijuana",
    label: i18n.t("(GMT-08:00) Tijuana"),
  },
];

export const pricings = [
  {
    title: "Test title 1",
    duration: "30 min",
    price: 50,
    sessionType1: ["basics", "full swing"],
    sessionType2: "full swing",
    location: "Golf Club",
    setDefault: true,
  },
  {
    title: "Test title 2",
    duration: "15 min",
    price: 100,
    sessionType1: ["full swing"],
    sessionType2: "putting",
    location: "Golf Academy",
    setDefault: false,
  },
  {
    title: "Test title 3",
    duration: "60 min",
    price: 150,
    sessionType1: ["wedges"],
    sessionType2: "bunkers",
    location: "Driving Range",
    setDefault: false,
  },
  {
    title: "Test title 4",
    duration: "75 min",
    price: 75,
    sessionType1: ["putting"],
    sessionType2: "recover",
    location: "Mini Golf Course",
    setDefault: false,
  },
  {
    title: "Test title 5",
    duration: "90 min",
    price: 200,
    sessionType1: ["playing lesson"],
    sessionType2: "club fitting",
    location: "Golf Course",
    setDefault: false,
  },
];

export const list_of_months = [
  i18n.t("1 Month"),
  i18n.t("2 Months"),
  i18n.t("3 Months"),
  i18n.t("4 Months"),
  i18n.t("5 Months"),
  i18n.t("6 Months"),
];

export const list_of_times = [
  i18n.t("1 Week"),
  i18n.t("2 Weeks"),
  i18n.t("3 Weeks"),
  i18n.t("4 Weeks"),
  i18n.t("5 Weeks"),
  i18n.t("6 Weeks"),
  i18n.t("7 Weeks"),
  i18n.t("8 Weeks"),
  i18n.t("3 Months"),
  i18n.t("4 Months"),
  i18n.t("5 Months"),
  i18n.t("6 Months"),
  i18n.t("Unlimited"),
];
