import React from "react";
import styles from "./styles.module.scss";
import UserAvatar from "app/components/UserAvatar";
import PostModel from "app/models/PostModel";
import EditPost from "../../EditPost";
import { cls } from "app/utils";
import { ReplyAttachmentPreview } from "../ReplyAttachmentPreview";
import { postDate } from "app/utils/postDate";
import PostLikes from "../../PostLikes";
import PostMenu from "../../PostMenu";
import { useReplyModel } from "./indexModel";
import PostMediaModal from "../../PostMedia/PostMediaView/PostMediaModal";
import { useTranslation } from "react-i18next";
import PostDetails from "../../PostDetails";

interface Props {
  reply: PostModel;
  post: PostModel;
  onReplyEdit?: (reply: PostModel) => void;
  onReplyDelete?: (reply: PostModel) => void;
  onReplyUpdate?: (reply: PostModel) => void;
  onReplyReport?: (reply: PostModel) => void;
  updateError?: boolean;
  isDemo?: boolean;
}

const Reply: React.FC<Props> = ({
  reply,
  post,
  onReplyDelete,
  onReplyReport,
  isDemo,
}: Props) => {
  const { t } = useTranslation();
  const {
    modalHandler,
    isModalOpen,
    onEditClick,
    onEditClose,
    isEditingReply,
    onReplyUpdate,
    updateError,
  } = useReplyModel({ reply });

  return (
    <div className={styles.reply_container} id={`reply-${reply?.id}`}>
      <UserAvatar
        altName="avatar"
        userName={reply.userName}
        srcName={reply.userAvatar}
        sizeWidth={44}
        sizeHeight={44}
        className={styles.avatar}
      />
      <div className={styles.reply_content}>
        <div className={styles.username}>{reply.userName}</div>
        {isEditingReply && (
          <EditPost
            classNames={cls({
              [styles.edit_post_container]: post.resources,
            })}
            post={reply}
            onCancel={onEditClose}
            onUpdate={onReplyUpdate}
            hideSelectTags
          />
        )}
        {!isEditingReply && reply.details && (
          <PostDetails details={reply.details} />
        )}

        {reply.resources && (
          <div className={styles.reply_details}>
            <ReplyAttachmentPreview
              key={reply.resources[0].id}
              mediaData={reply.resources[0]}
              onClick={modalHandler}
            />
          </div>
        )}
        <div className={styles.reply_info}>
          <div className={styles.reply_date}>{postDate(reply.createdAt)}</div>
          <div className="dot" />
          <div className={styles.reply_like}>
            <PostLikes
              postId={reply.id}
              likesCount={reply.likesCount}
              liked={reply.liked}
              likable={reply.likable}
              isDemo={isDemo}
            />
          </div>
          <div className="dot" />
          {!isDemo && (
            <PostMenu
              hideButtonStyles
              buttonClassName={styles.dropdown_button_classname}
              dropdownClassName={styles.dropdown_container}
              props={reply}
              isReply
              onDelete={onReplyDelete}
              onReport={onReplyReport}
              onEdit={onEditClick}
            />
          )}
        </div>
      </div>
      {updateError && (
        <div className={styles.error}>{t("Something went wrong")}</div>
      )}
      {isModalOpen && (
        <PostMediaModal
          onClose={modalHandler}
          isOpen={isModalOpen}
          mediaData={reply.resources[0]}
        />
      )}
    </div>
  );
};

export default Reply;
