import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import SpaceModel from "app/models/SpaceModel";
import { useActions } from "app/utils/hooks";
import {
  fetchSegmentById,
  editSegment,
  clearSegments,
} from "app/actions/segments";
import {
  fetchSpaceMemberships,
  clearSpace,
  spacePage,
} from "app/actions/space";
import Button from "app/components/Button/ButtonVariant";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { useHistory, Link } from "react-router-dom";
import { Loading } from "app/components/Wrappers";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { cls } from "app/utils";

const FORCE_PER_PAGE = 100;
const PER_PAGE = FORCE_PER_PAGE;

const ManageSpaces = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const resourceId = window.location.pathname.split("/")[2];

  const segmentActions = useActions({
    fetchSpaceMemberships,
    clearSpace,
    fetchSegmentById,
    clearSegments,
    editSegment,
  });

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const options = {
    "options[include]": ["segment_names"],
  };

  const [selectedSpaceIds, setSelectedSpaceIds] = useState<string[]>([]);
  const [spacesData, setSpacesData] = useState([]);

  const { page, pending, hasMore } = useSelector(
    (state: RootState) => state.space,
    shallowEqual
  );

  const fetchData = async (page?: number) => {
    if (page && page === 1) {
      dispatch(spacePage(1));
      setSpacesData(null);
    }
    const fetchedSpaces = await fetchSpacesWithMemberships(
      abortController.current,
      page
    );
    setSpacesData((prev: any) => [...(prev || []), ...fetchedSpaces]);
  };

  const abortController = useRef<AbortController | null>(null);

  const fetchSpacesWithMemberships = async (
    abort?: AbortController,
    force_page?: number
  ): Promise<SpaceModel[]> => {
    try {
      const defaultQuery = {
        page: force_page ? force_page : page,
        ...options,
        type: "athlete",
        force_per_page: FORCE_PER_PAGE,
        per_page: PER_PAGE,
      };

      const locationQuery = { ...defaultQuery };

      const res: SpaceModel[] = await segmentActions.fetchSpaceMemberships(
        locationQuery,
        abort ? abort.signal : undefined
      );

      if (res && Array.isArray(res)) {
        return res;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    if (resourceId) {
      segmentActions.fetchSegmentById(resourceId);
      abortController.current = new AbortController();
      setSpacesData(null);
      fetchData(1);
    }

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
      dispatch(clearSpace());
      segmentActions.clearSegments();
      setSelectedSpaceIds(null);
    };
  }, [dispatch, resourceId]);

  const [containerRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: fetchData,
    rootMargin: "0px 100px 0px 0px",
  });

  const { segmentsData, segmentsPending } = useSelector(
    (state: RootState) => state.segments,
    shallowEqual
  );

  useEffect(() => {
    if (segmentsData) {
      setSelectedSpaceIds((segmentsData && segmentsData.spaceIds) || []);
    }
  }, [segmentsData]);

  const cancelUrl = resourceId ? `/lists/${resourceId}/spaces` : "/lists/";

  const updateHandler = () => {
    const updatedSegmentData = {
      ...segmentsData,
      spaceIds: selectedSpaceIds?.length !== 0 ? selectedSpaceIds : [null],
    };
    segmentActions.editSegment(resourceId, updatedSegmentData).then(() => {
      setSelectedSpaceIds([]);
      route(cancelUrl);
    });
  };

  const checkHandler = (spaceId: string) => {
    if (!spaceId) {
      return;
    }

    setSelectedSpaceIds((prevSelectedSpaceIds = []) =>
      prevSelectedSpaceIds.includes(spaceId)
        ? prevSelectedSpaceIds.filter((id) => id !== spaceId)
        : [...prevSelectedSpaceIds, spaceId]
    );
  };

  const selectAllHandler = () => {
    const allSpaceIds = spacesData?.map(
      (data: { space: SpaceModel }) => data?.space?.id
    );
    setSelectedSpaceIds(allSpaceIds);
  };

  const onCancel = () => {
    return history.push(cancelUrl);
  };

  return (
    <SegmentsLayout
      isLoading={segmentsPending}
      loaderChild={<h3 className="well_nothing">{t("Loading...")}</h3>}
      displayNoResult={!segmentsData}
      noResultChild={<h3>{t("No Spaces to select")}</h3>}
    >
      <div className={styles.container}>
        <PagePanel
          title={t("Select {{name}}'s Spaces", {
            name: segmentsData && segmentsData.name,
          })}
        >
          <ol className={styles.breadcrumb_wrapper}>
            <li>
              <Link to="/lists">{t("List")}</Link>
            </li>{" "}
            /{" "}
            <li>
              <Link to={cancelUrl}>{segmentsData && segmentsData.name}</Link>
            </li>{" "}
            / <li>{t("Spaces")}</li>
          </ol>
        </PagePanel>

        <div className={styles.table_wrapper}>
          {spacesData && (
            <table className="segment_spaces table">
              <tbody>
                <tr>
                  <th className="segment-title">
                    <span>{t("Space")}</span>
                  </th>

                  <th className="segment-title">
                    {t("Already in other lists")}
                  </th>
                </tr>
                {spacesData?.map((data: { space: SpaceModel }) => {
                  return (
                    <tr
                      key={data?.space?.id}
                      ng-repeat="space in spaces | orderBy:'athleteName'"
                    >
                      <td>
                        <label className="checkbox-list">
                          <input
                            name={`space_${data?.space?.id}`}
                            onChange={() => checkHandler(data?.space?.id)}
                            checked={
                              selectedSpaceIds &&
                              selectedSpaceIds.includes(data?.space?.id)
                            }
                            type="checkbox"
                          />
                          {data?.space?.athleteName} &nbsp; {data?.space?.sport}
                        </label>
                      </td>

                      <td className="other-segments-label">
                        {data?.space?.segmentNames &&
                          data?.space?.segmentNames
                            ?.filter(
                              (segmentName: string[]) =>
                                segmentName !== segmentsData &&
                                segmentsData?.name
                            )
                            ?.join(", ")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        {pending && <Loading isLoading={pending} loadType="spinner" />}
        <div ref={containerRef} />
        <div
          className={cls(styles.buttons_wrapper, {
            ["mt-1"]: pending,
          })}
        >
          <Button
            title={t(`Select All {{count}} Spaces`, {
              count: spacesData?.length,
            })}
            buttonType="primary"
            onClickFunc={selectAllHandler}
            disabled={pending || spacesData?.length === 0}
          />
          <Button
            title={t("Update")}
            buttonType="primary"
            onClickFunc={updateHandler}
            disabled={pending}
          />
          <Button
            title={t("Cancel")}
            buttonType="secondary-outline"
            onClickFunc={onCancel}
          />
        </div>
      </div>
    </SegmentsLayout>
  );
};

export default ManageSpaces;
