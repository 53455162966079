import DropdownButton from "app/components/DropdownButton";
import { cls } from "app/utils";
import React, { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

interface IProps {
  children?: ReactNode;
  label?: string;
  className?: string;
}

const CreateNewXlButton = ({
  children,
  label,
  className,
}: IProps): React.ReactElement => {
  const { t } = useTranslation();

  const CreateNewButton = useCallback((): React.ReactElement => {
    return (
      <div className={cls(styles.new_button_container, className)}>
        <div className={styles.add_icon_container}>
          <i className="ico ico-add" />
        </div>
        <p>{label ?? t("New")}</p>
      </div>
    );
  }, [t]);

  return (
    <div className={styles.container}>
      <DropdownButton
        dropdownWrapperClass={styles.dropdown_content_container}
        btnContent={<CreateNewButton />}
        btnClassName={styles.btnClassName}
      >
        {children}
      </DropdownButton>
    </div>
  );
};
export default CreateNewXlButton;
