import { useCallback, useEffect, useMemo, useState } from "react";
import Fuse from "../../../vendor/fuse";
import { useActions, useSelector } from "app/utils/hooks";
import {
  clearGroup,
  clearMyInformationData,
  clearSelectedGroupData,
  fetchGroups,
} from "../../actions/groups";
import { RootState } from "typedefs";
import { useLocation } from "react-router-dom";
import { shallowEqual } from "react-redux";
import SpaceModel from "app/models/SpaceModel";
import { GroupsState } from "app/reducers/groups";
import UserModel from "app/models/UserModel";

export const useGroupsModel = () => {
  const [searchTerm, setSearch] = useState<string>("");
  const location = useLocation();
  const groupActions = useActions({
    fetchGroups,
    clearGroup,
    clearSelectedGroupData,
    clearMyInformationData,
  });
  const user = useSelector(
    (state: RootState): UserModel => state.user.self,
    shallowEqual,
  );

  const canCreateGroup = user && user.planPermissions.includes("create_teams");

  const [pending, setPending] = useState(false);
  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  const createGroupModalHandler = (): void => {
    if (canCreateGroup) {
      setIsOpenCreateGroupModal((prev: boolean): boolean => !prev);
    }
  };

  useEffect((): (() => void) => {
    setPending(true);
    groupActions.fetchGroups().finally(() => setPending(false));

    return (): void => {
      groupActions.clearSelectedGroupData();
      groupActions.clearMyInformationData();
      groupActions.clearGroup();
    };
  }, []);

  useMemo((): void => {
    const createGroupParam = queryParams.get("create-group");

    if (createGroupParam && canCreateGroup) {
      createGroupModalHandler();
    }
  }, [location, canCreateGroup]);

  const groups = useSelector(
    (state: RootState): GroupsState => state.groups,
    shallowEqual,
  );

  const fuseOptions = {
    keys: ["name", "sport"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.5,
  };

  const fuse = useMemo(() => {
    if (groups && groups.data) {
      const items =
        groups &&
        groups.data &&
        groups.data.map((space: SpaceModel): SpaceModel => space);
      return new (Fuse as any)(items, fuseOptions);
    }
  }, [groups, fuseOptions]);

  const results = useMemo(() => {
    if (groups && groups.data) {
      if (searchTerm !== "") {
        return fuse.search(searchTerm).map((result: any) => result.item);
      } else {
        return groups.data;
      }
    } else {
      return [];
    }
  }, [searchTerm, groups, fuse]);

  const onSearchGroup = useCallback((value: string) => {
    if (value != null && value.length > 0) {
      setSearch(value);
    } else {
      setSearch("");
    }
  }, []);

  return {
    searchTerm,
    onSearchGroup,
    canCreateGroup,
    results,
    createGroupModalHandler,
    isOpenCreateGroupModal,
    pending,
  };
};
