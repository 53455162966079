import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { SelectStyles } from 'app/routes/Calendar/EventForm/constants';
import FormItem from "app/components/inputs/FormItem";

/* import { useTranslation } from 'react-i18next'; */

import styles from './styles.module.scss';

const SelectLessonModal = ({
  onClose,
  onSubmit,
  pricingValues,
  lessonProperties,
}) => {
  /* const { t } = useTranslation(); */
  const { t } = useTranslation();

  const { coach } = lessonProperties;

  const [sessionPricing, setSessionPricing] = useState(pricingValues.find(pv => pv.defaultRate) || pricingValues[0]);

  const handleSessionPricingChange = (newSessionPricing) => {
    setSessionPricing(newSessionPricing);
  };

  const currencySymbol = coach.currencyYymbol;

  const onConfirm = () => {
    onSubmit(sessionPricing);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <div className={styles.select_lesson_form}>
      <FormItem className={styles.input_field}>
        <label>{t('Session')}</label>

        <Select
          options={pricingValues}
          styles={SelectStyles}
          value={sessionPricing}
          onChange={handleSessionPricingChange}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.id}
        />
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t('Session Rate')}</label>
        <input
          value={`${currencySymbol || '$'}${sessionPricing?.rate}`}
          readOnly
        />
      </FormItem>

      <div className={styles.select_lesson_form_actions}>
        <button className="btn btn-outline-secondary" type="submit" onClick={onCancel}>
          {t('Cancel')}
        </button>

        <button className="btn btn-primary" type="submit" onClick={onConfirm}>
          {t('Confirm')}
        </button>
      </div>
    </div>
  );
};

export default SelectLessonModal;
