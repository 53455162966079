import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import SearchBar from "../inputs/SearchBar";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cls } from "app/utils";

interface Props {
  className?: string;
}

const SiteSearch = ({ className }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (history.location.search.split("=")[0].includes("q")) {
      setSearch(history.location.search.split("=")[1]);
    }
  }, [history.location.search]);

  const onSearchEnterPressed = useCallback(
    (e) => {
      if (isFocused && search.length && e.key === "Enter") {
        history.push(`/search?q=${search}`);
      }
    },
    [isFocused, search],
  );

  useEffect(() => {
    window.addEventListener("keydown", onSearchEnterPressed);
    return () => window.removeEventListener("keydown", onSearchEnterPressed);
  }, [onSearchEnterPressed]);

  return (
    <SearchBar
      placeholder={t("Search posts, people or spaces...")}
      className={cls(styles.search, className)}
      value={search}
      onChange={(val) => setSearch(val)}
      onFocus={(val) => setIsFocused(val)}
    />
  );
};

export default SiteSearch;
