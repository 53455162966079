import { FormProvider, useForm } from "react-hook-form";

import styles from "./styles.module.scss";
import utilStyles from "../../../BuildProgram/utilStyles.module.scss";
import FormInput from "../../../../components/Programs/FormInput";
import React, { useEffect, useState } from "react";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfoModal from "app/routes/Calendar/InfoModal";
import { useHistory, useLocation } from "react-router-dom";
import SaveBar from "app/routes/Sessions/SaveBar";
import Label from "app/components/Programs/Label";
import SwitchToggle from "app/routes/Scheduling/SwitchToggle";
import SelectBox from "app/components/OptionButtonGroup/SelectBox";
import { bill_start_options, currency, currency_symbol } from "../utils";
import {
  schedulingFetchGet,
  schedulingFetchPost,
} from "app/utils/request/scheduling/fetch";
import Navbar from "app/components/Navbar";

const Subscription = () => {
  const { t } = useTranslation();
  const mapObjectToDefaultValues = (product) => {
    return {
      title: `[Copy of] ${product.title}`,
      description: product.description,
      number_of_sessions: product.numberOfSessions,
      unlimited: product.numberOfSessions === -1,
      price: product.price.toString(),
      past_due: product.pastDue,
      bill_start:
        bill_start_options.find(
          (option) => option.value === product.billStartDate
        ) || bill_start_options[0],
      per: product.recurring,
      currency_value:
        currency.find((option) => option.value === product.currency) ||
        currency[0],
    };
  };

  const methods = useForm({
    defaultValues: {
      title: "",
      description: "",
      number_of_sessions: null,
      unlimited: false,
      price: null,
      past_due: 10,
      bill_start: { value: "today", label: "Today" },
      per: 1,
      currency_value: { value: "USD", label: "US Dollar (USD, $)" },
    },
  });

  const location = useLocation();
  const { setValue, watch, getValues, register } = methods;
  const unlimited = watch("unlimited");
  const bill_start = watch("bill_start");
  const past_due = watch("past_due");
  const currency_value = watch("currency_value");
  const history = useHistory();
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigateBack = () => {
    history.goBack();
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state?.product) {
      const defaultValues = mapObjectToDefaultValues(location.state.product);
      methods.reset(defaultValues);
    }
  }, [location.state, methods]);

  const handleEnableUnlimited = (checked: boolean) => {
    setValue("unlimited", checked);
  };

  useEffect(() => {
    if (!location.state?.product) {
      schedulingFetchGet(`/coach_profiles?/${userId}`).then((data) => {
        setValue(
          "currency_value",
          currency.find((option) => option.value === data[0].currency) ||
            currency[0]
        );
      });
    }
  }, [userId]);

  const unlimited_toggle = () => {
    return (
      <SwitchToggle
        defaultChecked={unlimited}
        title={t("Unlimited")}
        name="enableUnlimited"
        defaultValue={false}
        setValue={setValue}
        onChange={handleEnableUnlimited}
      />
    );
  };

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const productData = {
      ...getValues(),
      coach_id: userId,
      past_due,
      bill_start: bill_start.value,
      type: "subscription",
      currency: currency_value.value,
    };
    try {
      const data = await schedulingFetchPost(`/stripe_products`, productData);
      if (!data || data.error) {
        throw new Error(data.error || "Error creating product");
      }
      setShowPopup(true);
    } catch (error) {
      console.error("Error creating product:", error);
      setErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const titleValue = watch("title", "");

  return (
    <div>
      <Navbar label={t("Create Subscription")} hasSearch={false} hasNotifications={false} onBackClick={navigateBack}/>
      <div className={styles.empty_container}>
        <div
          className={`${styles.container} ${styles.coach_min_height} billing-page-container`}
        >
          <FormProvider {...methods}>
            <form id="singlePaymentForm">
              <div className={styles.row}>
                <div
                  className={`${utilStyles.column} ${styles.general_section}`}
                >
                  <label>{t("General")}</label>
                  <div className={styles.horizontal_line} />
                  <div className={styles.column}>
                    <div className={styles.input_container_counter}>
                      <FormInput
                        tagTitle={t("Title")}
                        fieldName="title"
                        placeholder={t("Enter package name")}
                        isRequired={true}
                        {...register("title", {
                          required: t("This field is required"),
                          validate: {
                            maxLength: (value) =>
                              value.length <= 250 ||
                              t("The title must not exceed 250 characters"),
                          },
                        })}
                        maxLength={250}
                      />
                      <div className={styles.character_counter}>
                        {titleValue?.length}/250
                      </div>

                      <div className={styles.textarea_width}>
                        <Label
                          text={t("Description")}
                          item={(
                            <textarea
                              data-testid="description"
                              placeholder={t("Enter a description")}
                              {...methods.register("description")}
                              className={styles.details_textarea}
                            />
                          )}
                          containerStyle={styles.mt_16}
                        />
                      </div>

                      {unlimited ? (
                        <div className={styles.field_title}>
                          {t("Number of Sessions")}
                        </div>
                      ) : (
                        <FormInput
                          tagTitle={t("Number of Sessions")}
                          fieldName="number_of_sessions"
                          placeholder={t("Enter the number of sessions")}
                          {...register("number_of_sessions", {
                            validate: {
                              isNumber: (value) =>
                                value === null ||
                                /^\d+$/.test(value) ||
                                t("The value must be a whole number"),
                              inRange: (value) =>
                                value === "" ||
                                value === null ||
                                value >= 1 ||
                                t("The value must be bigger than 1"),
                            },
                          })}
                        />
                      )}
                      {unlimited_toggle()}
                    </div>
                  </div>
                </div>
                <div className={styles.vertical_line} />
                <div
                  className={`${utilStyles.column} ${styles.billing_section}`}
                >
                  <label>{t("Billing")}</label>
                  <div className={styles.horizontal_line} />
                  <div className={styles.column_gap_16}>
                    <div className={styles.mt_13}>
                      <Label
                        text={t("Currency")}
                        item={(
                          <SelectBox
                            options={currency}
                            value={currency_value}
                            setValue={(value) =>
                              setValue("currency_value", value)
                            }
                            customWidth="100%"
                            customControl={{
                              borderRadius: "8px",
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className={styles.minus_16_top}>
                      <FormInput
                        tagTitle={t("Price")}
                        fieldName="price"
                        isRequired={true}
                        addDollar={true}
                        placeholder={"0"}
                        currency={{
                          currency: currency_symbol[currency_value.value],
                        }}
                        {...register("price", {
                          required: t("This field is required"),
                          validate: {
                            isNumber: (value) => {
                              if (currency_value.value === "JPY") {
                                return Number.isInteger(Number(value)) || t("The value must be a whole number for JPY");
                              } else {
                                return  !isNaN(value) || t("The value must be a number");
                              }
                            }
                          },
                        })}
                      />
                    </div>
                    <div className={styles.row_multiple_elems}>
                      <div className={styles.pricing_item}>
                        <FormInput
                          tagTitle={t("Per")}
                          fieldName="per"
                          isRequired={true}
                          {...register("per", {
                            required: t("This field is required"),
                            validate: {
                              isNumber: (value) =>
                                (value !== null &&
                                  Number.isInteger(Number(value))) ||
                                t("The value must be a whole number"),
                              inRange: (value) =>
                                (value >= 1 && value <= 12) ||
                                t("The value must be between 1 and 12"),
                            },
                          })}
                        />
                      </div>
                      <div className={`${styles.pricing_item} ${styles.mt_42}`}>
                        <div className={styles.unit_of_time}>
                          {" "}
                          {t("Month(s)")}{" "}
                        </div>
                      </div>
                    </div>
                    <Label
                      text={t("Billing Start Date")}
                      item={(
                        <SelectBox
                          options={bill_start_options}
                          value={bill_start}
                          setValue={(value) => setValue("bill_start", value)}
                          customWidth="100%"
                          customControl={{
                            borderRadius: "8px",
                          }}
                        />
                      )}
                    />
                    <div className={styles.min10_top}>
                      <FormInput
                        tagTitle={t("Past Due")}
                        fieldName="past_due"
                        tooltipText={t(
                          "The number of days after an invoice due date that a payment can remain unpaid before additional actions, such as late fees or service suspension, are taken."
                        )}
                        tooltipColor={"gray"}
                        isRequired={true}
                        {...register("past_due", {
                          required: t("This field is required"),
                          validate: {
                            isNumber: (value) =>
                              (value !== null &&
                                Number.isInteger(Number(value))) ||
                              t("The value must be a whole number"),
                            inRange: (value) =>
                              (value >= 1 && value <= 30) ||
                              t("The value must be between 1 and 30"),
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
        <SaveBar
          onSubmit={methods.handleSubmit(onSubmit)}
          submitLabel={t("Save")}
          showCancel={true}
          onCancel={() => {
            history.goBack();
          }}
        />
      </div>
      <InfoModal
        isOpen={showPopup}
        title={t("Offering Created")}
        status="success"
        onDismiss={() => history.push("/billing")}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            {t("You created")} <b>{getValues().title}</b> subscription.
          </div>
        </div>
      </InfoModal>

      <InfoModal
        isOpen={errors.length > 0}
        title={errors[0]?.details[0]?.message}
        status="error"
        onConfirm={() => {
          history.goBack();
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{t("An error occured")}</div>
        </div>
      </InfoModal>
    </div>
  );
};

export default Subscription;
