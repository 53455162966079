import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import Button from "app/components/Button/ButtonVariant";
import { useActions, useSelector } from "app/utils/hooks";
import {
  changeNotifications,
  fetchNotifications,
} from "app/actions/user-settings";
import UserSettingsModel from "app/models/UserSettingsModel";
import { RootState } from "typedefs";
import AccountWrap from "..";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { Toast } from "app/components/helpers";

const NotificationsEdit = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<UserSettingsModel>({
    pendingInvitationsEmails: false,
    dailyDigestEmails: false,
    weeklyDigestEmails: false,
    defaultEmailNotificationsEnabled: false,
    defaultPushNotificationsEnabled: false,
  });
  const user = useSelector((state: RootState) => state.user.self, shallowEqual);

  const actions = useActions({ fetchNotifications, changeNotifications });
  const [loading, setLoading] = useState(false);
  const [toastAlert, setToastAlert] = useState(null);

  useEffect(() => {
    setLoading(true);
    actions
      .fetchNotifications()
      .then((res) => {
        setNotifications(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("something went wrong: ", err);
      });
  }, []);

  const onChangeHandler = (param: string, value: boolean) => {
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      [param]: value,
    }));
  };

  const onSubmitHandler = () => {
    setLoading(true);
    actions
      .changeNotifications({
        ...notifications,
        id: user.id,
      })
      .then(() => {
        setToastAlert(t("Your account was successfully updated."));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <AccountWrap>
      <div className={cls(styles.container, "panel")}>
        <h2 className="text-muted mb-1">{t("Notifications")}</h2>
        <div>
          <h4 className="">{t("Pending invitations reminder")}</h4>

          <div className="control-group checkbox-control-group">
            <div className="checkbox">
              <input
                type="checkbox"
                id="pendingInvitationsEmails"
                className=""
                checked={
                  notifications && notifications.pendingInvitationsEmails
                }
                onChange={(e) =>
                  onChangeHandler("pendingInvitationsEmails", e.target.checked)
                }
              />
              <label htmlFor="pendingInvitationsEmails"></label>
            </div>
            <label htmlFor="pendingInvitationsEmails" className="">
              {t("I want to receive weekly pending invitations reminder")}
            </label>
          </div>

          <h4 className="">{t("Daily digest emails")}</h4>

          <div className="control-group checkbox-control-group">
            <div className="checkbox">
              <input
                type="checkbox"
                id="dailyDigestEmails"
                className=""
                checked={notifications && notifications.dailyDigestEmails}
                onChange={(e) =>
                  onChangeHandler("dailyDigestEmails", e.target.checked)
                }
              />
              <label htmlFor="dailyDigestEmails"></label>
            </div>
            <label htmlFor="dailyDigestEmails" className="">
              {t("I want to receive daily digest emails")}
            </label>
          </div>

          <h4 className="">{t("Weekly digest emails")}</h4>

          <div className="control-group checkbox-control-group">
            <div className="checkbox">
              <input
                type="checkbox"
                id="weeklyDigestEmails"
                className=""
                checked={notifications && notifications.weeklyDigestEmails}
                onChange={(e) =>
                  onChangeHandler("weeklyDigestEmails", e.target.checked)
                }
              />
              <label htmlFor="weeklyDigestEmails"></label>
            </div>
            <label htmlFor="weeklyDigestEmails" className="">
              {t("I want to receive weekly digest emails")}
            </label>
          </div>

          <h4 className="">{t("Default notifications settings")}</h4>

          <div className="control-group checkbox-control-group">
            <div className="checkbox">
              <input
                type="checkbox"
                id="defaultEmailNotificationsEnabled"
                className=""
                checked={
                  notifications &&
                  notifications.defaultEmailNotificationsEnabled
                }
                onChange={(e) =>
                  onChangeHandler(
                    "defaultEmailNotificationsEnabled",
                    e.target.checked,
                  )
                }
              />
              <label htmlFor="defaultEmailNotificationsEnabled"></label>
            </div>
            <label htmlFor="defaultEmailNotificationsEnabled" className="">
              {t("Receive email notifications")}
            </label>
            &nbsp;&nbsp;
            <br />
            <div className="checkbox">
              <input
                type="checkbox"
                id="defaultPushNotificationsEnabled"
                className=""
                checked={
                  notifications && notifications.defaultPushNotificationsEnabled
                }
                onChange={(e) =>
                  onChangeHandler(
                    "defaultPushNotificationsEnabled",
                    e.target.checked,
                  )
                }
              />
              <label htmlFor="defaultPushNotificationsEnabled"></label>
            </div>
            <label htmlFor="defaultPushNotificationsEnabled" className="">
              {t("Receive push notifications")}
            </label>
          </div>

          <Button
            buttonType="primary"
            title={t("Save Changes")}
            buttonStyles={styles.save_button}
            onClickFunc={onSubmitHandler}
            loading={loading}
          />
        </div>
      </div>
      <Toast success={toastAlert} onDone={() => setToastAlert(null)} />
    </AccountWrap>
  );
};

export default NotificationsEdit;
