import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { RootState } from "typedefs";

import DatePicker from "app/components/DatePicker";
import CoachInfo from "./CoachInfo";
import DaysPanel from "./DaysPanel";
import DaysPanelForAllCoaches from "./DayPanelForAllCoaches";
import { Loading } from "app/components/Wrappers";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { AvailableSlotModel } from "app/models/scheduling/AvailableSlotModel";
import { LessonSchedule } from "app/models/EventModel";

import styles from "./DateTime.module.scss";
import { useTranslation } from "react-i18next";

type SlotParams = {
  coach_profile_ids: string[];
  from: string;
  to?: string;
  slot_duration: number;
  ignore_lesson_id?: number | null;
};

const timeSlotsForCoaches = (params: SlotParams) => {
  return schedulingFetchGet("/available_times", params)
    .then((rawSlots) => {
      const parsedSlots = {};

      rawSlots.forEach((coachSlots: any) => {
        const coachId = coachSlots.coachProfileId;
        const slotModels =
          coachSlots.slots?.map((slot) => {
            return new AvailableSlotModel(slot);
          }) || [];

        parsedSlots[coachId] = slotModels;
      });

      return parsedSlots;
    })
    .catch((e) => console.error(e));
};

const getPublicTimeSlots = (params: SlotParams) => {
  return schedulingFetchGet("/available_times", params, {}, null, true)
    .then((rawSlots) => {
      const parsedSlots = {};

      rawSlots.forEach((coachSlots: any) => {
        const coachId = coachSlots.coachProfileId;
        const slotModels =
          coachSlots.slots?.map((slot) => {
            return new AvailableSlotModel(slot);
          }) || [];

        parsedSlots[coachId] = slotModels;
      });

      return parsedSlots;
    })
    .catch((e) => console.error(e));
};

const DateAndTime = ({
  viewDate,
  setViewDate,
  coaches,
  duration,
  isPublicPage = false,
  hasPricings,
}) => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const lesson = useSelector(
    (state: RootState): LessonSchedule => state.bookSession.selectedSession
  );

  const dayStr = viewDate.toISODate();
  const minutesDuration = duration?.durationInMinutes;

  const singleCoach = coaches.length === 1;
  const coachIdsStr = coaches
    .filter((c) => !c.skipSlots)
    .map((c) => c.id)
    .join("-");

  const coachPublicIdsStr = coaches
    .filter((c) => !c.skipSlots)
    .map((c) => c.calendarUuid)
    .join("-");
  const timeZone = coaches[0].timeZone;

  useEffect(() => {
    if (!minutesDuration) {
      setLoading(false);
      return;
    }

    setLoading(true);
    let params: SlotParams = {
      slot_duration: minutesDuration,
      from: dayStr,
      coach_profile_ids: !isPublicPage
        ? coachIdsStr.split("-")
        : coachPublicIdsStr.split("-"),

      ignore_lesson_id: lesson?.id,
    };

    if (singleCoach) {
      const date = DateTime.fromISO(dayStr, { zone: timeZone }).startOf("day");
      params = {
        ...params,
        from: date.minus({ days: 1 }).toISO(),
        to: date.plus({ days: 1 }).endOf("day").toISO(),
      };
    }

    if (!isPublicPage) {
      timeSlotsForCoaches(params).then((slots: any[]) => {
        setTimeSlots(slots);
        setLoading(false);
      });
    } else {
      getPublicTimeSlots(params).then((slots: any[]) => {
        setTimeSlots(slots);
        setLoading(false);
      });
    }
  }, [
    coachIdsStr,
    dayStr,
    minutesDuration,
    lesson?.id,
    duration,
    singleCoach,
    timeZone,
    isPublicPage,
  ]);

  const renderLoading = () => {
    return (
      <div className={styles.coach_list}>
        <Loading isLoading loadType="spinner" />
      </div>
    );
  };

  const renderCoaches = () => {
    return (
      <div className={styles.coach_list}>
        {coaches.map((coach, index) => (
          <div
            key={coach.id ? coach.id : coach.calendarUuid}
            className={singleCoach ? styles.single_list : styles.multi_list}
          >
            {!singleCoach && (
              <CoachInfo coach={coach} singleCoach={singleCoach} />
            )}
            {singleCoach ? (
              <DaysPanel
                currentDay={viewDate}
                coach={coach}
                timeSlots={
                  timeSlots[!isPublicPage ? coach.id : coach.calendarUuid] || []
                }
                isPublicPage={isPublicPage}
              />
            ) : (
              <DaysPanelForAllCoaches
                currentDay={viewDate}
                coach={coach}
                timeSlots={
                  timeSlots[!isPublicPage ? coach.id : coach.calendarUuid] || []
                }
                index={index}
                isPublicPage={isPublicPage}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderEmptyPricings = () => {
    return (
      <div className={styles.rightPanel}>
        <i
          className={`ico ico-calendar ${styles.iconStyle}`}
          aria-label="date"
        />
        <p>
          {t("{{fullName}} is not set up to allow bookings by athletes.", {
            fullName: coaches[0].fullName,
          })}
        </p>
        <p>
          {t("Please contact {{fullName}} to book a session.", {
            fullName: coaches[0].fullName,
          })}
        </p>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.datepicker}>
          <DatePicker
            date={viewDate}
            setDate={setViewDate}
            minDate={new Date()}
            coach={coaches[0]}
            minutesDuration={minutesDuration}
          />
        </div>
        {singleCoach && (
          <CoachInfo coach={coaches[0]} singleCoach={singleCoach} />
        )}
      </div>
      {hasPricings
        ? loading
          ? renderLoading()
          : renderCoaches()
        : singleCoach === true
        ? renderEmptyPricings()
        : renderCoaches()}
    </div>
  );
};

export default DateAndTime;
