import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Offering from "./Offering";
import { Loading } from "app/components/Wrappers";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";

export default function Offerings({enabledIntegration = false}) {
  const  { t } = useTranslation();

  const [activeProducts, setActiveProducts] = useState([]);
  const [inactiveProducts, setInactiveProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [refresh, setRefresh] = useState(true);
  const refreshHandler = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    schedulingFetchGet(`/stripe_products?coach_id=${userId}`)
      .then((data) => {
        if (!data) {
          throw new Error('Unexpected response format');
        }
        setActiveProducts(data.filter((product) => product.enabled === true));
        setInactiveProducts(data.filter((product) => product.enabled === false));
      })
      .catch((error) => {
        console.error('Failed to fetch products:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, userId]);

 return (
  <div className={cls(styles.container)}>
    <div className={styles.header_container}>
        <div className={styles.header_title_container}>
          <div className={styles.header_title_wrapper}>
          <h2 className={cls(styles.title)}>
            {t('Offerings')}
            </h2>
          </div>
        </div>
        {enabledIntegration ?
        (
        <Link to={"/offerings/create"}>
          <button
            className={cls("btn", styles.button, styles.create_btn)}
          >
            + {t('Create New')}
          </button>
        </Link>
         ) : null }
    </div>
    {loading ?  <Loading isLoading={loading} loadType="spinner" /> :
      activeProducts.length > 0 || inactiveProducts.length > 0 ? (
      <div className={styles.offerings_section}>
        {activeProducts &&
          activeProducts.length > 0 ?
          (
          <div className={styles.offerings_container}>
            { activeProducts.map((product) => {
              return (
                <Offering key={product.id} product={product} dataRefresher={refreshHandler} enabledIntegration={enabledIntegration}/>
               );
             })}
          </div>
          ) : null
        }
        {inactiveProducts &&
          inactiveProducts.length > 0 ?
          (
          <>
          <div className={styles.subtitle}>{t('Inactive')}</div>
          <div className={styles.offerings_container}>
            { inactiveProducts.map((product) => {
              return (
                <Offering key={product.id} product={product} dataRefresher={refreshHandler} enabledIntegration={enabledIntegration}/>
               );
             })}
          </div> </>
          ) : null
        }
      </div>
      ) :
      (
      <div className={styles.info_container}>
        <div className={styles.gray_text}>
        {t("You can create packages and subscriptions that can be purchased by your athletes.")}
        </div>
        <div className={styles.centered_text}>
        {t("You have not created any offerings.")}
        <br/>
        {t("All of your packages and subscriptions will be displayed here.")}
        </div>
      </div>
      )
    }
</div>
);}

