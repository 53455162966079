/**
 * @module Actions.Space
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet } from "app/utils/request";
import { SpaceModel } from "app/models";

const fetchPending = () => action("@smartList.fetch.pending");

const fetchSmartListsError = (err: string) =>
  action("@smartList.fetch.smartListsError", err);

const fetchSmartListsSuccess = (smartLists: SpaceModel[]) =>
  action("@smartList.fetch.smartListsSuccess", smartLists);

export const clearSmartList = () => action("@smartList.clear");

const thisActions = {
  fetchSmartListsError,
  fetchSmartListsSuccess,
  fetchPending,
  clearSmartList,
};

export type SmartListAction = ActionType<typeof thisActions>;

export function fetchSmartLists(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchPending());

    return fetchGet<any>("smart_lists", params || {})
      .then((model: any) => dispatch(fetchSmartListsSuccess(model)))
      .catch((err) => dispatch(fetchSmartListsError(err)));
  };
}

export const actions = {
  fetchSmartLists,
};
