import GroupModel from "app/models/GroupsModel";
import React from "react";
import { useTranslation } from "react-i18next";
import TeamAvatar from "../TeamAvatar";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

interface Props {
  groupData: GroupModel;
  className?: string;
}

const GroupCardXl = ({ groupData, className }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cls(styles.container, className)}>
      <TeamAvatar
        srcName={groupData?.logo}
        sizeWidth={100}
        sizeHeight={100}
        className={styles.avatar}
      />
      <div className={styles.group_info}>
        <p className={styles.group_name}>{groupData?.name}</p>
        {/* <p className={styles.sport}>{groupData?.sport}</p> */}
        <p className={styles.group_sport}>{t("Group")}</p>
      </div>
    </div>
  );
};

export default GroupCardXl;
