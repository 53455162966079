import React, { useEffect, useCallback } from "react";
import PublicWrapper from "app/components/PublicWrapper";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { Loading } from "app/components/Wrappers";
import SessionForm from "../../Sessions/SessionForm";
import useBookSessionData from "../../Sessions/hooks/useBookSessionData";
import { defaultSelection } from "../../Sessions/helpers";
import { setBookSession } from "app/actions/scheduling/bookSession";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { useTimeZonesModel } from "../../Sessions/indexModel";
import useUrlSeach from "app/utils/hooks/useUrlSearch";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const NewFacilitySession = () => {
  const [searchParams, _] = useUrlSeach();
  const facilityId = searchParams.facilityId;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const bookLessonState = useSelector((state: RootState) => state.bookSession);
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );
  const fetcher = useCallback(() => {
    if (!facilityId) {
      return Promise.resolve([]);
    }
    const facilityPromise = schedulingFetchGet(
      `/facilities/${facilityId}`,
      {},
      {},
      null,
      true
    );
    const promise = Promise.all([facilityPromise])
      .then((response) => {
        return [response, response[0].coachProfiles];
      })
      .catch((_) => {
        history.push("/");
        toast.error(t("An unexpected error has occurred."));
      });
    return promise;
  }, [facilityId]);

  const [loadingBookLessonData, bookLessonData] = useBookSessionData(fetcher);

  useEffect(() => {
    if (user !== null && user.kind !== "athlete") {
      history.push("/");
      toast.error(t("You do not have access to that page."));
    }
  }, [user]);

  useEffect(() => {
    if (loadingBookLessonData || facilityId === null) {
      return;
    }
    const coachCount = bookLessonData["facilities"][0]?.coachProfiles?.length;
    if (!loadingBookLessonData && bookLessonData["facilities"].length === 0) {
      history.push("/");
      toast.error(t("An unexpected error has occurred."));
      return;
    }

    const initialData = defaultSelection(bookLessonData);

    let lessonState = {
      ...initialData,
      date: initialData.date.toISODate(),
      time: null,
      public: true,
    };

    if(coachCount > 1) {
      lessonState = {
        ...lessonState,
        coach: {
          fullName: "All Coaches",
          value: "all-coaches",
          id: "all-coaches",
        },
      };
    }

    if (bookLessonState.duration) {
      lessonState = {
        ...lessonState,
        duration: bookLessonState.pricingValue,
      };
    }
    if (bookLessonState.time) {
      lessonState = {
        ...lessonState,
        time: bookLessonState.time,
      };
    }
    dispatch(setBookSession(lessonState));
  }, [loadingBookLessonData, dispatch]);

  const { pending: loadingTimeZones } = useTimeZonesModel();

  if (loadingBookLessonData || !bookLessonState.facility || loadingTimeZones) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <PublicWrapper>
      <SessionForm bookLessonData={bookLessonData} isPublicPage={true} />
    </PublicWrapper>
  );
};
export default NewFacilitySession;
