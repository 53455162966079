import React, { memo } from "react";
import styles from "./styles.module.scss";

import GroupMember from "app/components/GroupMember";
import { UserModel } from "app/models";
import GroupsModel from "app/models/GroupsModel";
import { useTranslation } from "react-i18next";

interface IProps {
  members: UserModel[];
  pending: boolean;
  selectedGroup: GroupsModel;
  onDeleteMember: (userId: string, userName: string) => void;
  onMemberUpdate: (member: UserModel) => void;
}

const MembersList = ({
  members,
  pending,
  selectedGroup,
  onDeleteMember,
  onMemberUpdate,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.members_list}>
      {members?.map((member: UserModel, index: number) => {
        return (
          <GroupMember
            key={member.userId ?? index}
            member={member}
            role={member?.userId === selectedGroup?.ownerId ? t("Owner") : null}
            onRemoveMember={onDeleteMember}
            onMemberUpdate={onMemberUpdate}
          />
        );
      })}
      {!pending && members?.length === 0 && (
        <div className={styles.no_membbers}>{t("No members found.")}</div>
      )}
    </div>
  );
};

export default memo(MembersList);
