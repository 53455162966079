import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import StripeSvg from "../../../../public/images/stripe-icon.svg";
import MastercardSvg from "../../../../public/images/mastercard.svg";
import VisaSvg from "../../../../public/images/visa.svg";
import AmexSvg from "../../../../public/images/amex.svg";
import DiscoverSvg from "../../../../public/images/discover.svg";
import { useTranslation } from "react-i18next";
import { schedulingFetchDelete, schedulingFetchPatch, schedulingFetchPost, schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { Loading } from "app/components/Wrappers";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import SwitchToggle from "app/components/OptionButtonGroup/SwitchToggle";
import TaxItem from "../TaxItem";
import { FormProvider, useForm } from "react-hook-form";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import OfferingActionModal from "../OfferingActionModal";
import ActiveSubscriptionsModal from "../OfferingActionModal/ActiveSubscriptionsModal";
import FeesSection from "./FeesSection";

export default function Setup({isLoadingData = false, stripeId = null, activatedAccount = false, setStripeId = (_: string) => {},
                               enabledIntegration = false, setEnabledIntegration = (_: boolean) => {}}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [enableTax, setEnableTax] = useState(false);
  const [disablePopUp, setDisablePopUp] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");
  const disableHandler = () => {
    setDisablePopUp((prev) => !prev);
  };
  const methods = useForm({
    defaultValues: {
      tax_option: 'stripe_tax'
    },
  });
  const [isLoadingTaxes, setIsLoadingTaxes] = useState(false);

  const { getValues, handleSubmit, watch, reset} = methods;
  const taxOption = watch("tax_option");
  const userId = useSelector((state: RootState) => state.user.self.id);
  const onSubmit = async () => {
    const values = getValues();
    const tax_data = {
      is_enabled: enableTax,
      coach_id: userId,
      tax_type: values['tax_option'],
      tax_value:  values['tax_option'] ===  'flat_tax' ? values['flat_tax'] : null,
    };
    schedulingFetchPost(`/stripe_tax`, tax_data)
      .then((data) => {
        if (!data || data.error) {
          toast.error(data.error || t('An unexpected error has occured.'));
        } else {
          toast.success(t('Tax settings saved successfully.'));
        }
      })
      .catch((error) => {
        toast.error(error.errors || t('An unexpected error has occured.'));
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    if (code) {
      setIsLoading(true);
      schedulingFetchPatch(`/stripe_connect`, { code })
        .then((data) => {
          if (!data || data.error) {
            throw new Error(data.error || 'Error saving Stripe account');
          }
          setIsLoading(false);
          setStripeId(data['stripeId']);
          history.replace({ search: '' });
          window.location.reload();
          console.log('Stripe account saved successfully:', data);
        })
        .catch((error) => {
          history.replace({ search: '' });
          setIsLoading(false);
          toast.error(t('Error saving Stripe Account.'));
          console.error('Error saving Stripe account:', error);
        });
    }
  }, [location, history]);

  useEffect(() => {
    setIsLoadingTaxes(true);

    schedulingFetchGet(`/stripe_tax?coach_id=${userId}`)
      .then((data) => {
        if (!data) {
          throw new Error('Unexpected response format');
        }
        if(data.taxType !== null) {
         const defaultData = {
          tax_option: data.taxType,
          flat_tax: data.taxType === 'flat_tax' ? data.taxValue : null,
          stripe_tax: data.taxType === 'stripe_tax' ? data.taxValue : null
        };
        setEnableTax(true);
        reset(defaultData);
      }
      })
      .catch((error) => {
        console.error('Failed to fetch products:', error);
      })
      .finally(() => {
        setIsLoadingTaxes(false);
      });
  }, [ userId, stripeId]);

  const notConnected = () => {
        return (
        <div className={styles.info_container}>
          <img src={StripeSvg} alt="tags" className={styles.stripe_icon}/>
          <div className={styles.line_container}>
            <div className={styles.text_container}>
              <div className={styles.gray_text}>{stripeId ? t('Enable Stripe Account in order access payments.') : t('Connect your Stripe account or create a new one to start accepting online payments immediately.')}</div>
            </div>
            <button onClick={stripeId ? handleEnable : handleConnect} disabled={isLoading} className="btn btn-primary">
              {stripeId ? (isLoading ? t('Enabling...') : t('Enable Stripe Account')) :
              (isLoading ? t('Connecting...') : t('Connect to Stripe')) }
            </button>
           </div>
           <FeesSection />
        </div>
        );
      };


  const connected = () => {
    return (
    <div className={styles.info_container}>
      <img src={StripeSvg} alt="tags" className={styles.stripe_icon}/>
      <div className={styles.line_container}>
        <div className={styles.text_container}>
           {activatedAccount ? <div className={styles.gray_text}>{t('Stripe Account is Connected.')}</div> :
                               <div className={styles.gray_text}>{t('Please complete your account')} <a href='https://dashboard.stripe.com/account/onboarding'>{t('setup')}</a> {t('in Stripe.')}</div> }
        </div>
        <button onClick={disableHandler} disabled={isLoading} className={styles.cancel_button}>
          {isLoading ? t('Disabling...') : t('Disable')}
        </button>
       </div>
       <div className={styles.pay_methods_container}>
        <img src={VisaSvg} alt="tags" className={styles.card_icon}/>
        <img src={DiscoverSvg} alt="tags" className={styles.card_icon}/>
        <img src={AmexSvg} alt="tags" className={styles.card_icon}/>
        <img src={MastercardSvg} alt="tags" className={styles.card_icon}/>
      </div>
      <FeesSection />
      <div className={styles.line_container_text}>
        <div className={styles.settings_container}>
           {activatedAccount ? (
           <div className={styles.gray_text}>{t('Go to')} <a href='https://dashboard.stripe.com/test/settings/branding' className={styles.hyperlink}>{t('Branding settings ')}</a>
                                                                  {t('to customize colors and upload brand assets. To review your public business name, go to ')}
                                                                  <a href='https://dashboard.stripe.com/settings/business-details' className={styles.hyperlink}>{t('Public business information')}</a>.</div>
                                                                  ) :  null }
        </div>
       </div>
      { activatedAccount ? (
      <>
      <div className={styles.pr30}>
        <hr className={styles.line}/>
      </div>
      <div className={styles.taxes_container}>
        <div className={styles.bold_text}>{t('Would you like to apply Sales Tax to payments made via Stripe?')}</div>
       <FormProvider {...methods}>
        <form id="singlePaymentForm">
          <SwitchToggle
               checked={enableTax}
               onChange={() => setEnableTax(!enableTax)}
              title={t('Enable Taxes via Stripe')}
            />
           {
           enableTax &&
          (
           < div className={styles.tax_items} >
            <TaxItem  tagTitle={'Stripe Tax'} fieldName={'stripe_tax'}
              description={t('Enter ItemCode which allows coaches to map sales tax within Stripe’s tax engine. Apply to all sessions and group programs')}
              placeholder={'Enter ItemCode'}
              checkValue={'stripe_tax'}
              showInput={false}
              tooltipText={t('Stripe Tax is a paid product from Stripe. It automatically calculates the tax on your transactions without the need to define or update the rates and rules')}/>
            <TaxItem  tagTitle={'Tax Rate'} fieldName={'flat_tax'}
              description={t('Enter flat tax percentage that will be applied to all lessons and group programs')}
              placeholder={'Tax Rate %'}
              checkValue={'flat_tax'}
              isEditable={taxOption === 'flat_tax'}
              tooltipText={t('The Tax Rate entered is default, flat rate applied at checkout to all lessons and group programs paid via Stripe')}/>
              </div>
           )
          }
          </form>
        </FormProvider>
        </div>
        <div className={styles.line_container}>
            <button onClick={handleSubmit(onSubmit)}  className="btn btn-primary">
              {t('Save changes')}
            </button>
            </div>
          </>
        )
       : null
      }
    </div>
    );
  };

  const handleConnect = async () => {
    try {
      setIsLoading(true);

      schedulingFetchPost(`/stripe_connect`)
        .then((data) => {
          if (!data || !data.url) {
            throw new Error('Failed to connect with Stripe.');
          }
          window.location.href = data.url;
        })
        .catch(() => {
          setIsLoading(false);
          toast.error(t('An unexpected error has occurred.'));
        })
        .finally(() => {
        });
    } catch (error) {
      toast.error(t('An unexpected error has occurred.'));
    }
  };

  const handleEnable = async () => {
    setIsLoading(true);

    return schedulingFetchPatch(`/stripe_connect/enable_account`)
      .then(() => {
        setEnabledIntegration(true);
        toast.success(t('Account enabled successfully.'));
      })
      .catch(() => {
        toast.error(t('An unexpected error has occurred.'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDisconnect = async () => {
    setIsLoading(true);

    try {
      await schedulingFetchDelete(`/stripe_connect`);
        setEnabledIntegration(false);
        toast.success(t('Account disabled successfully.'));
        disableHandler();
      } catch(error)  {
      const errors = error.errors;
      if(errors?.message && error.status === 422) {
        disableHandler();
        setTitleError(errors.title);
        setMessageError(errors.message);
      } else {
        disableHandler();
        toast.error(t('An unexpected error has occurred.'));
      }
    } finally {
      setIsLoading(false);
    }
};

  return (
   <>
    <div className={styles.container}>
      {isLoadingData || isLoadingTaxes ? (
      <Loading isLoading={isLoadingData} loadType="spinner" />
      ) :
      (enabledIntegration ? connected() : notConnected() )}
    </div>
    {messageError && (
        <ActiveSubscriptionsModal
          message={messageError}
          onClose={() => setMessageError("")}
          title={titleError}
        />
      )}
    { disablePopUp && (
        <OfferingActionModal
          actions={{
            onCancel: () => disableHandler(),
            onConfirm: () => handleDisconnect(),
          }}
          data={{
            operation:  "delete",
            title: t('Are you sure you want to disable your Stripe Account?'),
            content: t("Disable removes the ability of Athletes to purchase any of your offerings."),
            button_text:  t('Yes, disable it')
          }}
        />
        )
     }
  </>
  );
}
