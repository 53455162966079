import LoadingPlaceholder from "../LoadingPlaceholder";
import styles from "../styles.module.scss";
import React from "react";

export default function LoadingContainer({ type }) {
  return (
    <div className={`${styles.photo_uploader_container}`}>
      <div
        className={`
          ${styles.placeholder}
          ${styles.loading_placeholder}
          ${
            type === "logo"
              ? styles.logo_placeholder
              : styles.banner_placeholder
          }
        `}
      >
        <div className={styles.inside_box}>
          <LoadingPlaceholder />
        </div>
      </div>
    </div>
  );
}
