import {
  deleteMemberFromSpace,
  deleteInvitation,
  fetchSpaceInvitations,
  fetchSpaceMembers,
  inviteMemberToSpace,
  makeMemberAthlete,
} from "app/actions/space";
import { usePager } from "app/components/helpers/usePager";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import SpaceMemberModel from "app/models/SpaceMemberModel";
import { useActions } from "app/utils/hooks";
import useDebounce from "app/utils/hooks/useDebounce";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootState } from "typedefs";
import { shallowEqual, useSelector } from "react-redux";
import {
  cancelSpaceInvitation,
  resendInvitation,
} from "app/actions/invitation";
import UserModel from "app/models/UserModel";

export const SpaceMembersModel = () => {
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const spaceActions = useActions({
    makeMemberAthlete,
    cancelSpaceInvitation,
    deleteMemberFromSpace,
    deleteInvitation,
    inviteMemberToSpace,
    resendInvitation,
  });
  const invitationActions = useActions({
    fetchSpaceMembers,
    fetchSpaceInvitations,
  });
  const [search, setSearch] = useState(null);
  const [query] = useState<{ [key: string]: any }>({
    perPage: 20,
  });
  const [invitationError, setInvitationError] = useState<string | null>(null);
  const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);
  const [inviteUrl, setInviteUrl] = useState<string | null>(null);

  const spaceParams = useParams<{ id: string }>();

  const debouncedSearch = useDebounce(search ?? "", 300);

  const memberSearchHandler = (val: string) => {
    setSearch(val);
  };

  const {
    data: members,
    loading,
    sentryRef: membersRef,
    initialLoad: fetchMembers,
  } = usePager({
    api: (_id, params: any) => invitationActions.fetchSpaceMembers(_id, params),
    fetchId: spaceParams.id,
    query:
      search?.length >= 2 ? { ...query, userName: debouncedSearch } : query,
    currentPage: 1,
    totalPages: query.perPage,
  });

  const {
    data: invitedMembers,
    sentryRef: invitedMembersRef,
    initialLoad: fetchInvitedMembers,
  } = usePager({
    api: (_id, params: any) =>
      invitationActions.fetchSpaceInvitations(_id, params),
    fetchId: spaceParams.id,
    query,
    currentPage: 1,
    totalPages: query.perPage,
  });

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    if (search?.length >= 2 || search?.length === 0) {
      fetchMembers();
    }
  }, [debouncedSearch]);

  const handleInviteModal = () => {
    setInviteModalIsOpen(!inviteModalIsOpen);
  };

  const onMakeAthlete = (memberId: string, member: SpaceMemberModel) => {
    customPrompt({
      message: t(
        `Changing athlete changes the current Space owner and athlete. Please confirm your action.`
      ),
      onYes: () => {
        spaceActions
          .makeMemberAthlete(spaceParams.id, memberId, {
            ...member,
            role: "athlete",
          })
          .then(() => {
            fetchMembers();
          });
      },
      onCancel: () => {
        return;
      },
    });
  };

  const onRemoveUser = (member: UserModel) => {
    customPrompt({
      message: t(`Are you sure?`),
      onYes: () => {
        if (member?.object === "space_invitation") {
          spaceActions
            .cancelSpaceInvitation(spaceParams.id, member?.id)
            .then(() => {
              fetchInvitedMembers();
            });
        } else {
          spaceActions
            .deleteMemberFromSpace(spaceParams.id, member?.id)
            .then(() => {
              fetchMembers();
              fetchInvitedMembers();
              if (self && self?.id === member?.id) {
                route("/spaces");
              }
            });
        }
      },
      onCancel: () => {
        return;
      },
    });
  };

  const onMemberInvite = (invitation: { [key: string]: any }) => {
    spaceActions
      .inviteMemberToSpace(spaceParams.id, {
        ...invitation,
        spaceId: spaceParams.id,
      })
      .then((invitation: { [key: string]: any }) => {
        if (invitationError) {
          setInvitationError(null);
          return;
        }
        if (invitation.phone && invitation.phone) {
          setInviteUrl(invitation.shortInvitationUrl);
          handleInviteModal();
        }
        fetchMembers();
        fetchInvitedMembers();
      })
      .catch((error: any) => {
        if (error && error.originalErrors) {
          if (Array.isArray(error.originalErrors.email)) {
            setInvitationError(error.originalErrors.email[0]);
          } else if (Array.isArray(error.originalErrors.user_id)) {
            setInvitationError(error.originalErrors.user_id[0]);
          } else {
            setInvitationError(t("An unknown error occurred"));
          }
        } else {
          setInvitationError(t("An unknown error occurred"));
        }
      });
  };

  const onCopyLink = (member: SpaceMemberModel) => {
    setInviteUrl(member.shortInvitationUrl);
    handleInviteModal();
  };

  const onEdit = (memberId: string) => {
    route(`/spaces/${spaceParams.id}/invitations/${memberId}/edit`);
  };

  const onResend = (invitation: UserModel) => {
    if (invitation) {
      spaceActions.resendInvitation(invitation).then(() => {
        fetchInvitedMembers();
      });
    }
  };

  return {
    members,
    loading,
    membersRef,
    invitedMembers,
    invitedMembersRef,
    memberSearchHandler,
    onMakeAthlete,
    onRemoveUser,
    onMemberInvite,
    onCopyLink,
    onEdit,
    onResend,
    invitationError,
    handleInviteModal,
    inviteModalIsOpen,
    inviteUrl,
  };
};
