import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export default function FeesSection() {
  const { t } = useTranslation();

  return (
    <div className={styles.feesContainer}>
      <div className={styles.feesRow}>
        <div className={styles.feesIcon}>
          <i className={`ico ico-info ${styles.toolTip}`} />
        </div>

        <div className={styles.feesContent}>
          <h3 className={styles.feesTitle}>{t("Fees")}</h3>
          <p className={styles.feesDescription}>
            {t(
              "Fees apply to payments made via Stripe. Stripe’s Standard Pricing is {{rate}} (in the US) per domestic card transaction. CoachNow also applies a {{techFee}} technology fee to all transactions. Stripe deducts fees before settling payouts to you. Payout schedules, gross and net payments, and more can be accessed in your Stripe Dashboard.",
              { rate: "2.90% + $0.30", techFee: "0.50%" }
            )}
          </p>

          <div className={styles.links}>
            <a href="https://stripe.com/en-ro/pricing" target="_blank" rel="noopener noreferrer">
              {t("Stripe Pricing")}
            </a>
            <a href="https://stripe.com/en-ro/legal/connect-account" target="_blank" rel="noopener noreferrer">
              {t("Stripe Connect Account Terms")}
            </a>
            <a href="https://coachnow.io/terms-of-service" target="_blank" rel="noopener noreferrer">
              {t("CoachNow Terms")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
