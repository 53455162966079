import styles from "../styles.module.scss";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import LoadingContainer from "../LoadingContainer";
import FailPlaceholder from "../FailPlaceholder";
import EditIcon from "../../../../../public/images/edit-icon.svg";
import Trashcan from "../../../../../public/images/trash.svg";

export default function FailContainer({
  type,
  uploadPhoto,
  setType,
  setResult,
  errorMessage,
  imageLoader,
  customPlaceholderStyle,
}) {
  const { t } = useTranslation();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      try {
        await uploadPhoto(type, file);
      } catch (error) {
        console.log(error);
      }
    },
    [type, uploadPhoto, t]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
    multiple: false,
    noKeyboard: true,
    noClick: true,
  });

  const failCase = (
    <div className={styles.fail_container}>
      <FailPlaceholder
        type={type}
        errorMessage={errorMessage}
        customPlaceholderStyle={customPlaceholderStyle}
      />
      <div className={styles.fail_line_container}>
        <div
          {...getRootProps()}
          className={styles.edit_wrapper}
          onClick={() => {
            open();
            setType(type);
          }}
        >
          <input {...getInputProps()} />
          <img src={EditIcon} />
        </div>
        <div
          className={styles.trashcan_wrapper}
          onClick={() => {
            setResult('');
          }}
        >
          <img src={Trashcan} />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.image_wrapper}>
      {imageLoader ? (
        <LoadingContainer type={type} />
      ) : (
        failCase
      )}
    </div>
  );
}
