import { useLocation, useHistory } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const getQueryParams = (): URLSearchParams => {
    return new URLSearchParams(location.search);
  };

  const setQueryParam = (key: string, value: string) => {
    const queryParams = getQueryParams();
    queryParams.set(key, value);
    history.push({ search: queryParams.toString() });
  };

  const deleteQueryParam = (key: string) => {
    const queryParams = getQueryParams();
    queryParams.delete(key);
    history.push({ search: queryParams.toString() });
  };

  return {
    getQueryParams,
    setQueryParam,
    deleteQueryParam,
    queryParams: getQueryParams(),
  };
};

export default useQueryParams;
