import { FormProvider, useForm } from "react-hook-form";

import styles from "./styles.module.scss";
import utilStyles from "../../../BuildProgram/utilStyles.module.scss";
import FormInput from "../../../../components/Programs/FormInput";
import React, { useState, useEffect } from "react";
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfoModal from "app/routes/Calendar/InfoModal";
import { useHistory, useLocation } from "react-router-dom";
import SaveBar from "app/routes/Sessions/SaveBar";
import Label from "app/components/Programs/Label";
import {
  schedulingFetchGet,
  schedulingFetchPost,
} from "app/utils/request/scheduling/fetch";
import SelectBox from "app/components/OptionButtonGroup/SelectBox";
import { currency, currency_symbol } from "../utils";

import DOMPurify from "dompurify";
import Navbar from "app/components/Navbar";

const SinglePayment = () => {
  const mapObjectToDefaultValues = (product) => {
    return {
      title: `[Copy of] ${product.title}`,
      description: product.description,
      number_of_sessions: product.numberOfSessions,
      price: product.price.toString(),
      past_due: product.pastDue,
      currency_value:
        currency.find((option) => option.value === product.currency) ||
        currency[0],
    };
  };

  const location = useLocation();
  const methods = useForm({
    defaultValues: {
      title: "",
      description: "",
      number_of_sessions: null,
      price: null,
      past_due: 10,
      currency_value: { value: "USD", label: "US Dollar (USD, $)" },
    },
  });
  const { watch, getValues, register, setValue } = methods;
  const userId = useSelector((state: RootState) => state.user.self.id);
  const past_due = watch("past_due");
  const currency_value = watch("currency_value");
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();
  const navigateBack = () => {
    history.goBack();
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!location.state?.product) {
      schedulingFetchGet(`/coach_profiles?/${userId}`).then((data) => {
        setValue(
          "currency_value",
          currency.find((option) => option.value === data[0].currency) ||
            currency[0]
        );
      });
    }
  }, [userId]);

  useEffect(() => {
    if (location.state?.product) {
      const defaultValues = mapObjectToDefaultValues(location.state.product);
      methods.reset(defaultValues);
    }
  }, [location.state, methods]);

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const productData = {
      ...getValues(),
      coach_id: userId,
      past_due,
      type: "product",
      currency: currency_value.value,
      description: DOMPurify.sanitize(getValues().description),
    };

    try {
      const data = await schedulingFetchPost(`/stripe_products`, productData);
      if (!data || data.error) {
        throw new Error(data.error || "Error creating product");
      }
      setShowPopup(true);
    } catch (error) {
      console.error("Error creating product:", error);
      setErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const titleValue = watch("title", "");
  return (
    <div>
      <Navbar label={t("Create Package")} hasSearch={false} hasNotifications={false} onBackClick={navigateBack}/>
      <div className={styles.empty_container}>
        <div className={`${styles.container}  billing-page-container`}>
          <FormProvider {...methods}>
            <form id="singlePaymentForm">
              <div className={styles.row}>
                <div
                  className={`${utilStyles.column} ${styles.general_section}`}
                >
                  <label>{t("General")}</label>
                  <div className={styles.horizontal_line} />
                  <div className={styles.column}>
                    <div className={styles.input_container_counter}>
                      <FormInput
                        tagTitle={t("Title")}
                        fieldName="title"
                        placeholder={t("Enter package name")}
                        isRequired={true}
                        {...register("title", {
                          required: t("This field is required"),
                          validate: {
                            maxLength: (value) =>
                              value.length <= 250 ||
                              t("The title must not exceed 250 characters"),
                          },
                        })}
                        maxLength={250}
                      />
                      <div className={styles.character_counter}>
                        {titleValue?.length}/250
                      </div>

                      <div className={styles.textarea_width}>
                        <Label
                          text={t("Description")}
                          item={(
                            <textarea
                              data-testid="description"
                              placeholder={t("Enter a description")}
                              {...methods.register("description")}
                              className={styles.details_textarea}
                            />
                          )}
                          containerStyle={styles.mt_16}
                        />
                      </div>

                      <FormInput
                        tagTitle={t("Number of Sessions")}
                        fieldName="number_of_sessions"
                        placeholder={t("Enter the number of sessions")}
                        {...register("number_of_sessions", {
                          validate: {
                            isNumber: (value) =>
                              value === null ||
                              /^\d+$/.test(value) ||
                              t("The value must be a whole number"),
                            inRange: (value) =>
                              value === null ||
                              value >= 1 ||
                              t("The value must be bigger than 1"),
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.vertical_line} />
                <div
                  className={`${utilStyles.column} ${styles.billing_section}`}
                >
                  <label>{t("Billing")}</label>
                  <div className={styles.horizontal_line} />
                  <div className={styles.mt_13}>
                    <Label
                      text={t("Currency")}
                      item={(
                        <SelectBox
                          options={currency}
                          value={currency_value}
                          setValue={(value) =>
                            setValue("currency_value", value)
                          }
                          customWidth="100%"
                          customControl={{
                            borderRadius: "8px",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <FormInput
                      tagTitle={t("Price")}
                      fieldName="price"
                      isRequired={true}
                      addDollar={true}
                      placeholder={"0"}
                      currency={{
                        currency: currency_symbol[currency_value.value],
                      }}
                      {...register("price", {
                        required: t("This field is required"),
                        validate: {
                          isNumber: (value) => {
                            if (currency_value.value === "JPY") {
                              return Number.isInteger(Number(value)) || t("The value must be a whole number for JPY");
                            } else {
                              return  !isNaN(value) || t("The value must be a number");
                            }
                          }
                        },
                      })}
                    />
                  </div>
                  <FormInput
                    tagTitle={t("Past Due")}
                    fieldName="past_due"
                    isRequired={true}
                    tooltipColor={"gray"}
                    tooltipText={t(
                      "The number of days after an invoice due date that a payment can remain unpaid before additional actions, such as late fees or service suspension, are taken."
                    )}
                    {...register("past_due", {
                      required: t("This field is required"),
                      validate: {
                        isNumber: (value) =>
                          (value !== null && Number.isInteger(Number(value))) ||
                          t("The value must be a whole number"),
                        inRange: (value) =>
                          (value >= 1 && value <= 30) ||
                          t("The value must be between 1 and 30"),
                      },
                    })}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
          <SaveBar
            submitLabel={t("Save")}
            showCancel={true}
            onSubmit={methods.handleSubmit(onSubmit)}
            onCancel={() => {
              history.goBack();
            }}
          />
        </div>
      </div>

      <InfoModal
        isOpen={showPopup}
        title={t("Offering Created")}
        status="success"
        onDismiss={() => history.push("/billing")}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            {t("You created")} <b>{getValues().title}</b> package.
          </div>
        </div>
      </InfoModal>

      <InfoModal
        isOpen={errors.length > 0}
        title={errors[0]?.details[0]?.message}
        status="error"
        onConfirm={() => {
          history.goBack();
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{t("An error occured")}</div>
        </div>
      </InfoModal>
    </div>
  );
};

export default SinglePayment;
