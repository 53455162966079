import React from "react";
import styles from "./styles.module.scss";
import SpacesAndGroupsList from "app/components/SpacesAndGroupsList";
import MyCoachesList from "app/components/MyCoachesList";
import RecentActivities from "app/components/RecentActivities";
import { cls } from "app/utils";
import Navbar from "app/components/Navbar";
import { useAthleteHomeModel } from "./indexModel";
import { useTranslation } from "react-i18next";

interface ISectionLayout {
  children: React.ReactNode;
  label: string;
  viewPressed: boolean;
  hasView?: boolean;
  viewAllHandler?: () => void;
  pending?: boolean;
}

const AthleteHome = (): React.ReactElement => {
  const {
    t,
    countSpaces,
    filteredCoaches,
    onViewSpaces,
    onColapseSpaces,
    onViewNotifications,
    onViewAllCoaches,
    onColapseCoaches,
    allCoaches,
    showAllCoaches,
    loadingSpaces,
    combinedData,
    showAllSpaces,
  } = useAthleteHomeModel();

  const renderData = [
    {
      label: `${t("Spaces and Groups")} (${countSpaces})`,
      hasView: combinedData?.length > 4,
      viewPressed: showAllSpaces,
      viewAllHandler: showAllSpaces ? onColapseSpaces : onViewSpaces,
      children: (
        <SpacesAndGroupsList
          spacesData={combinedData}
          pending={loadingSpaces}
          displayingAllSpaces={showAllSpaces}
        />
      ),
      pending: loadingSpaces,
    },
    {
      label: t("My Coaches") + ` (${allCoaches.length})`,
      hasView: allCoaches?.length > 4,
      viewPressed: showAllCoaches,
      viewAllHandler:
        !showAllCoaches && allCoaches?.length >= 4
          ? onViewAllCoaches
          : onColapseCoaches,
      children: <MyCoachesList coaches={filteredCoaches} />,
    },
    {
      label: t("Recent Activity"),
      hasView: true,
      viewPressed: false,
      viewAllHandler: onViewNotifications,
      children: <RecentActivities />,
    },
  ];

  return (
    <div className={cls(styles.container, "athlete-home")}>
      <Navbar label={t("Home")} />
      {renderData.map((item, index): React.ReactElement => {
        return (
          <div
            key={item.label}
            className={index === 0 ? styles.margin_top_zero : styles.margin_top}
          >
            <SectionLayout
              label={item.label}
              viewPressed={item.viewPressed}
              hasView={item?.hasView}
              viewAllHandler={item.viewAllHandler}
              pending={item?.pending}
            >
              {item.children}
            </SectionLayout>
          </div>
        );
      })}
    </div>
  );
};

export default AthleteHome;

const SectionLayout = ({
  children,
  label,
  hasView,
  viewAllHandler,
  pending,
  viewPressed,
}: ISectionLayout) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.section_title}>
        <p>{label}</p>
        {hasView && viewAllHandler && (
          <div
            onClick={!pending && viewAllHandler}
            className={cls(styles.view_all_btn, {
              [styles.disabled_view_btn]: pending,
            })}
          >
            <p>{viewPressed ? t("Collapse All") : t("View All")}</p>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
