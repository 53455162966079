import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import CustomerTable from "./CustomerTable";
import NoCustomersPlaceholder from "./NoCustomersPlaceholder";
import { formatDate } from "../../../utils/luxonFormats";

interface Customer {
  studentProfileId: number;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  totalSpent: string;
  remainingSessions: number;
  totalSessions: number;
  payments: number;
  date: string;
  currency: string;
}

export default function Customers() {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const userId = useSelector((state: RootState) => state.user.self.id);

  useEffect(() => {
    schedulingFetchGet(`/stripe_purchases/customers?coach_id=${userId}`)
      .then((data) => {
        if (!data) {
          throw new Error("Unexpected response format");
        }
        setCustomers(data);
      })
      .catch((error) => {
        console.error("Failed to fetch customers:", error);
      });
  }, [userId]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{t("Customers")}</h2>
        <button
          className={cls("btn", styles.button, styles.go_to_stripe)}
          onClick={() =>
            window.open("https://dashboard.stripe.com/customers", "_blank")
          }
        >
          {t("Go to Stripe")}
        </button>
      </div>

      {customers.length > 0 ? (
        <CustomerTable customers={customers} formatDate={formatDate} />
      ) : (
        <NoCustomersPlaceholder />
      )}
    </div>
  );
}
